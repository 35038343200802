
export default class CKEditorUploadAdapter {

	constructor(editor) 
	{
		editor.plugins.get( 'FileRepository' ).createUploadAdapter = ( loader ) => {
			return new Adapter(loader, editor);
		};
	}

	/**
	 * @inheritDoc
	 */
	static get pluginName() {
		return 'CKEditorUploadAdapter';
	}

	/**
	 * @inheritDoc
	 */
	init() {
	}
}

/**
 * Upload adapter.
 *
 * @private
 * @implements module:upload/filerepository~UploadAdapter
 */
class Adapter {
	/**
	 * Creates a new adapter instance.
	 *
	 * @param {module:upload/filerepository~FileLoader} loader
	 */
	constructor( loader, editor ) {
		/**
		 * FileLoader instance to use during the upload.
		 *
		 * @member {module:upload/filerepository~FileLoader} #loader
		 */
		this.loader = loader;

		this.editor = editor;
	}

	/**
	 * Starts the upload process.
	 *
	 * @returns {Promise}
	 */
	upload() {
		return this.loader.file
			.then( file => new Promise( ( resolve, reject ) => {
				console.log('file=',file);

				let uploadURL;
				if (this.editor.fileUploadURL)
				{
					uploadURL = this.editor.fileUploadURL;
				}
				else if (this.editor.fileUploadLocation)
				{
					uploadURL = this.editor.fileUploadLocation;
					if (!uploadURL.endsWith('/')) uploadURL += '/';
					uploadURL += file.name;
				}
				else
				{
					return reject(new Error('editor.fileUploadLocation or editor.fileUploadURL should be set'));
				}
				this._uploadXHR( resolve, reject, file, uploadURL );
			})
		);
	}

	abort() {
		if ( this.xhr ) {
			this.xhr.abort();
		}
	}
	_uploadXHR( resolve, reject, file, uploadURL ) {
		const xhr = this.xhr = new XMLHttpRequest();
		const method = this.editor.fileUploadMethod || 'PUT';
		const loader = this.loader;
		const genericErrorText = `Couldn't upload file: ${ file.name }.`;
		
		xhr.addEventListener( 'error', () => reject( genericErrorText ) );
		xhr.addEventListener( 'abort', () => reject() );
		xhr.addEventListener( 'load', async () => {
			console.debug('file upload xhr=',xhr);
			const response = xhr.response;

			if ( response.error ) {
				return reject( response && response.error && response.error.message ? response.error.message : genericErrorText );
			}

			if (this.editor.afterUploadCallback)
				await this.editor.afterUploadCallback(file, response, xhr);

			resolve( {
				default: (xhr.getResponseHeader('Location') || uploadURL)
			} );
		} );

		// Upload progress when it is supported.
		if ( xhr.upload ) {
			xhr.upload.addEventListener( 'progress', evt => {
				if ( evt.lengthComputable ) {
					loader.uploadTotal = evt.total;
					loader.uploaded = evt.loaded;
				}
			} );
		}

		//const headers = this.options.headers || {};
		let headers = {};

		// Use the withCredentials flag if specified.
		const withCredentials = false;

		for ( const headerName of Object.keys( headers ) ) {
			xhr.setRequestHeader( headerName, headers[ headerName ] );
		}

		xhr.withCredentials = withCredentials;

		if (method === 'PUT')
		{
			xhr.overrideMimeType(file.type);
			xhr.open( method, uploadURL, true );
			xhr.send( file );
		}
		else if (method === 'POST')
		{
			const formData = new FormData();
			formData.append(this.editor.fileUploadField || 'file', file);
			xhr.open( method, uploadURL, true );
			xhr.send( formData );
		}
		else
		{
			throw new Error('Unknown method');
		}
	}
}


