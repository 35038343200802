import template from './select_task.html';

class SelectTaskViewModel
{
	constructor (dialog) {
		this.dialog = dialog;

		this.is_loading = ko.observable(false);
		this.search_text = ko.observable();
		this.tasks = ko.observableArray();
		this.selected_task = ko.observable(null);
		this.selected_items = ko.observableArray([]);
		this.dialog_visible = ko.observable(true);
	}

	async btnSearch_click () 
	{
		this.is_loading(true);
		this.tasks([]);

		try
		{
			let result = await Grape.fetches.getJSON('/user/task_fts_search', { query: this.search_text() });

			if (result.status == 'OK')
			{
				if (result.records)
				{
					let records = [];
					for (let r of result.records)
					{
						r.selected = ko.observable(false);
						records.push(r);
					}
					this.tasks(records);
				}
			}
			else 
			{
				throw new Error(result);
			}
		} catch(error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		} finally {
			this.is_loading(false);
		}
	}

	async btnSelect_click()
	{
		let tasks = this.selected_items();
		let response;

		if (tasks.length == 1)
			response = await Grape.prompt({ title: 'Select Task', message: `Do you want to select ${tasks[0].task_nr}(${tasks[0].short_description}) ?` });
		else if (tasks.length > 1)
			response = await Grape.prompt({ title: 'Select Tasks', message: `Do you want to select these tasks ?` });
		else 
			Grape.alert({ title: 'Select Task', type: 'warning', message: 'No task was selected' });

		if (response)
			this.dialog.close(tasks);
	}

	btnClose_click()
	{
		this.dialog.close(null);
		this.dialog_visible(false);
	}
}

class SelectTaskDialog
{
	constructor (bindings) {
		this.viewModel = new SelectTaskViewModel(this, bindings);
		this.bindings = bindings;
	}

	init () {};
}

export default {
	name: 'SelectTask',
	page_class: SelectTaskDialog,
	template: template
}
