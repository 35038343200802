/*
 *
 * OPTIONS:
 * 	rules ARRAY [
 * 		{
 * 			field: { name: 'status' },
 * 			operator: '=',
 *			value: 'New',
 *			apply: {
 *				attr: 'background-color',
 *				value: 'green'
 *			}
 * 		}
 * 	]
 * 	fields ARRAY [ { name: 'status', label: 'Status' }, { ... } ]
 * 	label_field STRING 'label'
 *
 */

import template from './formatting-rules.html';

class FormattingRulesVM
{
	constructor (params) {
		this.rules = ko_helper.safe_observableArray(params.rules, []);

		this.fields = ko_helper.safe_observableArray(params.fields, []);
		this.label_field = ko_helper.safe_observable(params.label_field, 'name');
		this.inline = ko_helper.safe_observable(params.inline, false);
		this.selected_field = ko.observable();

		this.operators = ko.observableArray([
			'=', '!=', '>', '>=', '<', '<=', 'ANY'
		]);
		this.selected_operator = ko.observable();

		this.new_value = ko.observable('');

		this.apply_styles = ko.observableArray(['background-color']);
		this.selected_style = ko.observable();
		this.new_apply_value = ko.observable('#000000');

		if (params.done && typeof params.done === 'function')
			this.done = params.done;
		else
			this.done = () => { };
	}

	add_rule () 
	{
		let new_rule = {
			field: this.selected_field(),
			operator: this.selected_operator(),
			value: this.new_value(),
			apply: {
				attr: this.selected_style(),
				value: this.new_apply_value()
			}
		};

		this.rules.unshift(new_rule);
	}

	delete_item (e) 
	{
		this.rules.splice(this.rules.indexOf(e), 1);
	}
}

export default {
	name: 'formatting-rules',
	viewModel: FormattingRulesVM,
	module_type: 'ko',
	template: template
}
