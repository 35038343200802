
export default {
	name: 'NotificationTypeLookup',
	options: {
		tablename: 'notification_type_lookup',
		schema: 'public',
		sortfield: 'notification_type'
	}
};

