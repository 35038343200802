import template from './edit_categories.html';

//Instantiate Page Variables 
class EditCategoriesPageViewModel
{
	constructor (page) {
		this.page = page;

		this.categories = ko.observableArray();
	}

	update () 
	{
		Grape.cache.fetch('TaskCategoryLookup', (d) => {
			console.log(d);
			this.categories(d);
		});
	}

	async edit_description (c) 
	{
		let result = await Grape.prompt({ type: 'info', title: 'Description', message: 'Edit description', has_input: c.description, accept_text: 'Save' });

		if (result)
		{
			window.Grape.tables.update({
				schema: 'public',
				tablename: 'task_category_lookup',
				filter: {
					task_category_id: c.task_category_id
				},
				values: {
					description: result
				},
				done: () => {
					window.Grape.cache.invalidate('TaskCategoryLookup');
					Grape.alert({type: 'success', title: 'Description updated', message: 'Description updated'});
					this.update();
				}
			});
		}
	}

	async add_subcategory (task_category) 
	{
		let result = await Grape.prompt({ type: 'info', title: 'New subcategory', message: 'Create new subcategory', has_input: 'New', accept_text: 'Save' });

		if (result)
		{
			let obj = {
				task_category: task_category.task_category,
				subcategory_name: result,
				idx: 99
			};
			
			try 
			{
				let response = await fetch('/cabsav/subcategory_lookup/save', {
					method: 'POST',
					body: JSON.stringify(obj),
					headers: { 'content-type': 'application/json' }
				});

				let subcategories = task_category.subcategories;
				subcategories.forEach((val) => {
					if (val == result)
						throw new Error('Input value already exists');
				});
						
				if (response.ok)
				{
					Grape.alert({type: 'success', title: 'New subcategory saved', message: 'New subcategory saved'});
					window.Grape.cache.invalidate('TaskCategoryLookup');
					this.update();
				}
			} catch (error) {
				Grape.alert({ type: 'error', title: 'Error', message: error.message });
			}
		}
	}

	async remove_subcategory (subcategory, category) 
	{
		let response  = await Grape.prompt({ type: 'info', title: 'Remove subcategory', message: 'Are you sure you want to remove the subcategory ' + subcategory + '?' }); 

		if (response) 
		{
			window.Grape.tables.delete({
				schema: 'cabsav',
				tablename: 'task_subcategory_lookup',
				filter: {
					'task_subcategory': subcategory,
					'task_category_id': subcategory.task_category_id
				},
				done: () => {
					window.Grape.cache.invalidate('TaskCategoryLookup');
					Grape.alert({type: 'success', title: 'Subcategory removed', message: 'Subcategory removed'});
					this.update();
				}
			});
		}
	}

//add & remove status not functional in db
	//can neither add or remove statuses
/*
	async remove_status (subcategory, category) 
	{
		let response = await Grape.prompt({ type: 'info', title: 'Remove subcategory', message: `Are you sure you want to remove the subcategory status ${subcategory.status} ?` });

		if (response) 
		{
			window.Grape.tables.delete({
				schema: 'cabsav',
				tablename: 'task_subcategory_lookup',
				filter: {
					'task_subcategory': subcategory.status,
					'task_category_id': subcategory.task_category_id
				},
				done: () => {
					window.Grape.cache.invalidate('TaskCategoryLookup');
					Grape.alert({ type: 'success', title: 'Subcategory removed', message: 'Subcategory removed' });
					this.update();
				}
			});
		}
	}

	async add_status (task_category) 
	{
		let response = await Grape.prompt({ type: 'info', title: 'New status', message: 'Create new status', has_input: 'New', accept_text: 'Save' });

		console.log(response);
		console.log(task_category);

		if (response)
		{
			window.Grape.tables.insert({
				schema: 'cabsav',
				tablename: 'task_category_status',
				values: {
					'allowed_status': response,
					'task_category_id': task_category.task_category_id
				},
				done: () => {
					window.Grape.cache.invalidate('TaskCategoryLookup');
					Grape.alert({type: 'success', title: 'Status added', message: 'Status added'});
					this.update();
				}
			});
		}
	}*/
}


//Handle interaction with page 
class EditCategoriesPage
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new EditCategoriesPageViewModel(this);
		this.name = 'EditCategoriesPage';
	}

	//Event (Post/After): Load once
	init () 
	{
		document.title = 'EditCategories Page';
		this.viewModel.update();
	}

	//Event (Post/After): Refresh page data
	updateData () {}

	//Event (Pre/Before): Remove 
	teardown () {}
}

export default {
	route: '[/]edit_categories',
	page_class: EditCategoriesPage,
	template: template
}
