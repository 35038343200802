import template from './edit.html';

class EditRecurringSeriesViewModel
{
	constructor (dialog, bindings) {
		this.dialog = dialog;

		this.is_loading = ko.observable(false);

		this.recurring_task_series_id = ko.observable(null);
		this.task_id = ko.observable(bindings?.task_id || null);
		
		this.base_date = ko.observable('');
		this.interval = ko.observable('');
		this.end_date = ko.observable('');
		this.active = ko.observable(false);

		this.yesno_options = ko.observableArray([
			{ title: 'Yes', value: true },
			{ title: 'No', value: false }
		]);

		if (bindings?.recurring_task_series_id)
		{
			this.recurring_task_series_id(bindings.recurring_task_series_id);
		}
	}

	async update()
	{
		if (!(this.recurring_task_series_id()))
			return;

		let response = await fetch(`/task/recurring_series/${this.recurring_task_series_id()}`, {
			headers: {'Accept': 'application/json'}
		});
		let data = await response.json();
		if (!response.ok)
			return; //TODO

		this.base_date(data.base_date);
		this.interval(data.interval);
		this.end_date(data.end_date);
		this.active(data.active);

	}

	toJSON()
	{
		return {
			task_id: this.task_id(),
			base_date: this.base_date(),
			interval: this.interval(),
			end_date: this.end_date(),
			active: this.active()
		};
	}

	async create_series()
	{
		let obj = Object.assign({task_id: this.task_id()}, this.toJSON());

		let response = await fetch(`/task/recurring_series`, {
			method: 'POST',
			headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
			body: JSON.stringify(obj)
		});
		if (!response.ok)
		{
			throw new Error('Eeeh');
		}
		else
		{
			let data = await response.json();
			console.log('data=',data);
		}

	}

	async update_series()
	{
		let obj = Object.assign({}, this.toJSON());

		let response = await fetch(`/task/recurring_series/${this.recurring_task_series_id()}`, {
			method: 'POST',
			headers: {'Content-type': 'application/json', 'Accept': 'application/json'},
			body: JSON.stringify(obj)
		});
	}

	async btnSave_click () 
	{
		let success = false;
		try {
			if (this.recurring_task_series_id())
			{
				await this.update_series();
				Grape.alert({type: 'success', title: 'Success', message: 'Updated!'});
			}
			else
			{
				await this.create_series();
				Grape.alert({type: 'success', title: 'Success', message: 'Created!'});
			}
			success = true;
		} catch (err) {
			Grape.alert({type: 'error', title: 'Error', message: err.message});
			success = false;
		}
		if (success === true)
			this.dialog.close();
	}
	
	async btnClose_click () 
	{
		this.dialog.close();
	}

}

class EditRecurringSeriesDialog 
{
	constructor (bindings) {
		this.viewModel = new EditRecurringSeriesViewModel(this, bindings);
		this.bindings = bindings;
		this.name = 'EditRecurringSeries';
	}

	init ()
	{
		this.viewModel.update();
	}
}

export default {
	name: 'EditRecurringSeries',
	page_class: EditRecurringSeriesDialog,
	template: template
}

