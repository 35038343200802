import template from './users.html';

class UsersViewModel 
{
	constructor (page) {
	this.page = page;
	}
}

class UsersPage
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new UsersViewModel(this);
		this.name = 'UsersPage';
		document.title = 'Users';
	}
	
	init () 
	{ 	
		this.tbl_users = $("#tbl_users").bootstrapTable({
			rowStyle: (value, row, index) => {
				if (value.active == false) {
					return { 
						css: {
							"background-color": "rgba(210,110,110,0.1)",
							"color": "rgba(120,120,120,0.8)",
							"border": "none",
						}
					}
				}
				return {classes: ''};
			},
			columns: [
				{field: 'username', title: 'Name', sortable: true, formatter: (v, r, i) => { return r.username + (r.fullnames ? ' (' + r.fullnames + ')' : ''); }},
				{field: 'email', title: 'Email'},
				{field: 'software_unit_names', 
					title: 'Software Units',  
					formatter: (value, row, index) => {
						if (value == null)
							return "No Software Units";
						
						return value.join(", ");
					}
				},
				{
					field: 'role_names', 
					title: 'Roles', 
					formatter: (value, row, index) => {
						if (value == null)
							return "No Roles";
						
						return value.join(", ");
					}
				}
				//UserEditSoftwareUnits not functional
				/*,
				{
					field: 'action', 
					width: '100px', 
					align: 'right',
					title: 'Actions', 
					formatter: (value, row, index) => {
						let ret = [
							'<a class="edit" title="Edit" href="javascript:void(0)">',
							'<i class="glyphicon glyphicon-edit"></i>',
							'</a>&emsp;'
						].join('');
						return ret;
					}, 
					events: 
					{
						//FUNCTION: Change User to Deactive
						'click .edit': async (e, value, row, index) => {
							/*Grape.show_dialog(
								'UserEditSoftwareUnits', 
								{ row: row }, 
								{
									onClose: (result) => {
										this.updateData();
									}
								}
							);
							let result = await Grape.dialog.open('UserEditSoftwareUnits',{ row: row });

							if (result)
								this.updateData();
						}
					}
				}*/
			],

			//FEATURE: Pagination
			pagination: true,
			pageList: [10, 20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			dataToolbar: '#toolbar',
			dataField: 'records',
			onPageChange: (number, size) => {
				this.updateData();
			},

			//FEATURE: Sorting
			onSort: (sortName, sortOrder) => {
				this.updateData();
			},

			//FEATURE: Searching
			search: true,
			searchOnEnterKey: true,
			onSearch: (text) => {
			}
		});
	}

	//EVENT: Update Bootstrap table 
	async updateData () 
	{
		//STRUCT: User
		let options = {
			table: 'v_cabsav_users', 
			schema: 'cabsav',
			offset: 0,
			limit: 20,
			sortfield: 'user_id',
			sortorder: 'ASC'
		};

		let table_options = $("#tbl_users").bootstrapTable('getOptions');

		//LOGIC: Sort instantiate
		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}
	
		//LOGIC: Search instantiate
		if (table_options.searchText && table_options.searchText != "")
		{
			options.filter = [{field: 'username', operand: 'ILIKE', value: '%' + table_options.searchText + '%'},
			{field: "fullnames", operand: 'ILIKE', value:  '%' + table_options.searchText + '%'},
			{field: "email", operand: 'ILIKE', value:  '%' + table_options.searchText + '%'},
			{field: "role_names", operand: 'ILIKE', value:  '%' + table_options.searchText + '%'},
			{field: "software_unit_names", operand: 'ILIKE', value:  '%' + table_options.searchText + '%'}
			];
			options.filter_join = 'OR'
		}

		console.log(options);

		//LOGIC: Page size and offset instantiate
		if (table_options.pageNumber && table_options.pageSize)
		{
			options.limit = table_options.pageSize;
			options.offset = (table_options.pageNumber - 1) * options.limit;
		}

		//SERVER: Update Bootstrap table
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', options);

			if (result.status == 'OK')
				$("#tbl_users").bootstrapTable('load', result);
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	route: '[/]cabsavadmin/users/',
	page_class: UsersPage,
	template: template
}
