
import CKEditorUploadAdapter from './CKEditorUploadAdapter.js';
export default {
	toolbar: {
		items: [
			'heading',
			'|', 'bold', 'italic', 'underline', 'link', 'bulletedList', 'numberedList',
			'|', 'fontSize', 'fontColor', 'fontBackgroundColor', 'highlight',
			'|', 'indent', 'outdent', 'alignment',
			'|', 'blockQuote', 'insertTable', 'htmlEmbed',
			'|', 'imageUpload', 'imageInsert',
			'|', 'removeFormat', 'undo', 'redo'
		]
	},
	image: {
		styles: ['alignLeft', 'alignCenter', 'alignRight'],
		toolbar: ['imageStyle:alignLeft', 'imageStyle:alignCenter', 'imageStyle:alignRight', '|', 'imageResize']
	},
	table: {
		contentToolbar: [
			'tableColumn',
			'tableRow',
			'mergeTableCells',
			'tableCellProperties',
			'tableProperties'
		]
	},
	extraPlugins: [CKEditorUploadAdapter]
};
