

export default {
	name: 'DeveloperLookup',
	options: {
		refresh: async function() {
			let response = await fetch('/request/get/developers', {headers: {'Accept': 'application/json'}});
			let result = await response.json();
			return result;
		}
	}
};


