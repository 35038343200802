import template from './view_task.html';
import ClassicEditor from '../../lib/ckeditor/build/ckeditor';
import ClassicEditorConfig from '../../lib/ClassicEditorConfig.js';

window.toggle_notification = async (model, e) => 
{
	model.is_checked(!model.is_checked());
	let notification_type = $(e.currentTarget).data('notification_type');
	let task_id = $(e.currentTarget).data('task_id');
	let obj = {
		task_id: task_id,
		notification_type: notification_type,
		delivery_type: 'notify',
		active: model.is_checked()
	};

	try 
	{
		await fetch('/user/update_notification_setting', {
			method: 'POST',
			body: JSON.stringify(obj),
			headers: { 'content-type': 'application/json' }
		});
	} catch (err) {
		Grape.alert({ title: 'Error', type: 'error', message: err.message });
		console.error(err);
	}
}

window.toggle_email_notification = async (model, e) => 
{
	model.is_checked(!model.is_checked());
	let notification_type = $(e.currentTarget).data('notification_type');
	let task_id = $(e.currentTarget).data('task_id');
	let obj = {
		task_id: task_id,
		notification_type: notification_type,
		delivery_type: 'email',
		active: model.is_checked()
	};

	try 
	{
		await fetch('/user/update_notification_setting', {
			method: 'POST',
			body: JSON.stringify(obj),
			headers: { 'content-type': 'application/json' }
		});
	} catch (err) {
		Grape.alert({ title: 'Error', type: 'error', message: err.message });
		console.error(err);
	}
}

class ViewTaskViewModel 
{
	constructor(page) {
		this.page = page;

		this.task_id = ko.observable();
		this.task_description = ko.observable();
		this.short_description = ko.observable();
		this.full_description = ko.observable();
		this.status = ko.observable();
		this.requested_by = ko.observable();
		this.created_by = ko.observable();
		this.category = ko.observable();
		this.subcategory = ko.observable();
		this.priority = ko.observable();
		this.product_owner = ko.observable();
		this.message_list = ko.observableArray([]);
		this.attachments = ko.observableArray([]);
		this.tags = ko.observableArray([]);

		this.edit_tags_show = ko.observable(true);

		this.notifications = ko.observableArray([]);
		this.email_notifications = ko.observableArray([]);

		this.child_tasks = ko.observableArray([]);
		this.completed_child_count = ko.observable(0);
		this.total_child_count = ko.observable(0);

		this.total_hours_logged = ko.observable(0);

		this.task = ko.observable({});
		this.task_progress = ko.observableArray([]);

		this.parent_task_id = ko.observable(0);
		this.parent_task_nr = ko.observable('');
		this.parent_task_description = ko.observable('');

		this.developer_lookup = ko.observableArray([]);
		this.developer_list = ko.observableArray([]);
		this.developer_list_tmp = ko.observableArray(['Billy']);
		this.notification_type_list = ko.observableArray([]);
		this.selected_software_units = ko.observableArray([]);
		this.selected_developers =ko.observableArray([]);
		this.all_category_status_list = ko.observableArray();
		this.status_list = ko.observableArray();
		this.selected_status = ko.observable();

		this.down_status = ko.observable();
		this.up_status = ko.observable();

		this.description_edit = ko.observable(false);

		this.recurring_task_series_id = ko.observable(null);
		this.recurring_seq_no = ko.observable('');
		this.recurring_last_seq_no = ko.observable('');

		/* stopwatch variables */
		this.timer = ko.observable('Start Timer');
		this.stop_watch_status = ko.observable('Pending');
		this.stop_watch_date_started = ko.observable();
		this.stop_watch_date_paused = ko.observable();
		this.stop_watch_date_stopped = ko.observable();
		this.stop_watch_hours_logged_rounded = ko.observable();
		this.stop_watch_time_logged = ko.observable();
		this.stop_watch_time = ko.observableArray();

		this.status_update_visible = ko.observable(false);
		this.assigned_update_visible = ko.observable(false);
		this.toggle_status_update = () => {
			this.status_update_visible(!this.status_update_visible());
		}	
		this.toggle_assigned_update = () => {
			this.assigned_update_visible(!this.assigned_update_visible());
		}	
		
	}

	description_edit_click () 
	{
		if (this.description_edit() === false)
		{
			this.description_edit(true);
			this.page.enable_description_editing();
		}
	}
	
	async description_save_click () 
	{
		let data = this.page.ckeditor.getData();
		this.description_edit(false);
		this.page.disable_description_editing();

		$('.modal-loader').show();
		let obj = {
			task_id: this.task_id(),
			full_description: data
		};

		try
		{
			await fetch('/api/task', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});

			this.page.something_changed = true;
			this.page.refresh_data();
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err });
			console.error(err);
		}
	}

	change_status_down () 
	{
		if (this.down_status())
		{
			this.selected_status(_.find(this.status_list(), (d) => { return d.status == this.down_status(); }));
			if (this.selected_status())
				this.update_status();
		}
	}

	change_status_up () 
	{
		if (this.up_status())
		{
			this.selected_status(_.find(this.status_list(), (d) => { return d.status == this.up_status(); }));
			if (this.selected_status())
				this.update_status();
		}
	}
	show_hide_conversation(){

		$('.task-conversation').toggle();
		$('.task-conversation-icon').toggle();

		if($( ".task-desc" ).hasClass( "col-md-9" ))
		{
			$( ".task-desc" ).removeClass( "col-md-9" );
			$( ".task-desc" ).addClass( "col-md-11" );
		}
		else
		{
			$( ".task-desc" ).removeClass( "col-md-11" );
			$( ".task-desc" ).addClass( "col-md-9" );
		}
	}
	attachment_added () 
	{
		this.page.refresh_data();
	}

	new_message () 
	{
		this.page.refresh_data();
	}

	async update_status () 
	{
		this.status_update_visible(false);
		$('.modal-loader').show();
		let obj = {
			task_id: this.task_id(),
			status: this.selected_status().status
		};

		try
		{
			await fetch('/api/task', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});
			
			this.page.something_changed = true;
			this.page.refresh_data();
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	async assign_task () 
	{
		//let developer = this.developer_lookup().find((x) => x.username == this.selected_developer());
		this.status_update_visible(false);
		this.assigned_update_visible(false);
		$('.modal-loader').show();
		let obj = {
			task_id: this.task_id(),
			assigned_to: this.selected_developers()
		};

		try
		{
			await fetch('/api/task', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});
			
			this.page.something_changed = true;
			this.page.refresh_data();
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}
	unassign(){
		this.selected_developers([]);
		this.assign_task();
	}

	enable_notification (e, data) 
	{
		e.preventDefault();
		return false;
	}

	disable_notification (data) 
	{
		return false;
	}

	// TODO link this function up
	async open_image (image) 
	{
		await Grape.dialog.open('ImageViewDialog', { filename: image, attachments: [] });
	}

	btnViewHistory_click()
	{
		window.GrapeLocals.TaskActions['view_history']({task_id: this.task_id()});
	}

	async btnCreateRecurringSeries_click()
	{
		let result = await window.GrapeLocals.TaskActions['recurring-series']({
			task_id: this.task_id(),
			recurring_task_series_id: null
		});
		await this.page.refresh_data();
	}

	async btnViewRecurringSeries_click()
	{
		let result = await window.GrapeLocals.TaskActions['recurring-series']({
			task_id: this.task_id(),
			recurring_task_series_id: this.recurring_task_series_id()
		});
		await this.page.refresh_data();

	}

	async btnCreateNextRecurringTask_click()
	{
		let response = await fetch(`/task/recurring_series/${this.recurring_task_series_id()}/create-next`, {method: 'POST'});
		await this.page.refresh_data();
	}
}

class ViewTaskDialog
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new ViewTaskViewModel(this);
		this.task = null;
		this.task_id = null;
		this.something_changed = false;
		this.ckeditor = null;
		this.counter = 0;
		this.runClock;
	}

	init () 
	{
		//setTimeout(() => { $('.selectpicker').selectpicker('rebuild'); }, 0);
		setTimeout(() => { $('.selectpicker').selectpicker('refresh'); }, 0);
		let _roles = Grape.cache.Grape.currentSession.user.user_roles;

		if( _roles.includes("dev") || _roles.includes("admin")  )
			this.viewModel.edit_tags_show(true);
		else
			this.viewModel.edit_tags_show(false);
			
		if (this.bindings.task)
			this.task_id = this.bindings.task.task_id;
		else if (this.bindings.task_id)
			this.task_id = this.bindings.task_id;

		this.viewModel.task_id(this.task_id);
		this.get_stopwatch();
		this.refresh_data();
		$('a.notifications[data-toggle="popover"]').popover({
				placement: 'bottom',
				html: true,
				content: $('#notification-settings-popover')
		});

		$('a.emailnotifications[data-toggle="popover"]').popover({
				placement: 'bottom',
				html: true,
				content: $('#email-notification-settings-popover')
		});

		$('a[data-toggle="popover"]').on('shown.bs.popover', () => 
		{
         $('div', '.popover-content').removeClass('hide'); 
      });

	  $('.selectpicker').selectpicker('refresh');
	}

	enable_description_editing () 
	{
		// INITIALISE: CKEditor initialisation
		if (!this.ckeditor)
		{
			let el;
			if (this.target)
				el = this.target.querySelector('#span_full_description');
			else
				el = document.getElementById('span_full_description');

			ClassicEditor.create(el, 
					Object.assign({}, ClassicEditorConfig, {
						height: '350px'
					})
			).then((editor) => {
				this.ckeditor = editor;
				this.ckeditor.fileUploadURL = '/upload_task_attachment?task_id=' + this.task_id;
				this.ckeditor.fileUploadMethod = 'POST';
				this.ckeditor.fileUploadField = 'attachment';

			}).catch((err) => {
				console.error(err);
			});

		}
	}

	disable_description_editing () 
	{
		if (this.target)
			var el = this.target.querySelector('#span_full_description');
		else
			var el = document.getElementById('span_full_description');

		if (el)
			$(el).attr('contenteditable', false);
		if (this.ckeditor)
			this.ckeditor.destroy();
		this.ckeditor = null;
	}

	refresh_data () 
	{
		$('.modal-loader').show();
		//this.viewModel.developer_list_tmp(['ddd','jesff']);
		setTimeout(() => { $('.selectpicker').selectpicker('refresh'); }, 0);

		Promise.allSettled([
			new Promise((resolve, reject) => {
				Grape.cache.fetch('TaskCategoryLookup', (data) => {
					if (data)
						this.viewModel.all_category_status_list(data);
					resolve(null);
				});
			}),
			new Promise((resolve, reject) => {
				Grape.cache.fetch('NotificationTypeLookup', (data) => {
					if (data)
						this.viewModel.notification_type_list(data);
					resolve(null);
				});
			}),
			new Promise((resolve, reject) => {
				Grape.cache.fetch('SoftwareUnitUsernames', (data) => {
					setTimeout(() => { $('.selectpicker').selectpicker('rebuild'); }, 0);
					resolve(null);
				});
			}),
			new Promise((resolve, reject) => {
				Grape.cache.fetch('DeveloperLookup', (data) => {
					this.viewModel.developer_lookup(data);
					resolve(null);
				});
			}),

		])
		.then(() => {
			this.get_request_data(this.task_id, (res) => {
				$('.modal-loader').hide();
			});
		});


	}

	async get_request_data (task_id, childCallBack) 
	{
		
		let result = await Grape.fetches.getJSON(`/task/${task_id}`,{});
		
		if (result.status == 'OK') 
		{
			let task = result.task;
			if (task.assigned_to.length == 0)
			{
				task.assigned_to = 'None';
				this.viewModel.assigned_update_visible(true);
			}
			else
				task.assigned_to = task.assigned_to.join(', ');

			if (!task.deadline)
				task.deadline = 'None';

			if (!task.planned_start_date)
				task.planned_start_date = 'None';

			if (!task.roadmap_name)
				task.roadmap_name = 'None';

			if (!task.full_description)
				task.full_description = 'None';

			task.is_parent = (task.child_count > 0) ? 'Yes' : 'No';
			task.is_child = (task.parent_task_id) ? 'Yes' : 'No';

			this.viewModel.parent_task_id(task.parent_task_id);
			this.viewModel.parent_task_nr(task.parent_task_number);
			this.viewModel.parent_task_description(task.parent_task_description);
			
			this.viewModel.recurring_task_series_id(task.recurring_task_series_id);
			this.viewModel.recurring_seq_no(task.seq_no);
			this.viewModel.recurring_last_seq_no(task.last_seq_no);

			if (task.difficulty <= 1)
				task.difficulty_str = 'Easy';
			else if (task.difficulty == 2)
				task.difficulty_str = 'Medium';
			else if (task.difficulty >= 3)
				task.difficulty_str = 'Hard';

			this.task = task;
			this.viewModel.task(task);
			this.viewModel.message_list(task.messages || []);
			this.viewModel.attachments(task.attachments || []);
			this.viewModel.tags(task.tags || []);

			if (!task.child_tasks)
				task.child_tasks = [];
			let completed = 0;
			let completed_statuses = [
				'Rejected', 'Resolved', 'Obsolete', 'Deferred', 'Solution Ready'
			];
			task.child_tasks.forEach((d) => {
				if (d && completed_statuses.indexOf(d.status) >= 0)
				{
					completed++;
				}
			});
			this.viewModel.total_child_count(task.child_tasks.length);
			this.viewModel.completed_child_count(completed);
			this.viewModel.child_tasks(task.child_tasks);

			this.viewModel.all_category_status_list().forEach((s) => {
				if (s.task_category == task.category)
				{
					// TODO break loop
					this.viewModel.status_list(s.allowed_statuses);
				}
			});

			if (!task.notifications)
				task.notifications = [];
			if (!task.email_notifications)
				task.email_notifications = [];
			
			let notifications = [];
			this.viewModel.notification_type_list().forEach((o) => {
				let d = Object.assign({},o);
				if (task.notifications.indexOf(d.notification_type) >= 0)
					d.is_checked = ko.observable(true);
				else
					d.is_checked = ko.observable(false);

				notifications.push(d);
			});
			
			this.viewModel.notifications(notifications);
			let email_notifications = [];

			this.viewModel.notification_type_list().forEach((d) => 
			{
				let o = Object.assign({},d);
				if (task.email_notifications.indexOf(o.notification_type) >= 0)
					o.is_checked = ko.observable(true);
				else
					o.is_checked =  ko.observable(false);

				email_notifications.push(o);
			});

			this.viewModel.email_notifications(email_notifications);
			let total_hours = 0;
			if (task.task_times !== null)
			{
			task.task_times.forEach((tt) => {
				total_hours += tt.hours;
			});
			}

			this.viewModel.total_hours_logged(total_hours);
			this.viewModel.selected_status(_.find(this.viewModel.status_list(), (d) => { return d.status == task.status; }));
		await	Grape.cache.fetch('SoftwareUnitUsernames', (data) => 
			{
				if (data)
				{

					let devs = [];
					task.software_unit_ids.forEach((software_unit_id) => {

						let software_unit = _.find(data, (d) => { return d.software_unit_id == software_unit_id; });
						if (software_unit){
							devs = _.uniq(devs.concat(software_unit.devs));

						}
					});
					let new_devs_objects = [];
					devs.forEach(async (d) => {
						let dev =  this.viewModel.developer_lookup().find((dev) => { return dev.username == d });		
						if (dev)
							new_devs_objects.push(dev);
						else
							throw new Error(`Unable to locate dev username=${d}`);
					});
					this.viewModel.developer_list(new_devs_objects);
				}
				//childCallBack(task);
			});


			this.viewModel.up_status(this.get_up_status(task.status, task.category));
			this.viewModel.down_status(this.get_down_status(task.status, task.category));
			$('.selectpicker').selectpicker('refresh');
			childCallBack(task);
		}
	}

	// TODO MAKE THIS DYNAMIC (IE GET DATA FROM SERVER) see CS745: Create CabSav Util Library
	get_up_status(_current_status, category) 
	{
		switch (_current_status)
		{
			case 'New':
				if (category == 'Request')
					return 'Accepted';
				else
					return 'In Progress';
			case 'Accepted':
				return 'In Progress';
			case 'In Progress':
				if (category == 'Development')
					return 'Solution Ready';
				else if (category == 'Request')
					return 'Pending Review';
				else
					return 'Resolved';
			case 'Solution Ready':
				return 'Resolved';
			case 'Pending Review':
				return 'Successful Review';
			case 'Failed Review':
				return 'In Progress';
			case 'Successful Review':
				return 'Resolved';
			case 'On Hold':
				return 'In Progress';
			case 'Rejected':
				return 'Accepted';
			case 'Deferred':
				return 'New';
			case 'Obsolete':
				return 'New';
			default:
				return null;
		}
	}

	// see CS745: Create CabSav Util Library
	get_down_status(_current_status, category) 
	{
		switch (_current_status)
		{
			case 'New':
				if (category == 'Request')
					return 'Rejected';
				else
					return 'Obsolete';
			case 'Accepted':
				return 'Rejected';
			case 'In Progress':
				return 'On Hold';
			case 'On Hold':
				return 'Deferred';
			case 'Deferred':
				return 'Obsolete';
			case 'Obsolete':
				return 'Deferred';
			case 'Pending Review':
				return 'Failed Review';
			case 'Failed Review':
				return 'Rejected';
			case 'Successful Review':
				return 'Failed Review';
			case 'Solution Ready':
				if (['Development'].indexOf(category) > -1)
					return 'In Progress';
				else
					return null;
			default:
				return null;
		}
	}

	async open_parent_task () 
	{
		try
		{
			let task_id = await Grape.dialog.open('ViewTask', { task_id: this.viewModel.task().parent_task_id });
			
			if (task_id)
			{
				this.get_request_data();
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	async edit_request () 
	{
		try
		{
			let changed = await Grape.dialog.open('EditTask', { task: this.task });
			
			if (changed)
			{
				this.something_changed = true;
				this.refresh_data();
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	async edit_tags () 
	{
		try 
		{
			let result = await Grape.dialog.open('TagsEdit', { tags: this.task.tags })

			if (result?.updated)
			{
				let res = await Grape.fetches.postJSON('/task/tag', { task_id: this.task.task_id, tags: result.tags });
				if (res.status === 'OK')
					Grape.alert({ type: 'success', title: 'Success', message: 'Tags updated' });
				else
					throw new Error(res);

				if (result?.new_tags)
				{
					Grape.cache.invalidate('TaskTagLookup');
				}

				this.something_changed = true;
				this.refresh_data();
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	task_linking () 
	{
		window.GrapeLocals.TaskActions['linked-tasks'](this.task, (done) => {
			this.refresh_data();
		});
	}

	view_child_tasks () 
	{
		$('div.child_tasks').toggle();
	}

	async create_child_task () 
	{
		try
		{
			let changed = await Grape.dialog.open('EditTask',
			{
				parent_task_id: this.task.task_id,
				parent_task_description: this.task.short_description,
				parent_task_nr: this.task.task_nr,
				software_unit_name: this.task.software_unit_name,
				category: this.task.category,
				department: this.task.department
			});

			if (changed)
			{
				this.page.something_changed = true;
				this.refresh_data();
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	child_task_click (child_task) 
	{
		window.GrapeLocals.TaskActions['view'](child_task, (done) => {
			this.refresh_data();
		});
	}

	parent_task_click () 
	{
		window.GrapeLocals.TaskActions['view']({ task_id: this.task.parent_task_id }, (done) => {
			this.refresh_data();
		});
	}

	edit_dev_hours () 
	{
		window.GrapeLocals.TaskActions['hours'](this.task, (done) => {
			this.refresh_data();
		});
	}

	async toggle_timer (time_status)
	{
		try
		{
			let data = { task_id: this.task_id, status: time_status, time_logged: this.viewModel.stop_watch_time_logged() }
			let response = await fetch('/stopwatch/stopwatch_upsert', 
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: { 'content-type': 'application/json' }
			});
			
			if (response.ok)
			{
				this.get_stopwatch();
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	async get_stopwatch () 
	{
		try
		{
			let res = await Grape.fetches.getJSON('/api/record', {
				schema: 'cabsav',
				table: 'task_time_stopwatch',
				filter: [
					{ 
						value: this.task_id, 
						op: '=', 
						field: 'task_id' 
					}, {
						value: [
							'Pending', 'Started', 'Paused'
						],
						op: 'IN',
						field: 'status'
					}
				],
				limit: 1
			});

			if (res.status == 'OK')
			{
				if (res.records[0] != null)
				{
					this.viewModel.stop_watch_time(res.records[0]);

					if (res.records[0].time_logged != null)
						this.viewModel.timer(res.records[0].time_logged);

					this.viewModel.stop_watch_status(res.records[0].status);
					this.viewModel.stop_watch_date_started(res.records[0].date_started);
					this.viewModel.stop_watch_date_paused(res.records[0].date_paused);
					this.viewModel.stop_watch_time_logged(res.records[0].time_logged);

					this.viewModel.stop_watch_date_stopped(res.records[0].date_stopped);
					this.viewModel.stop_watch_hours_logged_rounded(res.records[0].hours_logged_rounded);

					if (this.viewModel.stop_watch_status() == 'Started' && this.viewModel.stop_watch_date_paused() == null)
					{
						const startDate = moment(this.viewModel.stop_watch_date_started());
						this.runClock = setInterval(() => {
							let elapsedDuration = moment.duration(moment().diff(startDate));
							this.viewModel.timer(moment.utc(elapsedDuration.as('milliseconds')).format('HH : mm : ss'));
						}, 1000);
					}

					if (this.viewModel.stop_watch_status() == 'Started' && this.viewModel.stop_watch_date_paused() != null)
					{
						let startDate = moment(this.viewModel.stop_watch_date_started());
						let now = moment(new Date());
						let duration = moment.duration(now.diff(startDate));
						let seconds = duration.asSeconds();

						let hms = this.viewModel.stop_watch_time_logged(); 
						let a = hms.split(':'); 

						this.counter = (+a[0]) * 60 * 60 + (+a[1]) * 60 + (+a[2]);

						this.counter = this.counter + seconds;

						this.runClock = setInterval(() => {
							this.viewModel.timer(moment().hour(0).minute(0).second(this.counter++).format('HH : mm : ss'));
						}
							, 1000);
					}
				}
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	async stop_watch () 
	{
		this.viewModel.stop_watch_time_logged(this.viewModel.timer());

		try
		{
			let data = { task_id: this.task_id, status: 'Stopped', time_logged: this.viewModel.stop_watch_time_logged() };
			let response = await fetch('/stopwatch/stopwatch_upsert', {
				method: 'POST',
				body: JSON.stringify(data),
				headers: { 'content-type': 'application/json' }
			}).then(res=>res.clone().json())
			.then( (json) =>{
				return json});
		  

		
			if (response.status === 'OK')
			{
				clearInterval(this.runClock);

				
				let data = {
					task: this.task,
					stop_watch_time: response.stop_watch
				};

				this.viewModel.timer('Start Timer');
				this.viewModel.stop_watch_status('Pending');
				this.get_stopwatch();
				
				window.GrapeLocals.TaskActions['hours'](data, (done) => {
				
					this.refresh_data();
				});
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	async pause_timer () 
	{
		try
		{
			let data = { task_id: this.task_id, status: 'Paused', time_logged: this.viewModel.timer() };
			let response = await fetch('/stopwatch/stopwatch_upsert', 
			{
				method: 'POST',
				body: JSON.stringify(data),
				headers: { 'content-type': 'application/json' }
			});
			
			if (response.ok)
			{
				this.get_stopwatch();
				clearInterval(this.runClock);
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	updateData () {}

	close_dialog () 
	{
		this.disable_description_editing();
		//Grape.removeListener('dialog_shown', this.bind_popovers);
		$('.selectpicker').selectpicker('close');


		this.close(this.something_changed);

	
		 
	}
}

export default {
	name: 'ViewTask',
	page_class: ViewTaskDialog,
	template: template
}
