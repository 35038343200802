export default {
	name: 'SoftwareUnitList',
	options: {
		refresh: function(cb) {
			var req = $.getJSON('/software_units', {}, function(result) {
				cb(null, result);
			});
			//TODO error handle
		}
	}
};


