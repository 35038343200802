import template from './attachment-list.html';

class AttachmentListVM
{
	constructor (options) {
		this.options = options;
		this.attachments = ko_helper.safe_observableArray(options.attachments);
		this.task_id = ko_helper.safe_observable(options.task_id);
		this.loading = ko.observable(false);

		console.log("Got attachments",this.attachments());
	}

	attachment_clicked (attachment) 
	{
		console.log(attachment);
		window.open(this.get_href(attachment));
	}

	upload_attachment_clicked (data, e) 
	{
		let uploader = `#div_upload_bar_${this.task_id()}`;
		$(uploader).toggle();
		
		console.log(this.options.attachments());
		console.log(this.options);
	}

	before_upload () 
	{
		console.log(this);
	}

	after_upload (value) 
	{
		console.log(value)
		console.log(this.options.attachments());
		if (this.options.after_upload())
			this.options.after_upload();
	}

	get_fa_class (filetype) 
	{
		if (filetype == 'png')
			return 'fa-file-image-o';
		else
			return 'fa-file';
	}

	get_href (file) 
	{
		return '/download/task_attachment?filepath=' + encodeURIComponent(file.filepath) + '&filename=' + encodeURIComponent(file.filename);
	}

	async delete_attachment (file) 
	{
		console.log('delete file', file);

		let response = await Grape.prompt({
			title: 'Delete file confirmation',
			message: 'Are you sure you want to delete this file?', 
		});

		if (response)
		{
			try
			{
				let obj = {
					task_attachment_id: file.task_attachment_id
				};

				let result = await fetch('/remove_task_attachment', {
					method: 'POST',
					body: JSON.stringify(obj),
					headers: { 'content-type': 'application/json' }
				});
				
				if (result.ok)
				{
					Grape.alert('File has been deleted');
				
					this.attachments().splice(this.attachments().indexOf(file), 1);
					this.after_upload();
				}
			} catch (error) {
				Grape.alert({ title: 'Error', type: 'error', message: error.message });
				console.error(error);
			}
		}
	}
}

export default {
	name: 'attachment-list',
	viewModel: AttachmentListVM,
	module_type: 'ko',
	template: template
}
