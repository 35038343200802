export default {
	name: 'TaskTagLookup',
	options: {
		refresh: function (cb) {
			$.getJSON('/user/task_tag_lookup', {}, function (result) {
				if (!result)
					return cb(null, []);

				let tags = result.tags;
				tags = _.filter(tags, t => {
					return t && t !== 'null' && t !== 'NULL';
				});

				cb(null, tags || []);
			});
		}
	}
};
