
import template from './dialog.html';

class DialogViewModel
{
	constructor (dialog)
	{
		this.dialog = dialog;
		this.fname = ko.observable();
		this.lname = ko.observable();
		this.titles = ko.observableArray();
		this.title = ko.observable();
		this.available_roles = ko.observableArray([{name: 'Option 1', value: 1}, {name: 'Option 2', value: 2}, {name: 'Option 3', value: 3}]);
		this.selected_roles = ko.observableArray();
	}

	save_click ()
	{
		this.dialog.close({
			fname: this.fname(), 
			lname: this.lname(),
			title: this.title(),
			roles: this.selected_roles()
		});
	}

	close_click ()
	{
		this.dialog.close(false);
	}
}

class DialogClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.viewModel = new DialogViewModel(this);
		this.name = 'NewDialog';
		this.element = element;
	}

	init ()
	{
		const self = this;
		let init_promises = [];
		init_promises.push(new Promise(function(resolve, reject) {
			Grape.cache.fetch('PersonTitleLookup', (person_titles) => {
				self.viewModel.titles(person_titles);
				resolve();
			});
		}));

		$('.select2', this.element).select2();

		return Promise.all(init_promises);
	}
}

export default {
	name: 'NewDialog',
	dialog_class: DialogClass,
	template: template
}
