export default {
	name: 'Roadmaps',
	options: {
		schema: 'cabsav',
		table: 'v_roadmaps',
		fields: ['roadmap_id', 'roadmap_name']
	}
};


