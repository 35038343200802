import Columns from './Columns.js';

// Instantiate Page Variables
class TaskFilterPageVM
{
	constructor(page) {
		this.page = page;

		this.is_loading = ko.observable(false);
		this.is_busy_executing = ko.observable(false); // busy executing filter

		this.task_list = ko.observableArray();
		this.saved_filters = ko.observableArray([]);
		this.selected_filter = ko.observable();

		this.current_filter = ko.observable();
		this.current_result_table_name = ko.observable(null);
		this.current_result_table_schema = ko.observable(null);

		//experimental
		this.bulk_update_visible = ko.observable(false);

		this.can_save = ko.computed(() => {
			if (!this.selected_filter())
				return true;
			if (this.selected_filter().username == window.Grape.currentSession.user.username)
				return true;
			return false;
		});

		this.can_delete = ko.computed(() => {
			if (!this.selected_filter())
				return false;
			if (!window.Grape.currentSession || !window.Grape.currentSession.user.username)
				return false;

			if (this.selected_filter().username == window.Grape.currentSession.user.username)
				return true;
			return false;
		}, this);

		this.selected_view_type = ko.observable('card_view');
		this.selected_view_type.subscribe(() => {
			if (this.selected_view_type() === 'table_view')
			{
				let cardItems = this.selected_items();

				let finalItems = [];
				cardItems.forEach(val => {
					finalItems.push(val.task_id);
				});

				$('#tbl_table').bootstrapTable('uncheckAll');
				$('#tbl_table').bootstrapTable('checkBy', {field: 'task_id', values: finalItems },);
			}
		});
		this.show_filter_settings = ko.observable(false);

		this.request_count = ko.observable(0);
		this.current_page_number = ko.observable(1);
		this.page_size = ko.observable(20);

		this.filter_has_changed = ko.observable(false);
		this.filter_name = ko.observable('');
		this.globally_visible = ko.observable(false);
		this.shared_to_software_unit = ko.observable(false);
		this.visible_in_navbar = ko.observable(false);
		this.is_user_default = ko.observable(false); // TODO

		this.selected_items = ko.observableArray([]);
		this.possible_columns = ko.observableArray(Columns);

		this.possible_formatting_columns = ko.observableArray([
			{
				field: 'status',
				title: 'Status'
			},
			{
				field: 'software_units',
				title: 'Software Units'
			},
			{
				field: 'priority',
				title: 'Priority'
			},
			{
				field: 'date_assigned',
				title: 'Date Assigned'
			},
			{
				field: 'date_created',
				title: 'Date Created'
			},
			{
				field: 'deadline',
				title: 'Deadline'
			},
			{
				field: 'category',
				title: 'Category'
			},
			{
				field: 'subcategory',
				title: 'Subcategory'
			},
			{
				field: 'department',
				title: 'Department'
			},
			{
				field: 'impact',
				title: 'Impact'
			},
			{
				field: 'assigned_to',
				title: 'Assigned To'
			},
			{
				field: 'requested_by',
				title: 'Requested By'
			},
			{
				field: 'tags',
				title: 'Tags'
			},
			{
				field: 'difficulty',
				title: 'Difficulty'
			},
			{
				field: 'unread_notification_count',
				title: 'Unread Notification Count'
			}
		]);

		this.get_column_def = (field) => {
			let cols = this.possible_columns();

			for (let i = 0; i < cols.length; i++)
				if (cols[i].field == field)
					return cols[i];

			return null;
		}

		this.default_columns = ko.observableArray([
			this.get_column_def('task_nr'),
			this.get_column_def('short_description'),
			this.get_column_def('status'),
			this.get_column_def('assigned_to')
		]);

		this.selected_columns = ko.observableArray([]);

		this.priority_color = (prio) => {
			if (prio == 'Low')
				return '#17a2b8';
			if (prio == 'Medium')
				return '#28a745';
			if (prio == 'High')
				return '#ffc107';
			if (prio == 'Urgent')
				return '#dc3545';
		}

		// DISPLAY SETTINGS
		this.edit_columns_toggle = () => {
			if (this.selected_columns().length == 0)
				this.selected_columns(this.default_columns());
			$('#show_cols_selector').toggle();
		}

		this.column_selector_updated = () => {
			$('#show_cols_selector').hide();
			this.page.update_table_data();
		}

		this.sorting_selector_updated = () => {
			$('#edit_sorting_fields').hide();
			this.page_changed();
		}

		this.edit_formatting_toggle = () => {
			// if (self.selected_columns().length == 0)
			//	self.selected_columns(self.default_columns());
			$('#show_conditional_formatting').toggle();
		}

		this.formatting_popup_done = () =>  {
			$('#show_conditional_formatting').hide();
			this.page.update_table_data();
		}

		this.edit_sorting_toggle = () => {
			$('#edit_sorting_fields').toggle();
		}

		this.view_stats = () => {
			Grape.dialog.open('FilterStats', { table_name: this.current_result_table_name() });
		}

		this.change_page = (pagenr, pagesize) => {
			this.page_size(pagesize);
			if (this.current_page_number() != pagenr)
				this.current_page_number(pagenr);
			else
				this.page_changed();
		}

		this.page_changed = async () => {
			console.log('Page changed');
			if (this.is_loading() === false)
			{
				this.is_loading(true);
				await this.execute_filter();
				this.is_loading(false);
			}
		}

		this.refresh_results = async () => {
			await this.delete_old_table();
			this.page_changed();
		}

		this.current_page_number.subscribe(this.page_changed);

		// FILTER
		this.all_software_units = ko.observableArray();
		this.all_status = ko.observableArray();
		this.all_departments = ko.observableArray();
		this.all_roadmaps = ko.observableArray();
		this.all_priority = ko.observableArray([
			'Low', 'Medium', 'High', 'Urgent'
		]);
		this.all_category = ko.observableArray();
		this.all_subcategory = ko.observableArray();

		this.all_impact = ko.observableArray([
			'Low', 'Medium', 'High'
		]);
		this.all_difficulty = ko.observableArray([
			'Easy', 'Medium', 'Hard'
		]);

		this.all_requestees = ko.observableArray();
		this.all_assignees = ko.observableArray();
		this.all_tags = ko.observableArray();

		this.filtered_software_units = ko.observableArray();
		this.filtered_status = ko.observableArray();
		this.filtered_priority = ko.observableArray();
		this.filtered_category = ko.observableArray();
		this.filtered_subcategory = ko.observableArray();
		this.filtered_impact = ko.observableArray();
		this.filtered_difficulty = ko.observableArray();
		this.filtered_department = ko.observableArray();
		this.filter_share_to_software_units = ko.observableArray();
		this.filtered_roadmap = ko.observableArray();
		this.filtered_requestees = ko.observableArray();
		this.filtered_assignees = ko.observableArray();
		this.filtered_tags = ko.observableArray();
		this.tag_any_all = ko.observable('any');

		// SORT
		this.sort_fields = ko.observableArray([]);

		// FORMATTING
		this.formatting_rules = ko.observableArray([]);

		// EVENTS
		this.remove_all_software_units = (e) => { this.filtered_software_units([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_status = (e) => { this.filtered_status([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_department = (e) => { this.filtered_department([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_department = (e) => { this.filter_share_to_software_units([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_roadmap = (e) => { this.filtered_roadmap([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_category = (e) => { this.filtered_category([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_subcategory = (e) => { this.filtered_subcategory([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_requestee = (e) => { this.filtered_requestees([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_assignees = (e) => { this.filtered_assignees([]); $('.selectpicker').selectpicker('refresh'); };
		this.reset_tags = (e) => { this.filtered_tags([]); $('.selectpicker').selectpicker('refresh'); };

		// when selected filter changes, load it
		this.current_filter.subscribe((nv) => {
			console.log('CURRENT FILTER CHANGED');
			this.load_filter(nv);
		});

		this.export_filter = () => {
			let filter = {
				software_unit_list: [],
				status_list: [],
				priority_list: [],
				impact_list: [],
				assignee_list: [],
				category_list: [],
				subcategory_list: [],
				difficulty_list: [],
				requestee_list: [],
				roadmap_list: [],
				department_list: [],
				filter_share_to_software_units:[],
				tag_list: [],
				tag_any_all: ''
			};

			let ar = this.filtered_software_units();
			for (let i = 0; i < ar.length; i++)
			{
				console.log(ar[i])
				if (ar[i] && ar[i].software_unit_name)
					filter.software_unit_list.push(ar[i].software_unit_name);
			}


			
			ar = this.filtered_status();
			for (let i = 0; i < ar.length; i++)
				if (ar[i])
					filter.status_list.push(ar[i].status);

			ar = this.filtered_roadmap();
			for (let i = 0; i < ar.length; i++)
				if (ar[i])
					filter.roadmap_list.push(ar[i].roadmap_id);

			ar = this.filtered_department();
			for (let i = 0; i < ar.length; i++)
				if (ar[i])
					filter.department_list.push(ar[i].name);

/*
			this.filter_share_to_software_units([]);
			fc.software_unit_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_software_units(), (i) => { return i.software_unit_name == o; });
				if (item)
					this.filter_share_to_software_units.push(item);
				else
					console.log('Cannot find software_unit', o);
			});*/

			ar = this.filtered_assignees();
			for (let i = 0; i < ar.length; i++)
			{
				// TODO use different marker, user_id ?
				filter.assignee_list.push(ar[i].username);
			}

			ar = this.filtered_requestees();
			for (let i = 0; i < ar.length; i++)
				if (ar[i])
					filter.requestee_list.push(ar[i].username);

			ar = this.filtered_category();
			for (let i = 0; i < ar.length; i++)
				if (ar[i])
					filter.category_list.push(ar[i]);

			ar = this.filtered_subcategory();
			for (let i = 0; i < ar.length; i++)
				if (ar[i])
					filter.subcategory_list.push(ar[i]);

			ar = this.filtered_tags();
			for (let i = 0; i < ar.length; i++)
				if (ar[i])
					filter.tag_list.push(ar[i]);

			filter.tag_any_all = this.tag_any_all();


			$("div.filter_priority input[type='checkbox']:checked").each((idx, el) => {
				filter.priority_list.push($(el).attr('value'));
			});
			$("div.filter_impact input[type='checkbox']:checked").each((idx, el) => {
				filter.impact_list.push($(el).attr('value'));
			});
			$("div.filter_difficulty input[type='checkbox']:checked").each((idx, el) => {
				filter.difficulty_list.push($(el).attr('value'));
			});

			return filter;
		}

		// load fields from obj into observables
		this.load_filter = (obj) => {
			if (!obj)
				return;

			this.disable_live_update();

			let fs = obj.filter_settings;
			let fc = {
				software_unit_list: [],
				status_list: [],
				assignee_list: [],
				category_list: [],
				subcategory_list: [],
				requestee_list: [],
				roadmap_list: [],
				department_list: []
			};
			_.extend(fc, obj.filter_criteria);

			this.load_filter_settings(fs);

			this.filter_name(obj.name);

			if (document && obj.name)
			{
				if (!obj.name || obj.name == '')
					document.title = 'Task Filter';
				else
					document.title = 'Filter: ' + obj.name;
			}

			this.filtered_software_units([]);
			fc.software_unit_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_software_units(), (i) => { return i.software_unit_name == o; });
				if (item)
					this.filtered_software_units.push(item);
				else
					console.log('Cannot find software_unit', o);
			});

			this.filtered_status([]);
			fc.status_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_status(), (i) => { return i.status == o; });
				if (item)
					this.filtered_status.push(item);
				else
					console.log('Cannot find status', o);
			});

			this.filtered_assignees([]);
			fc.assignee_list.forEach((o) => {
				let item;
				if (o === null)
					item = ko.utils.arrayFirst(this.all_assignees(), (i) => { return i.username == 'Unassigned Tasks'; });
				else
					item = ko.utils.arrayFirst(this.all_assignees(), (i) => { return i.username == o; });
				if (item)
					this.filtered_assignees.push(item);
				else
					console.log('Cannot find assignee', o);
			});

			this.filtered_requestees([]);
			fc.requestee_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_requestees(), (i) => { return i.username == o; });
				if (item)
					this.filtered_requestees.push(item);
				else
					console.log('Cannot find requestee', o);
			});

			this.filtered_category([]);
			fc.category_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_category(), (i) => { return i == o; });
				if (item)
					this.filtered_category.push(item);
				else
					console.log('Cannot find category', o);
			});

			this.filtered_subcategory([]);
			fc.subcategory_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_subcategory(), (i) => { return i == o; });
				if (item)
					this.filtered_subcategory.push(item);
				else
					console.log('Cannot find subcategory', o);
			});

			this.filtered_roadmap([]);
			fc.roadmap_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_roadmaps(), (i) => { return i.roadmap_id == o; });
				if (item)
					this.filtered_roadmap.push(item);
				else
					console.log('Cannot find roadmap', o);
			});


			this.filtered_department([]);
			fc.department_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_departments(), (i) => { return i.name == o; });
				if (item)
					this.filtered_department.push(item);
				else
					console.log('Cannot find department', o);
			});

			this.filtered_tags([]);
			if (!fc.tag_list)
				fc.tag_list = [];

			fc.tag_list.forEach((o) => {
				let item = ko.utils.arrayFirst(this.all_tags(), (i) => { return i == o; });

				if (item)
					this.filtered_tags.push(item);
				else
					console.log('Cannot find tag', o, 'in list', this.all_tags());
			});

			this.tag_any_all(fc.tag_any_all || 'any');

			console.log("fc");
			console.log(fc);
			if (!fc.priority_list || fc.priority_list.length == 0){
				document.querySelector('.filter_priority').querySelectorAll("input").forEach((_item)=>{

						_item.checked = true;
						return ;
				});

			}
			else
			{
				fc.priority_list.forEach((o) => {
					$("div.filter_priority input[type='checkbox'][value='" + o + "']").prop('checked', true);
				});
			}

			if (!fc.difficulty_list || fc.difficulty_list.length == 0)
				document.querySelector('.filter_difficulty').querySelectorAll("input").forEach((_item)=>{

					_item.checked = true;
					return ;
				});

			else
			{
				fc.difficulty_list.forEach((o) => {
					$("div.filter_difficulty input[type='checkbox'][value='" + o + "']").prop('checked', true);
				});
			}

			if (!fc.impact_list || fc.impact_list.length == 0)
				document.querySelector('.filter_impact').querySelectorAll("input").forEach((_item)=>{

					_item.checked = true;
					return ;
				});
			else
			{
				fc.impact_list.forEach((o) => {
					$("div.filter_impact input[type='checkbox'][value='" + o + "']").prop('checked', true);
				});
			}

			setTimeout(() => { $('.selectpicker').selectpicker('refresh'); }, 0);

			// this.enable_live_update();
		}

		this.load_filter_settings = (fs) => {
			if (!fs)
				fs = {};

			if (fs.visible_in_navbar)
				this.visible_in_navbar(fs.visible_in_navbar);
			if (fs.globally_visible)
				this.globally_visible(fs.globally_visible);
			if (fs.shared_to_software_unit)
				this.shared_to_software_unit(fs.shared_to_software_unit);
			if (fs.columns)
				this.selected_columns(fs.columns);
			else
				this.selected_columns(this.default_columns());
			if (fs.view_type)
				this.selected_view_type(fs.view_type);
			else
				this.selected_view_type('table_view');
			if (fs.sort_fields && fs.sort_fields.length > 0)
			{
				this.sort_fields(fs.sort_fields);
				let first_field = fs.sort_fields[0];
				this.page.update_table_sorting_column(first_field.field, first_field.order);
			}
			else
				this.sort_fields([]);

			if (fs.formatting_rules && fs.formatting_rules.length > 0)
				this.formatting_rules(fs.formatting_rules);
			else
				this.formatting_rules([]);
		}

		this.export_filter_settings = () => {
			let obj = {
				visible_in_navbar: this.visible_in_navbar(),
				globally_visible: this.globally_visible(),
				shared_to_software_unit: this.shared_to_software_unit(),
				shared_to_su: this.filter_share_to_software_units(),
				view_type: this.selected_view_type(),
				columns: this.selected_columns(),
				sort_fields: this.sort_fields(),
				formatting_rules: this.formatting_rules()
			};

			return obj;
		}

		this.set_tasks = (tasks) => {
			this.task_list(tasks);
			this.page.update_table_data();
		}


		this.delete_old_table = async () => {
			let dname = this.current_result_table_name();
			let dschema = this.current_result_table_schema();

			if (dname)
			{
				this.current_result_table_name(null);
				this.current_result_table_schema(null);

				await window.Grape.fetches.postJSON('/filter/delete_old_result', {
					table_name: dname
				});
			}
		}

		this.live_updates_enabled = ko.observable(false);

		this.btnUpdate_click = async () => {
			if (this.is_loading() === false)
			{
				this.is_loading(true);
				this.delete_old_table();
				await this.execute_filter();
				this.is_loading(false);
			}
		}

		this.btnStopUpdate_click = () => {
			// TODO
		}

		this.bulk_update_status = ko.observable();
		this.bulk_update_status_click = async () => {
			this.is_loading(true);
			let tasks = this.selected_items();

			for (let task of tasks)
			{
				let obj = {
					task_id: task.task_id,
					status: this.bulk_update_status().status
				};

				try
				{
					let response = await fetch('/api/task', {
						method: 'POST',
						body: JSON.stringify(obj),
						headers: { 'content-type': 'application/json' }
					});

					if (response.ok)
					{
						this.page.something_changed = true;
						this.refresh_results();
					}
				} catch (error) {
					Grape.alert({ title: 'Error', type: 'error', message: error.message });
					console.error(error);
				}
			}
			this.is_loading(false);
			this.view_bulk_edit_toggle();
		}

		this.filter_changed = async () => {
			this.filter_has_changed(true);
			if (this.live_updates_enabled() === false)
				return;

			if (this.is_loading() === false)
			{
				this.is_loading(true);
				this.delete_old_table();
				await this.execute_filter();
				this.is_loading(false);
			}
		}

		this.filtered_software_units.subscribe(this.filter_changed);
		this.filtered_status.subscribe(this.filter_changed);
		this.filtered_priority.subscribe(this.filter_changed);
		this.filtered_category.subscribe(this.filter_changed);
		this.filtered_subcategory.subscribe(this.filter_changed);
		this.filtered_impact.subscribe(this.filter_changed);
		this.filtered_difficulty.subscribe(this.filter_changed);
		this.filtered_roadmap.subscribe(this.filter_changed);
		this.filtered_department.subscribe(this.filter_changed);
		this.filtered_requestees.subscribe(this.filter_changed);
		this.filtered_assignees.subscribe(this.filter_changed);
		this.filtered_tags.subscribe(this.filter_changed);
		this.tag_any_all.subscribe(this.filter_changed);

		this.enable_live_update = () => {
			let rate_limit = 100; // in ms
			this.filtered_software_units.extend({ rateLimit: rate_limit });
			this.filtered_status.extend({ rateLimit: rate_limit });
			this.filtered_priority.extend({ rateLimit: rate_limit });
			this.filtered_category.extend({ rateLimit: rate_limit });
			this.filtered_subcategory.extend({ rateLimit: rate_limit });
			this.filtered_impact.extend({ rateLimit: rate_limit });
			this.filtered_difficulty.extend({ rateLimit: rate_limit });
			this.filtered_roadmap.extend({ rateLimit: rate_limit });
			this.filtered_department.extend({ rateLimit: rate_limit });
			this.filtered_requestees.extend({ rateLimit: rate_limit });
			this.filtered_assignees.extend({ rateLimit: rate_limit });
			this.filtered_tags.extend({ rateLimit: rate_limit });

			this.live_updates_enabled(true);
		}

		this.disable_live_update = () => { this.live_updates_enabled(false); };

		this.prompt_remove_filter = async () => {
			let answer = await Grape.confirm({
			  type: 'warning',
			  title: 'Delete Filter',
			  message: 'Are you sure you want to delete ' + this.current_filter().name + '?',
			  accept_text: 'Yes',
			  cancel_text: 'No'
			});

			if (answer)
				await this.remove_filter();
		}

		this.remove_filter = async () => {
			let obj = { filter_id: this.current_filter().filter_id };

			try
			{
				let response = await fetch('/user/filter/delete', {
					method: 'POST',
					body: JSON.stringify(obj),
					headers: { 'content-type': 'application/json' }
				});

				if (response.ok)
					this.update_filter_list();
			} catch (error) {
				Grape.alert({ title: 'Error', type: 'error', message: error.message });
				console.error(error);
			}
		}
	}

	// Gathers filter from observables and then execute it
	async execute_filter () {
		setTimeout(() => { $('.selectpicker').selectpicker('refresh'); }, 0);

		if (this.is_busy_executing() === true)
		{
			console.log('Already executing filter');
			Grape.alert({ type: 'info', message: 'Already busy executing filter', title: 'Busy' });
			return;
		}

		this.is_busy_executing(true);

		let generate_new_result = true;

		let filter_settings = this.export_filter_settings();
		let page_number = this.current_page_number();
		let offset = (page_number - 1) * this.page_size();

		let obj;

		if (this.current_result_table_name() && this.current_result_table_name() != '')
		{
			generate_new_result = false;

			obj = {
				filter_settings: filter_settings,
				offset: offset,
				limit: this.page_size(),
				table_name: this.current_result_table_name()
			};
			console.log('Fetching results from existing resultset', this.current_result_table_name());
		}
		else
		{
			generate_new_result = true;
			let filter = this.export_filter();

			localStorage.last_filter_criteria = JSON.stringify(filter);
			localStorage.last_filter_settings = JSON.stringify(filter_settings);

			obj = {
				filter_criteria: filter,
				filter_settings: filter_settings,
				offset: offset,
				limit: this.page_size()
			};
			console.log('Requesting new resultset');
		}
		this.request_count(0);

		let d = await window.Grape.fetches.postJSON('/task/filter', obj);
		if (d.status == 'ERROR')
		{
			Grape.alert({ type: 'error', message: `${d.status}: ${d.code}`, title: 'Error' });
			console.error(d);
			this.is_busy_executing(false);
			return;
		}

		if (d.table_name)
			this.current_result_table_name(d.table_name);

		if (d.table_schema)
			this.current_result_table_schema(d.table_schema);

		if (d.total_task_count)
			this.request_count(d.total_task_count);

		if (d.tasks == null)
		{
			this.set_tasks([]);

			if (d.total_task_count > 0)
			{
				this.current_page_number(1);
				await this.execute_filter();
			}
			else
			{
				this.filter_has_changed(false);
				this.is_busy_executing(false);
			}
		}
		else
		{
			d.tasks.forEach((task) => {
				// task.last_updated_str = new moment(task.last_updated).fromNow();
				task.difficulty_html = '';
				if (task.difficulty == 1)
					task.difficulty_html = '<b style="color: green;">[E]</b>';
				else if (task.difficulty == 2)
					task.difficulty_html = '<b style="color: orange;">[M]</b>';
				else if (task.difficulty == 3)
					task.difficulty_html = '<b style="color: red;">[H]</b>';
			});
			this.set_tasks(d.tasks);
			this.filter_has_changed(false);
			this.is_busy_executing(false);
		}
	}


	async update_filter_list () {
		let data = await Grape.fetches.postJSON('/user/filter/list', {});
		if (data.filters && data.filters.length > 0)
		{
			data.filters.forEach((filter) => {
				filter.label = filter.name + ' (' + filter.username + ')';
			});
			data.filters = _.chain(data.filters)
				.sortBy('username')
				.sortBy('name')
				.sortBy(f => { return f.username !== window.Grape.currentSession.user.username; })
				.value();

			this.saved_filters(data.filters);
			$('.sp-task-filters').selectpicker('refresh');
			return data.filters;
		}
		else
			return [];
	}

	view_bulk_edit_toggle ()
	{
		$('#bulk_update_popup').toggle();

		this.selected_items([]);
		$('#tbl_table').bootstrapTable('uncheckAll');
		if ($('#bulk_update_popup').is(':visible'))
		{
			$('#tbl_table').bootstrapTable('showColumn', 'state');
			this.bulk_update_visible(true);
		}
		else
		{
			$('#tbl_table').bootstrapTable('hideColumn', 'state');
			this.bulk_update_visible(false);
		}
	}

	select_filter_by_name (filter_name) {
		let filters = this.saved_filters();
		let filter = null;
		for (let i = 0; i < filters.length; i++)
		{
			if (filters[i].name == filter_name)
			{
				filter = filters[i];
				if (filter.username == Grape.currentSession.user.username)
					break;
			}
		}

		if (filter)
		{
			this.current_filter(filter);
			this.selected_filter(filter);
		}
	}

	async btnSaveFilter_click ()
	{
		let is_new = false;
		let filter_name;
		if (!this.current_filter() || this.current_filter().name == '')
		{
			filter_name = prompt('Enter a filter name: ');
			is_new = true;
		}
		else
		{
			filter_name = this.current_filter().name;
			is_new = false;
		}
		if (!filter_name || filter_name == '')
			return;


		let filter = this.export_filter();
		let filter_settings = this.export_filter_settings();
		if (filter_settings.shared_to_software_unit)
			filter_settings.shared_to_su = filter.software_unit_list;
	

		let obj = {
			criteria: filter,
			name: filter_name,
			filter_settings: filter_settings,
			globally_visible: this.globally_visible()
		};

		let d = await Grape.fetches.postJSON('/user/filter/edit', obj);

		if (d.status == 'ERROR')
		{
			Grape.alert({ type: 'error', message: `${d.status}: ${d.code}`, title: 'Error' });
			console.error(d);
			return;
		}

		if (is_new)
			this.update_filter_list();
		Grape.alert({ type: 'success', message: 'Saved successfuly', title: 'Filter Saved' });
	}

	btnExportResults_click()
	{
		if (this.current_result_table_name())
			window.open('/user_filter/export?table_name=' + this.current_result_table_name());
	}
}

export default TaskFilterPageVM;
