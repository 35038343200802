
import template from './view_test_case.html';

//Instantiate view_test_case Variables 
class ViewTestCaseViewModel {
	
	constructor(page) {

		this.page = page;
		this.html_editor_loaded = ko.observable(false);
		this.test_case_id = ko.observable();
		this.test_case_name = ko.observable();
		this.test_case_description = ko.observable();
		this.test_case_automated = ko.observable(false);
		this.test_case_type = ko.observable();
		this.test_case_types = ko.observable(['','Functional', 'Smoke', 'Regression', 'Security', 'Usability', 'Performance', 'Acceptance', 'Compatibility','Integration','Exploratory']);
		this.test_case_conditions = ko.observable();
		this.software_unit_versions =  ko.observable([]);
		this.test_case_tags = ko.observable();
		this.test_case_steps = ko.observable();
		this.test_case_run_time_estimate = ko.observable();
		this.test_case_software_unit_version_id = ko.observable();
		
	}

	
	
}
	

//Handle interaction with page 
class view_test_case {

	constructor (bindings) {

			

		this.bindings = bindings;
		this.viewModel = new ViewTestCaseViewModel(this);
		this.tbl_table = null;
		this.name = 'view_test_case';
		this.viewModel.test_case_id(this.bindings.test_case_id);


	}

	init () {
		document.title = 'View Test Case';
	}

	async updateData () {

		console.log('Update data');
		console.log(this.viewModel.test_case_id());
		if (this.viewModel.test_case_id() != null) {
		//GET_TEST_CASE

			let query_obj = {
				method : 'POST',
				body: JSON.stringify( { 'test_case_id': this.viewModel.test_case_id() }),
				headers: {'content-type':'application/JSON'}
				
			}; 

			let response = await fetch('/api/GetTestCase', query_obj);
			console.log(response);
			let _data = await response.json();

	

			let _testCaseData = JSON.stringify(_data.test_case);
			_testCaseData = JSON.parse(_testCaseData);
			if (response.ok)
			this.set_test_case(_testCaseData)

		}
	}

	set_test_case (_testCase) {

		this.viewModel.test_case_id(_testCase.test_case_id);
		this.viewModel.test_case_name(_testCase.test_case_name);
		this.viewModel.test_case_description(_testCase.test_case_description);
		this.viewModel.test_case_type(_testCase.test_case_type);
		this.viewModel.test_case_automated(_testCase.test_case_automated);
		this.viewModel.test_case_conditions(_testCase.test_case_conditions);
		this.viewModel.test_case_steps(_testCase.test_case_steps);
		this.viewModel.test_case_run_time_estimate(_testCase.test_case_run_time_estimate);
		this.viewModel.test_case_software_unit_version_id(_testCase.software_unit_version_id);
	}

	//Event (Pre/Before): Remove 
	teardown () {

		if (this.editor)
			this.editor.destroy();

	}
};


export default {
	route: '[/]QA/view_test_case/:test_case_id',
	page_class: view_test_case,
	template: template
};



