export default {
	name: 'SoftwareUnitForUsername',
	options: {
		refresh: function(cb) {
			var req = $.getJSON('/list_software_unit_current_user', {}, function(result) {
				console.log('In Cache');
				console.log(result);
				let software_units = [];

				if (!result || result.status != 'OK')
				{
					Grape.alert_api_error(result);
					cb(result);
					return;
				}

				if (!result.records)
				{
					cb(null, []);
					return;
				}
				result.records.forEach(function(software_unit) {

					software_units.push(software_unit.software_unit_name)

				});
				cb(null, software_units);
			});
		}
	}
}
