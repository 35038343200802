import template from './kanban_task.html';

class vm
{
	constructor (params) {

	
		this.params = params;
		this.board              = ko_helper.safe_observable(this.params.board);
		this.attachment_count   = ko_helper.safe_observable(this.params.attachment_count, 0);
		this.board_task_id      = ko_helper.safe_observable(this.params.board_task_id);
		this.category           = ko_helper.safe_observable(this.params.category);
		this.child_count        = ko_helper.safe_observable(this.params.child_count, 0);
		this.closed_child_count = ko_helper.safe_observable(this.params.closed_child_count, 0);
		this.department         = ko_helper.safe_observable(this.params.department);
		this.difficulty         = ko_helper.safe_observable(this.params.difficulty);
		this.impact             = ko_helper.safe_observable(this.params.impact);
		this.priority           = ko_helper.safe_observable(this.params.priority);
		this.short_description  = ko_helper.safe_observable(this.params.short_description);
		this.stage_path         = ko_helper.safe_observable(this.params.stage_path);
		this.status             = ko_helper.safe_observable(this.params.status); 
		this.tags               = ko_helper.safe_observableArray(this.params.tags, []);
		this.task_id            = ko_helper.safe_observable(this.params.task_id); 
		this.task_nr            = ko_helper.safe_observable(this.params.task_nr); 
		this.idx						= ko_helper.safe_observable(this.params.idx);
		this.assigned_to      = ko_helper.safe_observable(this.params.assigned_to);
		this.deadline 				= ko_helper.safe_observable(this.params.deadline);
		this.styles = ko.observable({});

		this.apply_formatting();
	}

	check_formatting_rule (rule) 
	{
		let value = this[rule.field.field];
		if (ko.isObservable(value)) { value = value() || ''; }

		if (rule.operator === '=') { return value == rule.value; }
		if (rule.operator === '!=') { return value != rule.value; }
		else if (rule.operator === '<') { return value < rule.value; }
		else if (rule.operator === '>') { return value > rule.value; }
		else if (rule.operator === '>=') { return value >= rule.value; }
		else if (rule.operator === '<=') { return value <= rule.value; }
		else if (rule.operator === 'ANY') { return rule.value.split(',').some((v)=>{ return value.indexOf(v) >= 0 }); }
		else { return false; }
	}

	apply_formatting_rule (rule, styles) 
	{
		if (rule.apply.value && rule.apply.value.length > 0)
		{
			styles[rule.apply.attr] = rule.apply.value;
		}
	}

	async apply_formatting ()
	{
		let styles = {};
		let rules = [];
		let board = this.board();
		let done = false;
	

		if (board === undefined){
			await this.refresh_data();
			

		}
		if (board && board.board_parameters)
		{
			let formatting_rules = board.board_parameters.formatting_rules;
			if (formatting_rules && formatting_rules.length > 0)
				rules = formatting_rules;

			for (let i = 0; i < rules.length && !done; i++) 
			{
				let rule = rules[i];
				if (this.check_formatting_rule(rule)) 
				{
					this.apply_formatting_rule(rule, styles);
					done = true;
				}
				if (styles[rule.apply.attr] === undefined)
				{
					styles[rule.apply.attr] = '';
				}
				this.styles(styles);
			}
		}
	}

	drag (data, e) 
	{
		e.originalEvent.dataTransfer.effectAllowed = 'move';
		e.originalEvent.dataTransfer.setData("text/json", JSON.stringify({
			task_id: this.task_id(),
			board_task_id: this.board_task_id(),
			stage_path: this.stage_path()
		}));

		return true;
	}

	async refresh_data ()
	{
	
		try
		{
			let res = await Grape.fetches.getJSON(`/kanban/select_task/${this.board_task_id()}`, {});
			
			if (res.status === 'OK' && res.task)
			{
				let task = res.task;

				this.attachment_count(task.attachment_count || 0);
				this.board_task_id(task.board_task_id);
				this.category(task.category);
				this.child_count(task.child_count || 0);
				this.closed_child_count(task.closed_child_count || 0);
				this.department(task.department);
				this.difficulty(task.difficulty);
				this.impact(task.impact);
				this.priority(task.priority);
				this.short_description(task.short_description);
				this.stage_path(task.stage_path);
				this.status(task.status); 
				this.idx(task.idx); 
				this.tags(task.tags || []);
				this.task_id(task.task_id); 
				this.task_nr(task.task_nr); 
				this.assigned_to(task.assigned_to);
				this.deadline(task.deadline);
			}
			else
			{
				throw new Error(`${res.status}: ${res.message}`);
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}

	async update_parent (target_stage, idx, done) 
	{
		let data = {
			board_task_id: this.board_task_id(),
			new_stage_path: target_stage,
			idx: idx
		};

		try 
		{
			let result = await fetch(`/kanban/move_task`, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: { 'content-type': 'application/json' }
			});
			
			if (result.ok)
			{
				this.stage_path = target_stage;
				done(null, result);
			}
			else
			{
				done(result.status, result);
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}

	async view_task (model, e) 
	{
		await Grape.dialog.open('ViewTask', { task: { task_id: this.task_id() }});
	}

	async remove_from_board () 
	{
		try
		{
			await fetch('/kanban/remove_from_board', { 
				method: 'POST',
				body: JSON.stringify({ board_task_id: this.board_task_id() }),
				headers: { 'content-type': 'application/json' }
			});
			location.reload();
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
		
	}

	async move_to_top () 
	{
		try
		{
			await fetch('/kanban/update_task_idx', { 
				method: 'POST',
				body: JSON.stringify({ board_task_id: this.board_task_id(), place_after_idx: 1 }),
				headers: { 'content-type': 'application/json' }
			});
			location.reload();
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
		
	}

	async move_to_bottom () 
	{
		try
		{
			await fetch('/kanban/update_task_idx', { 
				method: 'POST',
				body: JSON.stringify({ board_task_id: this.board_task_id(), place_after_idx: 999 }),
				headers: { 'content-type': 'application/json' }
			});
			location.reload();
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}
}

export default {
	name: 'kanban-task',
	viewModel: vm,
	module_type: 'ko',
	template: template
}
