import template from './task_filter.html';
import TaskFilterPageVM from './task_filter_vm.js';

// Handle interaction with page
class TaskFilterPage
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new TaskFilterPageVM(this);
		this.name = 'TaskFilterPage';
		this.current_columns = '';
	}

	async init ()
	{
		document.title = 'Task Filter';

		$('.only_show_on_hover').hide();
		$('div.hover_block').hover(
			(e) => { $('.only_show_on_hover', $(e.currentTarget)).show(); },
			(e) => { $('.only_show_on_hover', $(e.currentTarget)).hide(); }
		)

		// redirect to login page if not logged in
		if (!window.Grape.currentSession)
		{
			let current_hash = '#/tasks_filter/localStorage';

			if (bindings && bindings.filter_name)
				current_hash = '#/tasks_filter/' + bindings.filter_name;

			window.Grape.navigate('#/grape-ui/login?rr=' + current_hash);
			return;
		}

		let data = await Grape.cache.fetch('SoftwareUnitList');

		if (data && data.records)
			this.viewModel.all_software_units(data.records);

		data = await Grape.cache.fetch('TaskStatusLookup');
		if (data)
			this.viewModel.all_status(data);

		data = await Grape.cache.fetch('Roadmaps');
		if (data)
			this.viewModel.all_roadmaps(data);


		data = await Grape.cache.fetch('DepartmentList');
		if (data)
			this.viewModel.all_departments(data);

		data = await Grape.cache.fetch('DeveloperLookup');
		if (data)
		{
			let copy = data.slice(0);
			copy.unshift({ username: 'Unassigned Tasks' });
			this.viewModel.all_assignees(copy);
		}

		data = await Grape.cache.fetch('RequesteeLookup');
		if (data)
			this.viewModel.all_requestees(data);

		data = await Grape.cache.fetch('TaskTagLookup');
		if (data)
			this.viewModel.all_tags(data);

		data = await Grape.cache.fetch('TaskCategoryLookup');
		if (data)
		{
			let all_category = [];
			let all_subcategory = [];
			console.log('task category lookup', data);
			data.forEach((d) => {
				all_category.push(d.task_category);
				d.subcategories.forEach((s) => {
					if (all_subcategory.indexOf(s) == -1)
						all_subcategory.push(s);
				});
			});
			this.viewModel.all_category(all_category);
			this.viewModel.all_subcategory(all_subcategory);
		}

		await this.viewModel.update_filter_list();
		await this.loadInitialFilter();

		setTimeout(() => {
			$('.selectpicker').selectpicker('refresh');
			$('div.filter_priority,div.filter_impact,div.filter_difficulty,div.filter_department,div.filter_roadmap input[type="checkbox"]').on('change', this.viewModel.filter_changed);
		}, 0);

		//TODO
		if (this.bindings.task_id)
		{
			//open view_task
			console.log(`Opening task: ${this.bindings.task_id}`);
			window.GrapeLocals.TaskActions['view']({ 'task_id': this.bindings.task_id }, (changed) => {
				if (changed)
					this.viewModel.refresh_results();
			});
		}

		this.viewModel.selected_filter.subscribe((nv) => {
			if (!nv)
				this.viewModel.current_filter(null);
			else
				Grape.navigate('/tasks_filter/' + nv.name);
		});
	}

	create_table (columns)
	{
		if (this.tbl_table)
		{
			$('#tbl_table').bootstrapTable('destroy');
			this.tbl_table = null;
		}

		let format_date = (value, row, index) => {
			if (!value)
				return '';
			return moment(value).format('YYYY/MM/DD');
		}

		let format_datetime = (value, row, index) => {
			if (!value)
				return '';
			return '<span style="white-space: nowrap;">' + moment(value).format('YYYY/MM/DD HH:mm') + '</span>';
		}

		let format_date_closed = (value, row, index) => {
			if (row.date_closed)
				return moment(row.date_closed).format('YYYY/MM/DD');
			else if (row.date_completed)
				return moment(row.date_completed).format('YYYY/MM/DD');
			else
				return '';
		}

		let format_status = (value, row, index) => {
			let use_class = 'default';

			switch (row.status)
			{
				case 'New':
				case 'Accepted':
				case 'In Progress':
				case 'Pending Review':
					use_class = 'primary';
					break;
				case 'Need More Info':
				case 'Waiting':
				case 'On Hold':
					use_class = 'warning';
					break;
				case 'Successful Review':
				case 'Solution Ready':
				case 'Staged':
				case 'Resolved':
					use_class = 'success';
					break;
				case 'Obsolete':
				case 'Deferred':
				case 'Rejected':
				case 'Failed Review':
					use_class = 'danger';
					break;
			}

			return '<span class="label label-' + use_class + '" style="white-space: nowrap;">' + row.status + '</span>';
		}

		let format_progress = (value, row, index) => {
			if (row.child_count)
				return '<progress max="' + row.child_count + '" value="' + row.closed_child_count + '"></progress>';

			return '';
		}

		let format_difficulty = (value, row, index) => {
			if (row.difficulty >= 3)
				return 'Hard';
			else if (row.difficulty == 2)
				return 'Medium';
			else if (row.difficulty == 1)
				return 'Easy';
			else
				return 'Impossible';
		}

		let format_child_tasks = (value, row, index) => {
			let a = [];

			row.child_tasks.forEach((ct) => {
				a.push('<div>');
				a.push(format_status(value, row, index) + ' ' + ct.task_nr + ': ' + ct.short_description);
				a.push('</div>');
			});

			return a.join('');
		}

		let special_column_widths = {
			'task_nr': '50px'
		}

		let special_column_titles = {};

		let special_column_formatters = {
			'date_assigned': format_date,
			'date_created': format_date,
			'date_closed': format_date_closed,
			'last_updated': format_datetime,
			'status': format_status,
			'difficulty': format_difficulty,
			'child_progress': format_progress,
			'child_tasks': format_child_tasks
		}

		let special_column_events = {
			'description': {
				'click': (e, value, row, index) => {
					alert('Click description!');
				}
			}
		}

		let actions_formatter = (value, row, index) => {
			let ret = [
				'<div style="width: 200px;">',
				'	<a href="#/task/' + row.task_id + '" class="view" title="View">',
				'		<span class="fad fa-eye ux_context_menu_icon"></span>',
				'	</a>',
				'	<a class="edit" title="Edit" href="#/task_edit/' + row.task_id + '">',
				'		<span class="fad fa-edit ux_context_menu_icon"></span>',
				'	</a>',
				'	<a class="history" title="View Task History" href="#">',
				'		<span class="fad fa-history ux_context_menu_icon"></span>',
				'	</a>',
				'</div>'
			].join('');

			return ret;
		}

		let actions_events = {
			'click .view': (e, value, row, index) => {
				window.GrapeLocals.TaskActions['view'](row, (changed) => {
					if (changed)
						this.viewModel.refresh_results();
				});
				e.preventDefault();
				e.stopPropagation();
			},
			'click .edit': (e, value, row, index) => {
				window.GrapeLocals.TaskActions['edit'](row, (changed) => {
					if (changed)
						this.viewModel.refresh_results();
				});
				e.preventDefault();
				e.stopPropagation();
			},
			'click .hours': (e, value, row, index) => {
				window.GrapeLocals.TaskActions['hours'](row, () => {
				});
				e.preventDefault();
				e.stopPropagation();
			},
			'click .notifications': (e, value, row, index) => {
				window.GrapeLocals.TaskActions['notifications'](row, () => {
				});
				e.preventDefault();
				e.stopPropagation();
			},
			'click .messages': (e, value, row, index) => {
				window.GrapeLocals.TaskActions['messages'](row, () => {
				});
				e.preventDefault();
				e.stopPropagation();
			},
			'click .linked-tasks': (e, value, row, index) => {
				window.GrapeLocals.TaskActions['linked_tasks'](row, () => {
				});
				e.preventDefault();
				e.stopPropagation();
			},
			'click .history': (e, value, row, index) => {
				window.GrapeLocals.TaskActions['view_history'](row, () => {
				});
				e.preventDefault();
				e.stopPropagation();
			}
		}

		let table_columns = [{
			field: 'state',
			checkbox: true,
			visible: false
		}];

		columns.forEach((col) => {
			let o = {
				field: col.field,
				title: col.title || col.field,
				sortable: false
			};

			if (special_column_widths[col.field])
				o.width = special_column_widths[col.field];
			if (special_column_titles[col.field])
				o.title = special_column_titles[col.field];
			if (special_column_formatters[col.field])
				o.formatter = special_column_formatters[col.field];
			if (special_column_events[col.field])
				o.events = special_column_events[col.field];

			table_columns.push(o);
		});

		table_columns.push({
			field: 'action',
			width: '100px',
			align: 'right',
			title: 'Actions',
			formatter: actions_formatter,
			events: actions_events
		});

		// COMPONENT: Bootstrap Table
		this.tbl_table = $('#tbl_table').bootstrapTable({
			columns: table_columns,
			dataField: 'records',
			totalField: 'total',

			// TABLE PROPERTIES: Paging
			pagination: true,
			pageList: [
				20, 50, 100
			],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: (number, size) => {
				this.viewModel.change_page(number, size);
			},
			onClickRow: (row, e) => {
				if (row && row.task_id && !this.click_delay)
				{
					this.click_delay = true;

					window.GrapeLocals.TaskActions['view'](row, (changed) => {
						this.click_delay = false;
						if (changed)
							this.viewModel.refresh_results();
					});
				}
			},
			// TABLE PROPERTIES: Sort
			onSort: (sortName, sortOrder) => {
				console.log(sortName, sortOrder);
				let field = this.viewModel.get_column_def(sortName);
				field.order = sortOrder.toUpperCase();
				this.viewModel.sort_fields([field]);
				this.viewModel.page_changed();
			},
			rowStyle: (row, index) => {
				let rules = this.viewModel.formatting_rules();
				let css = { 'padding': '2px' };

				if (rules && rules.length)
				{
					for (let i = 0; i < rules.length; i++)
					{
						let rule = rules[i];

						let fieldname = rule.field['field'];
						if (!row[fieldname])
							continue;

						let match = false;
						switch (rule.operator)
						{
							case '=':
								if (row[fieldname] == rule['value'])
									match = true;
								break;
							case '!=':
								if (row[fieldname] !== rule['value'])
									match = true;
								break;
							case '>=':
								if (row[fieldname] >= rule['value'])
									match = true;
								break;
							case '>':
								if (row[fieldname] > rule['value'])
									match = true;
								break;
							case '<=':
								if (row[fieldname] <= rule['value'])
									match = true;
								break;
							case '<':
								if (row[fieldname] < rule['value'])
									match = true;
								break;
							case 'ANY':
								let ar = rule['value'].split(',');
								if (ar.indexOf(row[fieldname]) >= 0)
									match = true;
								break;
						}
						if (match)
						{
							css[rule.apply.attr] = rule.apply.value;
							return { css: css };
						}
					}
				}
				return { css: css };
			},
			// TABLE PROPERTIES: Search
			search: false,
			searchOnEnterKey: false,
			onSearch: (text) => {
				// TODO
			}
		});

		this.tbl_table.on('check.bs.table', () => {
			let items = $('#tbl_table').bootstrapTable('getSelections');
			if (this.viewModel.selected_view_type() === 'table_view')
				this.viewModel.selected_items(items);
		});

		this.tbl_table.on('check-all.bs.table', () => {
			let items = $('#tbl_table').bootstrapTable('getSelections');
			if (this.viewModel.selected_view_type() === 'table_view')
				this.viewModel.selected_items(items);
		});

		this.tbl_table.on('uncheck-all.bs.table', () => {
			if (this.viewModel.selected_view_type() === 'table_view')
				this.viewModel.selected_items([]);
		});

		this.tbl_table.on('uncheck.bs.table', () => {
			let items = $('#tbl_table').bootstrapTable('getSelections');
			if (this.viewModel.selected_view_type() === 'table_view')
				this.viewModel.selected_items(items);
		});
	}

	update_table_data ()
	{
		// check columns
		let cols = this.viewModel.selected_columns();
		if (cols.length == 0)
			cols = this.viewModel.default_columns();

		let s = JSON.stringify(cols);
		if (this.current_columns != s)
		{
			this.create_table(cols);
			this.current_columns = s;
		}

		let options = $('#tbl_table').bootstrapTable('getOptions');

		$('#tbl_table').bootstrapTable('load', {
			records: this.viewModel.task_list(),
			total: this.viewModel.request_count()
		});
	}

	update_table_sorting_column (sortName, sortOrder)
	{
		// console.log("REFRESH OPTIONS", sortName, sortOrder);
		// $("#tbl_table").bootstrapTable('refreshOptions', {sortName: sortName, sortOrder: sortOrder});
	}

	teardown ()
	{
		this.viewModel.delete_old_table();
	}

	async loadInitialFilter()
	{
		let filter_criteria = {
			software_unit_list: [],
			status_list: [],
			priority_list: [],
			impact_list: [],
			assignee_list: [],
			category_list: [],
			subcategory_list: [],
			difficulty_list: [],
			requestee_list: [],
			department_list: [],
			roadmap_list: [],
			tag_list: [],
			tag_any_all: ''
		}

		let filter_settings = {
			visible_in_navbar: false,
			globally_visible: false,
			shared_to_software_unit: false,
			view_type: 'table_view',
			columns: this.viewModel.default_columns(),
			formatting_rules: [],
			sort_fields: []
		}

		// TODO Need to formalize this
		let open_statuses = [
			'New', 'Accepted', 'In Progress', 'Failed Review', 'Pending Review'
		];



		if (this.bindings.filter_name && this.bindings.filter_name != 'localStorage')
		{
			let _su = await Grape.cache.fetch('SoftwareUnitForUsername');

			let hardcoded_filters = {
				'assigned_to_me': {
					criteria: {
						assignee_list: [Grape.currentSession.user.username],
						status_list: open_statuses
					},
					settings: {
						columns: [
							'task_nr', 'short_description', 'category', 'requested_by', 'date_assigned', 'status', 'priority'
						],
						sort_fields: [{ field: 'date_assigned', order: 'DESC', title: 'Date Assigned' }],
						name: 'Assigned to me'
					}
				},
				'requested_by_me': {
					criteria: {
						requestee_list: [Grape.currentSession.user.username],
						status_list: open_statuses
					},
					settings: {
						columns: [
							'task_nr', 'short_description', 'category', 'date_created', 'status', 'priority', 'date_assigned', 'assigned_to'
						],
						sort_fields: [{ field: 'date_created', title: 'Date Created', order: 'DESC' }]
					}
				},
				'all_open': {
					criteria: {
						status_list: open_statuses
					},
					settings: {
						columns: [
							'task_nr', 'short_description', 'category', 'requested_by', 'date_created', 'date_assigned', 'status', 'priority'
						],
						sort_fields: [{ field: 'date_created', title: 'Date Created', order: 'DESC' }]
					}
				},
				'all_open_requests': {
					criteria: {
						status_list: open_statuses,
						category_list: ['Request']
					},
					settings: {
						columns: [
							'task_nr', 'short_description', 'category', 'requested_by', 'date_created', 'date_assigned', 'status', 'priority'
						],
						sort_fields: [{ field: 'date_created', title: 'Date Created', order: 'DESC' }]
					}
				},
				'my_software_units': {
					criteria: {
						software_unit_list: _su
					},
					settings: {
						columns: [
							'task_nr', 'short_description', 'category', 'date_created', 'status', 'priority', 'date_assigned', 'assigned_to'
						],
						sort_fields: [{ field: 'date_created', title: 'Date Created', order: 'DESC' }]
					}
				},
			}

			if (hardcoded_filters[this.bindings.filter_name])
			{
				let filter = hardcoded_filters[this.bindings.filter_name];
				let columns = [];
				filter.settings.columns.forEach((c) => {
					columns.push(this.viewModel.get_column_def(c));
				});
				filter.settings.columns = columns;
				this.viewModel.load_filter({
					filter_criteria: filter.criteria,
					filter_settings: filter.settings
				});
				await this.viewModel.execute_filter();
			}
			else
			{
				this.viewModel.select_filter_by_name(this.bindings.filter_name);
				await this.viewModel.execute_filter();
			}
		}
		else if (this.bindings.software_unit)
		{
			filter_criteria.software_unit_list = [decodeURI(this.bindings.software_unit)];
			filter_criteria.status_list = open_statuses;
			this.viewModel.load_filter({ filter_criteria: filter_criteria, filter_settings: filter_settings });
			await this.viewModel.execute_filter();
		}
		else if (localStorage.last_filter_settings && localStorage.last_filter_criteria)
		{
			let filter_settings = JSON.parse(localStorage.last_filter_settings);
			let filter_criteria = JSON.parse(localStorage.last_filter_criteria);

			console.log('LOADING LAST FILTER', { filter_criteria: filter_criteria, filter_settings: filter_settings });
			this.viewModel.load_filter({ filter_criteria: filter_criteria, filter_settings: filter_settings });
			await this.viewModel.execute_filter();
		}
		else // NOT SURE WHAT TO DO HERE
		{
			//cb(null);
		}
	}
}

export default {
	route: '[/]tasks_filter/:filter_name/:task_id',
	page_class: TaskFilterPage,
	template: template
}
