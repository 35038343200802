import template from './task_developer_hours.html';

class TaskDevHoursViewModel
{
	constructor (page)
	{
		this.page = page;
		this.is_loading = ko.observable(false);

		this.task_id = ko.observable();
		this.is_billable = ko.observable(true);
		this.task_description = ko.observable();
		this.total_development_hours = ko.observable(0);
		this.development_hour_amount = ko.observable(0);
		this.development_hour_date = ko.observable(moment(new Date()).format('YYYY-MM-DD'));
		this.development_hour_description = ko.observable('');

		this.time_log_show = ko.observable(true);

		this.all_tags = ko.observableArray();
		this.current_tags = ko.observableArray([]);

		this.task_times = ko.observableArray();

		this.task_developer_hours = ko.observableArray([]);
	}

	async remove_task_time (data) 
	{
		let answer = await Grape.prompt({
			title: 'Confirm action',
			type: 'warning',
			message: 'Are you sure you would like to remove this entry?'
		});
		if (answer == true)
		{
			this.is_loading(true);

			let time_log = {
				task_time_id: data.task_time_id
			};

			try
			{
				let response = await fetch('/cabsav/delete_task_time', {
					method: 'POST',
					body: JSON.stringify(time_log),
					headers: { 'content-type': 'application/json' }
				});

				if (response.ok) 
				{
					this.page.get_task_developer_hours();
					this.is_loading(false);
					Grape.alert({ type: 'success', title: 'Removed', message: 'Task time was removed' });
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'error', message: err.message });
			}
		}
	}
}

class TaskDevHoursDialog 
{	
	constructor (bindings) {
		this.viewModel = new TaskDevHoursViewModel(this);
		this.bindings = bindings;

	}

	init () 
	{
		let _roles = Grape.cache.Grape.currentSession.user.user_roles;

		console.log(_roles);

		if( _roles.includes("dev") || _roles.includes("admin")  )
			this.viewModel.time_log_show(true);
		else
			this.viewModel.time_log_show(false);


		$('input.date').datepicker({ format: 'yyyy-mm-dd', autoclose: true });
		
		this.get_task_developer_hours(() => {
			this.get_task_data();
			
		});
		this.get_stopwatch_time();
	}

	async get_task_developer_hours () 
	{
	
		let result = await fetch('/dev/tasks/view/developer_hours', {
			method: 'POST',
			body: JSON.stringify( { task_id: this.bindings.task_id }),
			headers: { 'content-type': 'application/json' }
		});
		
		let data = await result.json();

		if (result.ok)
		{
			let task = data.task;
			let task_times = task.task_times;
			let desc = [
				task.task_prefix, task.task_id 
			].join('');

			if (task_times)
			{
				task_times.forEach((tt) => 
				{
					if (tt.is_billable === true)
						tt.billing_html = 'Yes';
					else
						tt.billing_html = 'No';

					if (tt.developer == window.Grape.currentSession.user.username)
						tt.show_remove = true;
					else
						tt.show_remove = false;
				});
				this.viewModel.task_times(task_times);
				// TODO total hours
			}
			this.viewModel.task_id(task.task_id);
			this.viewModel.task_description(desc);
		}
	}

	async edit_time_tags (model) 
	{
		let result = await Grape.dialog.open('TagsEdit', { tags: model.tags });

		if (result?.updated)
		{
			this.viewModel.is_loading(true);

			try
			{
				let res = await Grape.fetches.postJSON('/task_time/tag', { task_time_id: model.task_time_id, tags: result.tags });
				if (res.status === 'OK')
					Grape.alert({ type: 'success', title: 'Success', message: 'Tags updated' });
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'error', message: err.message });
			}

			if (result?.new_tags)
				Grape.cache.invalidate('TaskTagLookup');

			this.get_task_developer_hours();
			this.viewModel.is_loading(false);
		}
	}

	async edit_new_entry_tags () 
	{

		let result = await Grape.dialog.open('TagsEdit', { tags: this.viewModel.current_tags() });

		if (result?.updated)
			this.viewModel.current_tags(result.tags);
	}

	async get_task_data () 
	{
		try 
		{
			let result = await Grape.fetches.getJSON(`/task/${this.bindings.task_id}`, {});

			if (result.status == 'OK')
			{
				let _task = result.task;

				if (_task.tags)
				{
					if (_task.tags.indexOf('non_billable') >= 0)
						this.viewModel.is_billable(false);
					else
						this.viewModel.is_billable(true);
				}
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
		}
	}

	get_stopwatch_time () 
	{
		this.viewModel.development_hour_amount();
		if (this.bindings.stop_watch_time)
			this.viewModel.development_hour_amount(this.bindings.stop_watch_time.hours_logged_rounded);
	}

	log_development_hours () 
	{
		let selected_task_id = this.viewModel.task_id();
		let hour_log_date = this.viewModel.development_hour_date();
		let logged_date = (hour_log_date) || moment(new Date()).format('YYYY-MM-DD');
		let logged_hours = parseFloat(this.viewModel.development_hour_amount());

		this.validate_fields(logged_hours, logged_date, async (valid) => 
		{
			if (valid)
			{
				let log_entry =
				{
					task_id: selected_task_id,
					hours: logged_hours,
					date_assigned: logged_date,
					is_billable: this.viewModel.is_billable(),
					description: this.viewModel.development_hour_description(),
					tags: this.viewModel.current_tags()
				};
				
				try
				{
					let result = await fetch('/cabsav/save_task_time', {
						method: 'POST',
						body: JSON.stringify(log_entry),
						headers: { 'content-type': 'application/json' }
					});
					
					if (result.ok)
					{
						Grape.alert({ type: 'success', title: 'Development Hours', message: 'Your development hours has been logged' });
						this.get_task_developer_hours();
						this.viewModel.development_hour_amount(0);
						this.viewModel.development_hour_date(moment(new Date()).format('YYYY-MM-DD'));
						this.viewModel.current_tags([]);
					}
				} catch (err) {
					Grape.alert({ title: 'Error', type: 'error', message: err.message });
				}
			}
		});
	}

	alter_billable_hours (task_time) 
	{
		this.alter_billable_table_rows(task_time.pos, 'hide');
	}

	cancel_altering_billable_hours (task_time) 
	{
		this.alter_billable_table_rows(task_time.pos, 'show');
	}

	alter_billable_table_rows (idx, action) {
		if (action == 'hide')
		{
			$("[data-index='" + idx + "'] td .view_options").hide();
			$("[data-index='" + idx + "'] td .edit_options").show();
		}
		else
		{
			$("[data-index='" + idx + "'] td .view_options").show();
			$("[data-index='" + idx + "'] td .edit_options").hide();
		}

		$('.dev_hours_entry').each((index) => {
			if ((index + 1) != idx)
			{
				if (action == 'hide')
				{
					$(this).addClass('test_class');
					$(this).find('.view_options').hide();
					$(this).find('.edit_options').hide();
				}
				else
				{
					$(this).removeClass('test_class');
					$(this).find('.view_options').show();
					$(this).find('.edit_options').hide();
				}
			}
		});
	}

	async save_billable_hours (task_time) {
		let idx = task_time.pos;
		let hours = parseFloat(task_time.billable_hours);

		this.validate_billable_field(hours, idx, async (valid) => {
			if (valid)
			{
				let time_log = {
					task_id: task_time.task_id,
					task_time_id: task_time.task_time_id,
					billable_hours: hours
				};
				try
				{
					let response = await fetch('/update_billable_portion', {
						method: 'POST',
						body: JSON.stringify(time_log),
						headers: { 'content-type': 'application/json' }
					});

					if (response.ok)
					{
						Grape.alert({ 
							title: 'Billable Hours', 
							type: 'success', 
							message: 'The billable portion of the hours logged has been set.' 
						});
						this.alter_billable_table_rows(idx, 'show');
						this.get_task_developer_hours();
					}
				} catch (err) {
					Grape.alert({ title: 'Error', type: 'error', message: err.message });
				}
			}
		});
	}

	validate_billable_field (hours, field_index) 
	{
		$("[data-index='" + field_index + "'] td .edit_options").css('border-color', '#D9D9D9');

		let validity = true;

		if (!hours || isNaN(parseFloat(hours)) || hours < 0)
		{
			$("[data-index='" + field_index + "'] td .edit_options").css('border-color', 'red');
			validity = false;
		}

		return validity;
	}

	validate_fields (hours, log_date, childCallBack) 
	{
		$('input').css('border-color', '#D9D9D9');

		let validity = true;

		if (!hours || hours < 0.05 || isNaN(parseFloat(hours)))
		{
			$('.hour_input').css('border-color', 'red');
			validity = false;
		}
		else if (!log_date)
		{
			$('.hour_input').css('border-color', 'red');
			validity = false;
		}

		childCallBack(validity);
	}

	onClose () 
	{
		// TODO No need to return OR choose to display hours worked on task
		return null;
	}

	close_dialog () 
	{
		this.close();
	}
}

export default {
	name: 'TaskDevHours',
	page_class: TaskDevHoursDialog,
	template: template
}
