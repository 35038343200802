
export default {
	name: 'DepartmentList',
	options: {
		tablename: 'department',
		schema: 'cabsav',
		sortfield: 'name'
	}
};

