import template from './edit_test_case.html';
import ClassicEditor from '../../../lib/ckeditor/build/ckeditor.js';
import ClassicEditorConfig from '../../../lib/ClassicEditorConfig.js';

class EditTestCaseViewModel  
{
	constructor(page) {
		this.page = page;
	
		this.test_case_id = ko.observable(null);
		this.html_editor_loaded = ko.observable(false);
		this.enable_html_editor = () => 
		{
			this.page.enable_ckeditor();
		}

		this.editor = null;
		this.editor_steps = null;
		this.editor_conditions = null;
		this.test_case_name = ko.observable();
		this.test_case_description = ko.observable();
		this.test_case_automated = ko.observable(false);
		this.test_case_type = ko.observable();
		this.test_case_types = ko.observable(['','Functional', 'Smoke', 'Regression', 'Security', 'Usability', 'Performance', 'Acceptance', 'Compatibility','Integration','Exploratory']);
		this.test_case_preconditions = ko.observable();
		this.test_case_postconditions = ko.observable();
		this.software_unit_versions =  ko.observable([]);
		this.test_case_tags = ko.observable();
		this.test_case_steps = ko.observable();
		this.test_case_run_time_estimate = ko.observable();
		this.test_case_software_unit_version_id = ko.observable();
	}
}
class edit_test_case 
{
	//Instantiate edit_test_case Variables 
	constructor (bindings) {
		this.bindings = bindings;
		this.name = 'edit_test_case';
		this.viewModel = new EditTestCaseViewModel(this);

		this.viewModel.test_case_id(this.bindings.test_case_id);
	}

	//Handle interaction with page
	init() 
	{
		document.title = 'Edit Test Case';
	}

	enable_ckeditor () 
	{
		console.log($('.ckeditor'));

		if (!this.editor)
		{
			ClassicEditor.create( 
				document.querySelector('.ckeditor'),
				Object.assign({}, ClassicEditorConfig, {
					height: '550px'
				})
			).then((editor) => {
				console.table(Array.from(editor.ui.componentFactory.names()));
				this.viewModel.html_editor_loaded(true);
				this.editor = editor;

				this.editor.setData(this.viewModel.test_case_description());
			}).catch((err) => {
				console.error(err);
			});
		}

		if (!this.editor_steps){

			ClassicEditor.create(
				document.querySelector('.ckeditor_steps'),
				Object.assign({}, ClassicEditorConfig, {
					height: '550px'
				})
			).then((editor_steps) => {
				console.table(Array.from(editor_steps.ui.componentFactory.names()));
				this.viewModel.html_editor_loaded(true);
				this.editor_steps = editor_steps;

				this.editor_steps.setData(this.viewModel.test_case_steps());
			}).catch((err) => {
				console.error(err);
			});
		}
	}

	async saveTestCase() 
	{
		console.log('Save Test Case');

		if (this.viewModel.test_case_automated() == null) {
			this.viewModel.test_case_automated()
		}

		let _testCase ={
			test_case_id:  this.viewModel.test_case_id(),
			name : this.viewModel.test_case_name(),
			description : this.editor.getData(),
			type : this.viewModel.test_case_type(),
			tags : this.viewModel.test_case_tags(),
			automated : this.viewModel.test_case_automated(),
			preconditions : this.viewModel.test_case_preconditions(),
			postconditions : this.viewModel.test_case_postconditions(),
			steps :  this.editor_steps.getData(),
			run_time_estimate : this.viewModel.test_case_run_time_estimate(),
			software_unit_version_id : this.viewModel.test_case_software_unit_version_id()
		};

		let query_obj = {
			method : 'POST',
			body: JSON.stringify(_testCase),
			headers: {'content-type':'application/JSON'}
		}; 

		let response = await fetch('/api/testCaseSave', query_obj);
		console.log(response.body);

		let _data = await response.json();

		console.log(JSON.stringify(_data.task_test_case_id));
		
		if (response.ok )
		{
			if (this.viewModel.test_case_id() == null)
			{
				Grape.alert(`Test Case '${_testCase.name}' created.`);
				window.location = '#/QA/edit_test_case/'+(JSON.stringify(_data.task_test_case_id));
			}
			else
			{
				Grape.alert(`Test Case '${_testCase.name}' Edited.`);
				window.location = '#/QA/edit_test_case/' + this.viewModel.test_case_id();
			}
		}
	}

	//Event (Post/After): Refresh page data
	async updateData() 
	{
		try
		{
			let  query_obj = {
				schema: 'qa',
				table: 'v_software_unit_version_name',
				filter: []
			};
		
			let response = await Grape.fetches.getJSON('/api/record', query_obj);
			console.log(response)

			if (response.status =='OK')
			{
				console.log(response)
			
				this.viewModel.software_unit_versions(response.records);
				this.viewModel.software_unit_versions().unshift({software_unit_version_id: '', software_unit_name: '' ,  display_name: 'Not Set'}) ;
				console.log(this.viewModel.software_unit_versions());
			}
			else if (response.message == 'Table requested is null')
			{
				//this.viewModel.software_unit_versions(null);
			}
			else
				throw new Error(response.message);

			if (this.viewModel.test_case_id() !== null)
			{
				await this.getTestCase(this.viewModel.test_case_id());
			}

			this.enable_ckeditor();
		} catch(err) {
			Grape.alert({title: "Error", type: "error", message: err.message});
			console.error(err);
		}
	}

	async getTestCase (_id)
	{
		console.log(_id);
		
		let query_obj = {
			method : 'POST',
			body: JSON.stringify({test_case_id: _id}),
			headers: {'content-type':'application/JSON'}
		}; 
	
		let response = await fetch('/api/GetTestCase/',query_obj);
		console.log(response);

		let _data = await response.json();

		let _testCaseData = JSON.stringify(_data.test_case);
		_testCaseData = JSON.parse(_testCaseData);
		
		if (response.ok)
		{
			let _testCase = {
				test_case_id : _id,
				test_case_name : _testCaseData.test_case_name,
				test_case_description:  _testCaseData.test_case_description,
				test_case_type:  _testCaseData.test_case_type,
				test_case_automated:  _testCaseData.test_case_automated,
				test_case_preconditions: _testCaseData.test_case_preconditions,
				test_case_postconditions: _testCaseData.test_case_postconditions,
				test_case_steps: _testCaseData.test_case_steps,
				test_case_tags: _testCaseData.test_case_tags,
				test_case_run_time_estimate:  _testCaseData.test_case_run_time_estimate,
				software_unit_version_id: _testCaseData.software_unit_version_id
			};
			this.set_test_case(_testCase) ;
		}
	}

	set_test_case(_testCase) 
	{
		this.viewModel.test_case_id(_testCase.test_case_id);
		this.viewModel.test_case_name(_testCase.test_case_name);
		this.viewModel.test_case_description(_testCase.test_case_description);
		this.viewModel.test_case_type(_testCase.test_case_type);
		this.viewModel.test_case_tags(_testCase.test_case_tags);

		this.viewModel.test_case_automated(_testCase.test_case_automated);
		this.viewModel.test_case_preconditions(_testCase.test_case_preconditions);
		this.viewModel.test_case_postconditions(_testCase.test_case_postconditions);
		this.viewModel.test_case_steps(_testCase.test_case_steps);
		this.viewModel.test_case_run_time_estimate(_testCase.test_case_run_time_estimate);
		this.viewModel.test_case_software_unit_version_id(_testCase.software_unit_version_id);
	}

	//Event (Pre/Before): Remove 
	teardownz () 
	{
		if (this.editor)
			this.editor.destroy();
	}
}

export default {
	route: '[/]QA/edit_test_case/:test_case_id',
	page_class: edit_test_case,
	template: template
};
