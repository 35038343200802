(function () {
	window.render_search_navbar = function () {
		return `<div style="display: block; max-width: 200px;">
			<div class="input-group" style="margin-top: 10px;">
				<input id="navbar_search_txt"
					type="text"
					class="form-control input-sm"
					onkeypress="
						var e = event; if ((e.keyCode ? e.keyCode : e.which) == \'13\') $(\'#navbar_search_btn\')[0].click();
					"
					placeholder="Search"
				/>
				<span class="input-group-btn">
					<a id="navbar_search_btn"
						class="btn btn-success btn-sm"
						onclick="Finch.navigate('/'); setTimeout(function() { Finch.navigate('task_fts?search_string_id=navbar_search_txt'); }, 10);"

						>
						<span><span class="fad fa-search"></span> Search</span>
					</a>
				</span>
			</div>
		</div>`;
	};

	window.show_create_task_dialog = function () {

		Grape.dialog.open('EditTask', {})
		.then((_back)=>{
		})
		.catch((err)=>{


		});
		return false;
	};

	window.nav_render_create_task_btn = function () {
		return '<div class="input-group" style="margin-top: 10px;">' +
			'<a class="btn btn-primary btn-sm" href="#/task_create" onclick="return window.show_create_task_dialog()">' +
			'<span><span class="fad fa-plus"></span> New Task</span>' +
			'</a>' +
			'</div>';
	};

	window.show_create_request_dialog = function () {

		Grape.dialog.open('NewRequest', {})
		.then(()=>{

		})
		.catch((err)=>{

		});

		return false;
	};

	window.nav_render_create_request_btn = function () {
		return '<div class="input-group" style="margin-top: 10px;">' +
			'<a class="btn btn-primary btn-sm" onclick="return window.show_create_request_dialog()">' +
			'<span><span class="fad fa-plus"></span> New Request</span>' +
			'</a>' +
			'</div>';
	};
})();
