//let TaskStatusVM = function(params) {
class TaskStatusVM
{
	constructor (params) {
		this.params = params;
		this.classes = ko.observable('label-default');
		this.title = ko.observable('');

		if (ko.isObservable(this.params.task_status))
		{
			this.task_status = this.params.task_status;
		}
		else
		{
			this.task_status = ko.observable(this.params.task_status);
		}
		this.update_classes(this.task_status());

		if (ko.isObservable(this.params.text))
		{
			this.text = this.params.text;
		}
		else if (this.params.text)
		{
			this.text = ko.observable(this.params.text);
		}
		else
		{
			this.text = ko.observable('');
		}

		this.displayText = ko.computed(() => {
			return this.text() || this.task_status();
		});

		this.task_status.subscribe((d) => {
			this.update_classes(d);
		});
	}

	update_classes (task_status) 
	{
		let request_message = '';
		let request_type = '';

		switch (task_status) {
			case "New":
				request_message = "This request is new and is waiting to be accepted or rejected by the product owner.";
				request_type = "primary";
				break;
			case "Accepted":
				request_message = "This request has been accepted and is waiting to be assigned to a developer.";
				request_type = "primary";
				break;
			case "Rejected":
				request_message = "This request has been rejected and no developement will be scheduled.";
				request_type = "danger";
				break;
			case "Need More Info":
				request_message = "This request has been put on hold until the necessary information has been collected.";
				request_type = "warning";
				break;
			case "Waiting":
				request_message = "This request has been put on hold until the necessary information has been collected.";
				request_type = "warning";
				break;
			case "In Progress":
				request_message = "This request is currently being attended to.";
				request_type = "primary";
				break;
			case "Pending Review":
				request_message = "This request is open for review. Choose the 'Approve' or 'Reject' option once you have completed the review.";
				request_type = "primary";
				break;
			case "Successful Review":
				request_message = "This request has been reviewed successfully.";
				request_type = "success";
				break;
			case "Failed Review":
				request_message = "This request has been reviewed and the solution did not meet the criteria.";
				request_type = "danger";
				break;
			case "Solution Ready":
				request_message = "This request is currently being attended to.";
				request_type = "success";
				break;
			case "On Hold":
				request_message = "This request has been put on hold until further notice.";
				request_type = "warning";
				break;
			case "Staged":
				request_message = "This request has been deployed to a testing server and is awaiting testing.";
				request_type = "success";
				break;
			case "Resolved":
				request_message = "This request has completed and is deployed on the live server.";
				request_type = "success";
				break;
			case "Obsolete":
				request_message = "This request is marked as obsolete and no development will be scheduled.";
				request_type = "danger";
				break;
			case "Deferred":
				request_message = "This request is marked as deferred and no development will be scheduled.";
				request_type = "danger";
				break;
		}
		
		this.classes('label-' + request_type);
		this.title(request_message);
	}
}

export default {
	name: 'task-status',
	viewModel: TaskStatusVM,
	module_type: 'ko',
	template: '<span class="label" style="font-size: 0.8em;" data-bind="html: displayText, css: classes, attr: {title: title}"></span>'
}
