import template from './view_roadmap.html';

import RoadMapModel from '../../lib/RoadMapModel.js';

class ViewRoadmapViewModel
{
	constructor(page) {
		this.page = page;
		
		this.roadmap_id = ko.observable();

		this.roadmap = new RoadMapModel(page.bindings.roadmap_id);

		this.roadmap_progression = ko.observableArray([]);
	
		this.expanded_milestones = ko.observableArray([]);
	}

	async load()
	{
		await this.roadmap.load();

		if (this.roadmap.open_tasks()+this.roadmap.closed_tasks() > 0)
		{
			let rm_open_share = ((this.roadmap.open_tasks() / (this.roadmap.open_tasks() + this.roadmap.closed_tasks())) * 100).toFixed(2);
			let rm_closed_share = ((this.roadmap.closed_tasks() / (this.roadmap.open_tasks() + this.roadmap.closed_tasks())) * 100).toFixed(2);

			this.roadmap_progression({
				open_percent: (`${rm_open_share.toString()}%`),
				closed_percent: (`${rm_closed_share.toString()}%`)
			});
		}
		
		await this.load_milestones();
	}


	async load_milestones () 
	{	
		try {
			await this.roadmap.load_milestones();
		} catch (err) {
			console.error(err);
			Grape.alert({ title: 'Error',type: 'error',message: err.message });
		}
	}

	async load_milestone_tasks (milestone_id)
	{
		let milestone = this.roadmap.milestones().find((x) => x.milestone_id()===milestone_id);
		if (milestone)
			await milestone.load_tasks();
	}

	async btn_existing_milestone_click ()
	{
		let tasks = await window.Grape.dialog.open('SelectTask', {});

		if (tasks)
		{
			for (let task of tasks)
				await this.promote_existing_task(task.task_id);

			Grape.alert({ type: 'success', title: 'Success', message: 'Task(s) have been promoted to milestones!' });
		}

		this.load();
	}

	async promote_existing_task (task_id)
	{
		try 
		{
			let input = { task_id: task_id, roadmap_id: this.roadmap_id() };
			let result = await fetch('/create_milestone_from_task', 
			{
				method: 'POST',
				body: JSON.stringify(input),
				headers: { 'content-type': 'application/json' }
			});

			let data = await result.json();

			if (data.status == 'ERROR') 
				throw new Error(data.message || result.code);
		} catch (err) {
			Grape.alert({title: 'Error', type: 'error', message: err.message});
			console.error(err);
		}
	}
	
	async btn_new_milestone_click() 
	{
		let obj = {};
		let data;
		let dialog = await Grape.dialog.open('EditTask', obj);
	
		if (dialog)
		{
			try 
			{
				let input = { task_id: dialog.task_id, roadmap_id: this.roadmap_id() };
				let result = await fetch('/create_milestone_from_task', 
				{
					method: 'POST',
					body: JSON.stringify(input),
					headers: { 'content-type': 'application/json' }
				});

				data = await result.json();
	
				if (!result.ok) 
					throw new Error(data.message || result.code);
				
				await this.load();
			} catch (err) {
				Grape.alert({title: 'Error', type: 'error', message: err.message});
				console.error(err);
			}
		}	
	}

	task_click (task_id, milestone_id)
	{
		window.GrapeLocals.TaskActions['view']({ task_id: task_id }, () => {
			if (milestone_id)
				this.load_milestone_tasks(milestone_id);
		});
	}

	async new_task_click (milestone) 
	{
		await Grape.dialog.open('EditTask', {
			parent_task_id: milestone.task_id(),
			parent_task_description: milestone.short_description(),
			parent_task_nr: milestone.task_nr(),
			software_unit_name: null,
			category: null,
			department: null
		});

		this.load_milestone_tasks(milestone.milestone_id());
	}

	async add_existing_task_click (milestone) 
	{
		let tasks = await window.Grape.dialog.open('SelectTask',{});

		if (tasks)
			for (let task of tasks)
				this.add_existing_task(milestone, task);

		this.load_milestones();
	}

	async add_existing_task (milestone, task)
	{
		if (task.parent_task_id)
			Grape.alert({ type: 'warning', title: 'Invalid choice', message: 'The task is already linked to a parent task' });
		else
		{
			try
			{
				let input = { 
					parent_task_id: milestone.task_id,
					parent_task_description: milestone.short_description,
					parent_task_nr: milestone.task_nr,
					task_id: task.task_id, 
				};

				if (input.parent_task_id === input.task_id)
					throw new Error('Cannot have the task be its own parent');

				let response = await fetch('/api/task', {
					method: 'POST',
					body: JSON.stringify(input),
					headers: { 'content-type': 'application/json' }
				});

				if (response.ok)
				{		
					Grape.alert({ type: 'success', title: 'Success', message: 'Task has been linked' });
					console.log(`add_existing_task_click: ${milestone.milestone_id}`);
					this.load_milestones();
				}
			} catch(err) {
				Grape.alert({title: 'Error', type: 'error', message: err.message});
				console.error(err);
			}		
		}
	}
}

//Handle interaction with page 
class ViewRoadmapPage 
{
	constructor(bindings) {
		this.bindings = bindings;
		this.viewModel = new ViewRoadmapViewModel(this);
		this.name = 'ViewRoadmapPage';
		document.title = 'View Roadmap';
	}
	
	//Event (Post/After): Load once
	async init () 
	{
		// Need to be logged in to view this page
		if (!window.Grape.currentSession)
		{
			window.Grape.navigate('#/grape-ui/login?rr=' + encodeURIComponent('/roadmap/view_roadmap/' + this.bindings.roadmap_id));
			return;
		}

		this.viewModel.roadmap_id(this.bindings.roadmap_id);

		try {
			await this.viewModel.load();
		} catch (err) {
			Grape.alert({title: 'Error', type: 'error', message: err.message});
			console.error(err);
		}
	}

	toggle_milestone (_milestone_id) 
	{
		let ids = this.viewModel.expanded_milestones();
		if (ids.indexOf(_milestone_id) > -1)
		{
			ids.splice(ids.indexOf(_milestone_id), 1);
		}
		else
		{
			ids.push(_milestone_id);
			let milestone = this.viewModel.roadmap.milestones().find((x) => x.milestone_id()===_milestone_id);
			if (milestone)
				milestone.load_tasks();
		}
		this.viewModel.expanded_milestones(ids);
	}

	async edit_roadmap (_roadmap_id)
	{
		let roadmap_filter = { 
			roadmap_id: _roadmap_id
		}

		let d = await Grape.dialog.open('EditRoadmapDetails', roadmap_filter);
		
		if (d) 
			this.updateData();
	}

}

export default {
	route: '[/]roadmap/view_roadmap/:roadmap_id',
	page_class: ViewRoadmapPage,
	template: template
}
