
export default {
	name: 'SoftwareUnitTypes',
	options: {
		tablename: 'software_unit_type',
		schema: 'cabsav',
		sortfield: 'name'
	}
};

