import template from './dialog.html';
import c3 from 'c3';

class FilterStatsViewModel
{	
	constructor (page) {
		this.page = page;

		this.available_grouping_columns = ko.observableArray([
			{field: 'status', title: 'Status'},
			{field: 'assigned_to', title: 'User Assigned'},
			{field: 'software_unit_name', title: 'Software Unit'}
		]);

		this.selected_group_field = ko.observable();
	}
}

class FilterStatsClass
{
	constructor (bindings) {
		this.viewModel = new FilterStatsViewModel(this); // Name of the ViewModel defined above
		this.bindings = bindings;
		this.name = 'FilterStatsDialog'; // Unique dialog name
		this.table_name = null;
		this.chart = null;
	}

	init () 
	{
		this.table_name = this.bindings.table_name;

		this.load();

		this.viewModel.selected_group_field.subscribe(this.load, this);
	}

	async load () 
	{
		if (!this.viewModel.selected_group_field())
			return;

		let grouping_fieldname = this.viewModel.selected_group_field().field;

		try
		{
			let result = await Grape.fetches.getJSON('/user_filter/result_totals', {table_name: this.table_name, group_by_field: grouping_fieldname});

			if (result.status == 'ERROR')
				throw new Error(`${result.status}: ${result.code}`);

			let name_columns = ['x'];
			let value_columns = ['count: '];

			result.results.forEach((result) => {
				name_columns.push(result[grouping_fieldname]);
				value_columns.push(result['count']);
			});

			this.chart = c3.generate({
				data: {
					x: 'x',
					columns: [name_columns , value_columns],
					groups: [ ['count'] ],
					type: 'bar'
				},
				bar: {
					width: {
					    ratio: 0.8
					}
				},
				axis: {
					rotated: true,
					x: {
						type: 'category'
					}
				}
			});
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	onClose () {};

	btnclose () 
	{
		this.close(false);
	}
}

export default {
	name: 'FilterStats',
	page_class: FilterStatsClass,
	template: template
}
