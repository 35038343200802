import template from './manage_kanban.html';

class ManageKanbanViewModel
{
	constructor (page) {
		this.page = page;

		let format_levels = (levels) =>
		{
			levels = _.map(levels, (value, key) => {
				let val = value;
				if (!val.name) { val.name = key; }

				return val;
			});

			if (!levels || levels.length === 0)
				return levels;

			for (let i = 0; i < levels.length; i++) {
				let current = levels[i];
				let idx = current.idx || 0;

				current.levels = format_levels(current.levels || []);
				current.levels = _.sortBy(current.levels, 'idx');

				if (current.levels.length > 0)
					idx = Math.max(idx, current.levels[0].idx);

				current.idx = idx;
				current.cb_load_more = (...p) => { this.load_more_callback(...p) };
			}

			return _.sortBy(levels, 'idx');
		}

		let format_board_response = (board) =>
		{
			board.levels = format_levels(board.levels);
			board.load_more_callback = (...p) => this.load_more_callback(...p);
			return board;
		};

		this.board_id = ko.observable(-1);
		this.selected_board_id = ko.observable(); // from <select>
		this.available_boards = ko.observableArray();
		this.refresh_boards = async (done) =>
		{
			let data =
			{
				schema: 'cabsav',
				table: 'v_kanban_boards',
				filter: [{ field: 'active', operand: '=', value: 'true' }]
			};

			try
			{
				let result = await Grape.fetches.getJSON('/api/record',data);

				if (result.status == 'OK')
				{
					result.records = _.chain(result.records)
						.forEach(rec => {
							if (rec.created_by_username && rec.created_by_username !== '')
								{
									rec.caption = `${rec.board_title} (${rec.created_by_username})`;
								}
							else
								{
									rec.caption = `${rec.board_title}`;
								}
						})
						.sortBy('caption')
						.sortBy(rec => rec.created_by !== null)
						.value();

					this.available_boards(result.records);
				}
				else
				{
					throw new Error(`${result.status}: ${result.code}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
				console.error(err);
			}
		}

		this.active_board = ko.observable();
		this.refresh_stages = async () =>
		{
			try
			{
				let result = await Grape.fetches.getJSON(`/kanban/select_board/${this.board_id()}`,{});

				if (result.status == 'OK')
				{
					this.active_board(format_board_response(result));
					if (document)
						document.title = 'Kanban: ' + result.board.title;
				}
				else
				{
					throw new Error(`${result.status}: ${result.code}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
				console.error(err);
			}
		}

		this.available_backlog = ko.observableArray();
		this.refresh_backlog = async (limit=100) =>
		{
			try
			{
				let result = await Grape.fetches.getJSON(`/kanban/backlog_tasks/${this.board_id()}`, {limit});

				if (result.status == 'OK')
				{
					(result.tasks || []).forEach(t => t.board = this.active_board());
					this.available_backlog(result.tasks);
				}
				else
				{
					throw new Error(`${result.status}: ${result.code}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
				console.error(err);
			}
		}

		this.available_archive = ko.observableArray();
		this.refresh_archive = async (limit=100) =>
		{
			try
			{
				let result = await Grape.fetches.getJSON(`/kanban/archive_tasks/${this.board_id()}`, {limit});

				if (result.status == 'OK')
				{
					(result.tasks || []).forEach(t => t.board = this.active_board());
					this.available_archive(result.tasks);
				}
				else
				{
					throw new Error(`${result.status}: ${result.code}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
				console.error(err);
			}
		}

		this.available_tasks = ko.observableArray();
		this.refresh_tasks = async() =>
		{
			try
			{
				let result = await Grape.fetches.getJSON(`/kanban/select_tasks/${this.board_id()}`, {});

				if (result.status == 'OK')
				{
					(result.tasks || []).forEach(t => t.board = this.active_board());
					this.available_tasks(result.tasks);
				}
				else
				{
					throw new Error(`${result.status}: ${result.code}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
				console.error(err);
			}
		}

		this.selected_board_id.subscribe((new_board_id) =>
		{
			if (new_board_id)
				{ window.location = '#/kanban/manage/' + new_board_id; }
			else
				{ window.location = '#/kanban/manage'; }
		});

		this.board_id.subscribe((board_id) =>
		{
			this.refresh_board_data();
		});
	}

	async load_more_callback(full_path)
	{
		console.log('load_more_callback=' + full_path);
		if (full_path == 'BACKLOG')
		{
			await this.refresh_backlog(1000);
		}
		else if (full_path == 'ARCHIVE')
		{
			await this.refresh_archive(1000);
		}
		this.page.refresh_board_data();
	}

	async refresh_board_data ()
	{
		this.page.loading(true);

		try
		{

			await this.refresh_stages();
			await this.refresh_tasks();
			await this.refresh_backlog();
			await this.refresh_archive();

		} catch (err) {
			grape.alert({ title: 'Error', type: 'Error', message: err.message });
			console.error(err);
		}

		this.page.loading(false);
		this.page.refresh_board_data();
	}
}

class ManageKanbanPage
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new ManageKanbanViewModel(this);
		this.name = 'ManageKanbanPage';
		this.loading = ko.observable(false);
	}

	async init ()
	{
		if (!window.Grape.currentSession)
		{
			let current_hash = '#/kanban/manage';
			window.Grape.navigate('#/grape-ui/login?rr=' + current_hash);
			return;
		}

		this.loading(true);

		try
		{
			await this.viewModel.refresh_boards();
			await this.set_board_from_bindings();
		} catch (err) {
			grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
		this.loading(false);
	}

	set_board_from_bindings(done)
	{
		if (this.bindings.board_id)
		{
			this.viewModel.selected_board_id(this.bindings.board_id);
			this.viewModel.board_id(this.bindings.board_id);
		}
	}

	refresh_board_data ()
	{
		let backlog_lane = this.find_lane('BACKLOG');
		if (backlog_lane)
			backlog_lane.tasks(this.viewModel.available_backlog());

		let archive_lane = this.find_lane('ARCHIVE');
		if (archive_lane)
			archive_lane.tasks(this.viewModel.available_archive());

		let tasks = _.groupBy(this.viewModel.available_tasks(), 'stage_path');
		_.each(tasks, (value, key, list) =>
		{
			let target_lane = this.find_lane(key);
			if (target_lane)
			{
				target_lane.tasks(value);
			}
			else
			{
				Grape.alert({
					type: 'error',
					title: 'Error',
					message: 'Unable to find lane ' + key
				});
			}
		});
	}

	find_lane (full_path)
	{
		let lane_element = $(`.kanban-lane[data-path="${full_path}"]`);
		if (lane_element && lane_element.length > 0)
		{
			let vm = ko.dataFor(lane_element[0]);
			return vm;
		}
		else
		{
			return null;
		}
	}

	find_task (full_path)
	{
		let lane_element = $(`.kanban-task[data-task-id="${full_path}"]`);
		if (lane_element && lane_element.length > 0)
		{
			let vm = ko.dataFor(lane_element[0]);
			return vm;
		}
		else
		{
			return null;
		}
	}

	async add_task (model, e)
	{
		let answer = await Grape.prompt({
			title: 'Add Task',
			has_input: true,
			message: `Which task number would you like to add?`
		});

		if (answer)
		{
			try
			{
				let res = await fetch('/kanban/add_task', {
					method: 'POST',
					body: JSON.stringify({ board_id: this.viewModel.board_id(), task_nr: answer }),
					headers: { 'content-type': 'application/json' }
				});

				if (res.ok)
				{
					Grape.alert('Task added to board: ' + answer);
				}
				else
				{
					throw new Error(`${res.status}: ${res.statusText}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
			}
		}
		else if(answer === undefined)
		{
			Grape.alert({ title: 'Error', type: 'Error', message: `Input is empty` });
		}
	}

	async populate_backlog ()
	{
		this.loading(true);
		try
		{
			let result = await fetch('/kanban/board/populate', {
				method: 'POST',
				body: JSON.stringify({ board_id: this.viewModel.board_id() }),
				headers: { 'content-type': 'application/json' }
			});

			if(result.ok)
			{
				this.viewModel.refresh_board_data();
			}
			else
			{
				throw new Error(`${result.status}: ${result.statusText}`);
			}
		} catch (err) {
			Grape.alert({ type: 'Error', title: 'Error', message: err.message });
		}
	}
}

export default {
	route: '[/]kanban/manage/:board_id',
	page_class: ManageKanbanPage,
	template: template
}
