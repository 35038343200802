import template from './tags_edit.html';

class TagsEditModel {
	constructor(page) {
		this.page = page;

		this.initial_tags = [];


		this.initial_tags = ko.observableArray();
		this.current_tags = ko.observableArray();
		this.all_tags = ko.observableArray();

		this.tags_changed = ko.pureComputed(() => {
			let tags_changed = false;
			let initial_tags = this.initial_tags;

			let tags = this.current_tags();

			tags.sort();
			initial_tags.sort();

			// Determine if tag selection changed
			if (initial_tags().length !== tags.length) { tags_changed = true; }
			else
			{
				for (let index = 0; index < tags.length; index++)
				{
					if (initial_tags[index] != tags[index])
					{
						tags_changed = true;
						break;
					}
				}
			}

			return tags_changed;
		});

		this.new_tags = ko.pureComputed(() => {
			if (!this.tags_changed()) return false;

			let new_tag = false;
			let all_tags = this.all_tags;
			let tags = this.current_tags();

			for (let index = 0; index < tags.length; index++)
			{
				const tag = tags[index];
				if (all_tags.indexOf(tag) < 0)
				{
					new_tag = true;
					break;
				}
			}

			return new_tag;
		});

		this.task_update_url = '/task/tags';
	}

	async refresh_cache(force_refresh) {
		if (force_refresh)
			Grape.cache.invalidate('TaskTagLookup');

		let all_tags = await Grape.cache.get('TaskTagLookup');
		
		this.all_tags(all_tags);
		console.log("refresh_cache");
		console.log(this.current_tags());

	}
}

class tagsEditDialog {
	constructor(bindings) {
		this.bindings = bindings;
		this.viewModel = new TagsEditModel(this);
	}

	async init() {
		await this.viewModel.refresh_cache(false);
		await this.updateData();

		this.setup_select2();
	}

	async updateData() {
		console.log(this.bindings)
		
		this.viewModel.initial_tags(this.bindings.tags || []);

		this.viewModel.task_update_url = this.bindings.task_update_url;

		this.viewModel.current_tags(this.viewModel.initial_tags());

		console.log('Starting with:', this.viewModel.current_tags());
		console.log('loaded with:', this.viewModel.initial_tags());
		console.log('All tags:', this.viewModel.all_tags());

		let _tags = this.viewModel.current_tags();
		if(this.viewModel.current_tags().length >=1){
		_tags.forEach((_tag)=>{
				console.log(_tag);
				if(!this.viewModel.all_tags().includes(_tag)){
					console.log('Pushing');
					this.viewModel.all_tags.push(_tag);
				}
			});
		}
	}

	setup_select2() {
		$('select.tag-select').select2({
			tags: true,
			multiple: true,
			optional: true,
			minimumInputLength: 1,
			createTag(params) {
				if (params.term === 'null' || params.term === 'undefined' || params.term === 'NULL')
					return null;

				return {
					id: params.term,
					text: params.term
				};
			}
		});
	}

	async btnsave_click(model, e) {
		this.close({ updated: this.viewModel.tags_changed(), new_tags: this.viewModel.new_tags(), tags: this.viewModel.current_tags() });
	}

	btnclose_click() {
		this.close();
	}
}

export default {
	name: 'TagsEdit',
	page_class: tagsEditDialog,
	template: template
};
