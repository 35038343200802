
export default {
	name: 'SoftwareUnitVersionStatus',
	options: {
		tablename: 'v_lookup_value',
		schema: 'grape',
		filter: [{field: 'lookup_name', operand: '=', value: 'SoftwareUnitVersionStatus'}]
	}
};

