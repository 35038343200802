import template from './new_request.html';
import ClassicEditor from '../../lib/ckeditor/build/ckeditor.js';

class NewRequestViewModel
{
	constructor (page) {
		this.page = page;

		this.html_editor_loaded = ko.observable(false);
		this.enable_html_editor = () => 
		{
			this.html_editor_loaded(true);
			this.page.enable_ckeditor();
		}

		this.task_id = ko.observable();
		this.task_prefix = ko.observable();
		this.software_unit_item_id = ko.observable();
		this.software_unit_owner = ko.observable();
		this.initial_status = ko.observable('New');
		this.software_unit_alter_allowed = ko.observable(false);

		this.parent_task_description = ko.observable();
		this.parent_task_nr = ko.observable();
		this.parent_task_id = ko.observable(null);

		this.selected_requestee = ko.observable();
		this.allow_requestee_edit = ko.observable(false);
		
		this.short_description = ko.observable();
		this.full_description = ko.observable('');

		this.selected_department = ko.observable();

		this.selected_status = ko.observable('New');
		this.selected_software_unit = ko.observable({software_unit_name:'',clients:[],devs:[]});
		this.selected_category = ko.observable();
		this.selected_deadline = ko.observable();
		this.selected_priority = ko.observable();
		this.selected_difficulty = ko.observable();
		this.selected_impact = ko.observable();
		this.logged_in_user = ko.observable(localStorage.getItem('username'));

		this.status_list = ko.observableArray([]);
		this.software_unit_list = ko.observableArray([]);
		this.requestee_list = ko.observableArray([]);
		this.developer_list = ko.observableArray([]);
		this.department_list = ko.observableArray([]);
		this.category_list = ko.observableArray([]);
		this.priority_list = ko.observableArray(['Low', 'Medium', 'High', 'Urgent']);
		this.difficulty_list = ko.observableArray(['Easy', 'Medium', 'Hard']);
		this.impact_list = ko.observableArray(['Low', 'Medium', 'High']);
	}
}

class NewRequestDialog 
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new NewRequestViewModel(this);
		this.task_id = null;
		this.editor = null;
	
		this.original_task = null;
	}

	async init () 
	{
	

		//let _el = this.target.querySelector('.modal-loader');
		//_el.css("background-color", "yellow");
		if (this.bindings.task && this.bindings.task.task_id)
			this.task_id = this.bindings.task.task_id;
		
		if (!this.task_id && this.bindings.task_id)
			this.task_id = this.bindings.task.task_id;

		let page_width = $(".modal-content").width();
		let page_height = $(".modal-content").height();
		console.log(	$(this.target.querySelector('.modal-loader')));
		$(this.target.querySelector('.modal-loader')).show()
		$(".software_unit_warning").hide();

		let callback1 = Grape.cache.fetch('TaskStatusLookup', (data) => 
		{
			if (data)
				this.viewModel.status_list(data);	
		});

		let callback2 = Grape.cache.fetch('SoftwareUnitUsernames', (data) => {
			if (data)
				this.viewModel.software_unit_list(data);
		});

		let callback3 = Grape.cache.fetch('DepartmentList', (data) => {
			this.viewModel.department_list(data);
		});

		let callback4 = Grape.cache.fetch('RequesteeLookup', (data) => {
			if (data)
				this.viewModel.requestee_list(data);
		});

		await Promise.all([ callback1, callback2, callback3, callback4 ]);

		if (this.task_id)
		{
			this.retrieve_task_data(this.task_id, (err, task) => {
				this.original_task = task;
				$(this.target.querySelector('.modal-loader')).hide();
			});
		}
		else
		{
			if (this.bindings.parent_task_id)
			{
				this.viewModel.parent_task_id(this.bindings.parent_task_id);
				if (this.bindings.parent_task_description)
					this.viewModel.parent_task_description(this.bindings.parent_task_description)
				if (this.bindings.parent_task_nr)
					this.viewModel.parent_task_nr(this.bindings.parent_task_nr)
			}

			this.viewModel.software_unit_alter_allowed(true);
			this.viewModel.enable_html_editor();
			this.original_task = {
				requested_by: window.Grape.currentSession.user.username
			};
			this.viewModel.selected_requestee(_.find(this.viewModel.requestee_list(), (o) => { return o.username == Grape.currentSession.user.username; }));

			let default_software_unit_name = localStorage.getItem('last_software_unit_name_used');
			if (this.bindings.software_unit_name)
				default_software_unit_name = this.bindings.software_unit_name;

			if (default_software_unit_name)
			{
				this.viewModel.selected_software_unit(
					_.find(this.viewModel.software_unit_list(),
						(o) => 
						{ 
							return o.software_unit_name == default_software_unit_name; 
						}
					)
				)
			}
			
			let default_department = localStorage.getItem('last_department_used');
			if (this.bindings.department)
				default_department = this.bindings.department;

			if (default_department)
				this.viewModel.selected_department(
					_.find(this.viewModel.department_list(),
						(o) => 
						{
							return o.name == default_department; 
						}
					)
				)
				$(this.target.querySelector('.modal-loader')).hide();
		}
	}

	enable_ckeditor () 
	{
		// CKEditor initialisation


		if (!this.editor)
		{
			ClassicEditor.create(
				this.target.querySelector('.ckeditor'),
			{ 
				height: '550pz',
				toolbar: 
				[
					'heading', '|',  
					'Bold', 'Italic', 'code', 'fontColor', 'fontSize', '|', 
					'Link', 'bulletedList', 'numberedList', '|', 
					'indent', 'outdent', '|', 
					'imageUpload', 'blockQuote', 'insertTable', 'todoList', '|', 
					'undo', 'redo'
				]
			}).then((editor) => 
			{

				console.log(editor);
				console.table(Array.from(editor.ui.componentFactory.names()));
				this.viewModel.html_editor_loaded(true);
				this.editor = editor;
				this.editor.setData(this.viewModel.full_description())
			}).catch((err) => {
				console.error(err);
				console.trace();
			});
		}
	}



	difficulty_code_to_name (code) 
	{
		if (code <= 1)
			return 'Easy';
		else if (code == 2)
			return 'Medium';
		else if (code >= 3)
			return 'Hard';
	}

	difficulty_name_to_code (name) 
	{
		if (name == 'Easy')
			return 1;
		else if (name == 'Medium')
			return 2;
		else if (name == 'Hard')
			return 3;
		else
			return 0;
	}

	// FUNCTION: Retrieve task specific data from task table
	async retrieve_task_data (_task_id, childCallBack) 
	{
		let result = await Grape.fetches.getJSON(`/task/${_task_id}`, {});

		if (result.status == 'OK')
		{
			let _task_data = result.task;
			console.log(_task_data);

			let _allow_software_unit_alter = (_task_data.parent_task_id || _task_data.child_tasks) ? false : true;

			this.viewModel.task_id(_task_data.task_id);
			this.viewModel.task_prefix(_task_data.task_prefix);
			this.viewModel.software_unit_alter_allowed(_allow_software_unit_alter);
			this.viewModel.software_unit_item_id(_task_data.software_unit_item_id);
			this.viewModel.software_unit_owner(_task_data.product_owner);
			this.viewModel.short_description(_task_data.short_description);

			this.viewModel.selected_software_unit(_.find(this.viewModel.software_unit_list(), (o) => { return o.software_unit_name == _task_data.software_unit_name; }));
			this.viewModel.selected_status(_.find(this.viewModel.status_list(), (o) => { return o.status == _task_data.status; }));
			this.viewModel.selected_category(_.find(this.viewModel.category_list(), (o) => { return o.task_category == _task_data.category; }));
			this.viewModel.selected_requestee(_.find(this.viewModel.requestee_list(), (o) => { return o.username == _task_data.requested_by; }));

			this.viewModel.selected_deadline(_task_data.deadline);
			this.viewModel.selected_priority(_task_data.priority);
			this.viewModel.selected_difficulty(difficulty_code_to_name( _task_data.difficulty) );
			this.viewModel.selected_impact(_task_data.impact);
			this.viewModel.parent_task_id(_task_data.parent_task_id);
			this.viewModel.full_description(_task_data.full_description);

			childCallBack(null, _task_data);
		}
	}

	async show_task_info () 
	{
		await Grape.dialog.open('TaskInfoDialog', { task_id: this.viewModel.task_id() });
	}

	// FUNCTION: Initiate save redt
	async save_task () 
	{
		let _valid = this.validate();
		if (!_valid)
			return;

			$(this.target.querySelector('.modal-loader')).show();

		let task = this.compile_data()

		try 
		{
			let response = await fetch('/api/task', {
				method: 'POST',
				body: JSON.stringify(task),
				headers: { 'content-type': 'application/json' }
			});
			$(this.target.querySelector('.modal-loader')).hide();
			let data = await response.json();

			if (response.ok)
			{
				Grape.alert(
				{
					title: 'Request Created',
					message: `Your request has been successfuly logged under task ${JSON.stringify(data.task_nr)}`,
					type: 'success'
				});
				this.close();

			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
		}
	}

	// FUNCTION: Called when the page is closed or navigated away from.
	validate () 
	{
		let _flag = true;
		let _short_description = this.viewModel.short_description();

		if (!_short_description || _short_description == '')
		{
			_flag = false;

			Grape.alert({ title: 'Missing Data', message: 'No short description defined', type: 'error'});
		}

		return _flag;
	}

	// FUNCTION: Complile task record data from observable variables.
	compile_data () 
	{
		let task_data = {
			task_id: this.viewModel.task_id(),
			short_description: this.viewModel.short_description(),
			software_unit_id: parseInt(this.viewModel.selected_software_unit().software_unit_id),
			software_unit_ids: [this.viewModel.selected_software_unit().software_unit_id],
			software_unit_name: this.viewModel.selected_software_unit().software_unit_name,
			product_owner: this.viewModel.software_unit_owner(),
			category: 'Request',
			status: 'New',
			department_id: this.viewModel.selected_department().department_id,
			department_name: this.viewModel.selected_department().name,
			priority: this.viewModel.selected_priority(),
			impact: 'Low'
		};

		if (this.editor)
			task_data.full_description = this.editor.getData();

		return task_data;
	}

	// FUNCTION: Called when the page is closed or navigated away from.
	teardown () 
	{
		if (this.editor)
			this.editor.destroy();
	}

	close_dialog () 
	{
		parent.location.reload();
		this.close(false);
	}
}

export default {
	name: 'NewRequest',
	page_class: NewRequestDialog,
	template: template
}
