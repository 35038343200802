

/* Import CSS */
import './css/cabsav.css';
import './css/kanban.css';
import './css/btn-colors.css';
import './css/attachment-box.css';
import './css/cabsav-inline-popup.css';
import './css/duotone.min.css';
import './css/select2-bootstrap.min.css';
import './css/Andika.css';

import 'bootstrap-typeahead';
import 'jquery-mention';

import CabSavPlugin from './CabsavPlugin.js';

/* Import navbar */
import navbar from '../static/navbar.json';
import pages from './pages/index.js';
import dialogs from './dialogs/index.js';
import components from './components/index.js';
import cabsav_caches from './caches/index.js';

import './cjs/render_search_navbar.js';
import './cjs/task-actions.js';

/* Define default routes */
const default_routes = {
	guest: '/grape-ui/login',
	all: '/tasks_filter/localStorage'
};

document.addEventListener('DOMContentLoaded', () => {
	/* Setup app */
	Grape.config.debug = true;
	Grape.config.navbar = navbar;
	Grape.config.default_routes = default_routes;

	pages.forEach(function (page) {
		window.Grape.route(page.route, page);
	});

	dialogs.forEach(function (dialog) {
		window.Grape.dialogs[dialog.name] = {
			page_class: dialog.dialog_class || dialog.page_class,
			template: dialog.template
		};
	});

	cabsav_caches.forEach(function (cache) {
		Grape.cache.register(
			cache.name,
			cache.options
		);
	});

	components.forEach(function (component) {
		// Load all knockout components exported by the components index file
		if (!component.module_type || component.module_type == 'ko')
		{
			ko.components.register(component.name, {
				template: component.template,
				viewModel: component.viewModel
			});
		}
	});

	/* Plugin */
	Grape.plugins.register(CabSavPlugin);

	/* Start app */
	Grape.init();
});
