import template from './edit_kanban.html';

let format_levels = (levels) => 
{
	levels = _.map(levels, (value, key) => 
	{
		let val = value;
		if (!val.name) 
			val.name = key; 

		val.full_path = ko.observable(val.full_path);
		val.name = ko.observable(val.name);
		val.associated_cabsav_status = ko.observable(val.associated_cabsav_status);
		val.description = ko.observable(val.description);
		val.idx = ko.observable(val.idx || Infinity);

		return val;
	});

	if (!levels || levels.length === 0) 
		levels = []; 

	for (var i = 0; i < levels.length; i++)
	{
		let current = levels[i];
		let idx = current.idx() || Infinity;

		let child_levels = format_levels(current.levels || [])();
		child_levels = _.sortBy(child_levels, i => i.idx());

		if (child_levels.length > 0) 
			idx = Math.min(idx, child_levels[0].idx()); 

		current.levels = ko.observableArray(child_levels);
		current.idx(idx);
	}

	return ko.observableArray(_.sortBy(levels, i => i.idx()));
}

class KanbanBoardViewModel
{
	constructor (board_data) {
		if (!board_data) 
			board_data = {}; 
		if (!board_data.board) 
			board_data.board = {}; 
		if (!board_data.board_parameters) 
			board_data.board_parameters = {};
		if (!board_data.levels) 
			board_data.levels = {}; 

		this.board_id = ko_helper.safe_observable(board_data.board.board_id, null);
		this.title = ko_helper.safe_observable(board_data.board.title, '');
		this.filter_id = ko_helper.safe_observable(board_data.board.filter_id, null);
		this.filter_name = ko_helper.safe_observable(board_data.board.filter_name, null);
		this.software_unit_name = ko_helper.safe_observable(board_data.board.software_unit_name, null);
		this.software_unit_id = ko_helper.safe_observable(board_data.board.software_unit_id, null);
		this.created_by = ko_helper.safe_observable(board_data.board.created_by, null);
		this.active = ko_helper.safe_observable(board_data.board.active, true);

		this.public = ko_helper.safe_observable(board_data.board_parameters.public, false);
		this.formatting_rules = ko.observableArray(board_data.board_parameters.formatting_rules || []);

		this.board_parameters = ko_helper.safe_observable(board_data.board.board_parameters, {});
		this.levels = format_levels(board_data.levels);

		this.can_delete = ko.pureComputed(() => 
		{
			if (this.board_id())
				return true; // TODO add some permissions?
			else
				return false;
		});

		this.can_save = ko.pureComputed(() => 
		{
			let title = this.title();
			let filter_id = this.filter_id();

			if (!title || title.length === 0) 
				return false;
			
			if (!filter_id) 
				return false; 

			return true;
		});
	}

	get_level_leaves (search_context, path)
	{
		if (search_context === undefined) 
			search_context = this;

		let levels = [];
		let sorted_levels = _.chain(search_context.levels())
			.sortBy('name')
			.sortBy('idx')
			.value();

		for (let i = 0; i < sorted_levels.length; i++)
		{
			let current = sorted_levels[i];
			let current_path = (path ? path + '.' : '') + current.name();

			if (current.levels().length > 0)
			{
				levels = levels.concat(this.get_level_leaves(current, current_path));
			}
			else
			{
				levels.push({
					name: current.name(),
					description: current.description(),
					associated_cabsav_status: current.associated_cabsav_status() || '',
					path: current_path
				});
			}
		}

		for (let i = 0; i < levels.length; i++)
			levels[i].idx = i + 1;

		return levels;
	}
}

class EditKanbanViewModel
{
	constructor (page) {
		this.page = page;
	
		this.possible_formatting_columns = ko.observableArray([
			{
				field: 'status',
				title: 'Status'
			},
			{
				field: 'software_unit_name',
				title: 'Product'
			},
			{
				field: 'priority',
				title: 'Priority'
			},
			{
				field: 'category',
				title: 'Category'
			},
			{
				field: 'department',
				title: 'Department'
			},
			{
				field: 'impact',
				title: 'Impact'
			},
			{
				field: 'assigned_to',
				title: 'Assigned To'
			},
			{
				field: 'tags',
				title: 'Tags'
			},
			{
				field: 'difficulty',
				title: 'Difficulty'
			},
			{
				field: 'child_count',
				title: 'Child Count'
			},
			{
				field: 'attachment_count',
				title: 'Attachment Count'
			}
		]);

		this.board_id = ko.observable(-1);
		this.available_boards = ko.observableArray();
		this.refresh_boards = async () => 
		{
			try
			{
				let result = await window.Grape.fetches.getJSON('/api/record', {
					schema: 'cabsav',
					table: 'v_kanban_boards'
				});

				if(result.status == "OK")
				{	
					let current_user = Grape.currentSession.user.username;
					result.records = _.chain(result.records)
						.filter({ created_by_username: current_user })
						.forEach(rec => 
						{
							if (rec.active === false)
							{ 
								rec.caption = `${rec.board_title} (Inactive)`;
							}
							else
							{ 
								rec.caption = `${rec.board_title}`; 
							}
						})
						.sortBy(rec => rec.caption).reverse()
						.sortBy(rec => rec.caption.toLowerCase())
						.sortBy(rec => rec.active === false)
						.value();

					result.records.splice(0, -0, {
						board_id: -1,
						title: '',
						caption: 'New Board ....'
					});

					this.available_boards(result.records);
				}
				else 
				{
					throw new Error(`${result.status}: ${result.code}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
			}
		}

		this.available_software_units = ko.observableArray();
		this.refresh_software_units = () => 
		{
			Grape.cache.fetch('SoftwareUnitUsernames', (data) => 
			{
				if (data)
				{
					this.available_software_units(data);
				}
			});
		}

		this.filters_id = ko.observable(-1);
		this.available_filters = ko.observableArray();
		this.refresh_filters = async () => 
		{
			try
			{
				let result = await Grape.fetches.getJSON(`/user/filter/list_names`, {});	
				
				if (result.status == 'OK')
				{
					result.filters = _.chain(result.filters)
						.forEach(rec =>
						{
							if (!rec.is_owner && rec.username !== '')
								rec.caption = `${rec.name} (${rec.username})`; 
							else
								rec.caption = `${rec.name}`;
						})
						.sortBy('caption')
						.sortBy(rec => !rec.is_owner)
						.value();

					this.available_filters(result.filters);
				} 
				else 
				{
					throw new Error(`${result.status}: ${result.code}`);
				}
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
			}
		}

		this.active_board = ko.observable();
		this.refresh_active_board = async () => 
		{
			if (!this.board_id() || this.board_id() === -1)
			{
				this.active_board(new KanbanBoardViewModel());
			}
			else
			{
				let result = await Grape.fetches.getJSON(`/kanban/select_board/${this.board_id()}`, {});

				if (result.status == 'OK')
				{
					this.active_board(new KanbanBoardViewModel(result));
				}
				else 
				{
					Grape.alert({ title: 'Error', type: 'Error', message: `${result.status}: ${result.code}` });
				}
			}
		}

		this.refresh_board_data = async () => 
		{
			this.page.loading(true);
			try
			{
				await this.refresh_active_board();
				this.page.loading(false);
			} catch (err) {
				Grape.alert({ title: 'Error', type: 'Error', message: err.message });
			}
		}

		this.board_id.subscribe((board_id) => 
		{
			if (board_id)
			{
				this.refresh_board_data((err, res) => 
				{
					this.page.loading(false);
				});
			}
		});
	}

	async save_board () 
	{
		let board = this.active_board();
		let data = {
			board_id: this.board_id(),
			title: board.title(),
			active: board.active(),
			software_unit_id: board.software_unit_id(),
			backlog_from_user_filter_id: board.filter_id(),
			board_parameters: {
				public: board.public(),
				formatting_rules: board.formatting_rules()
			},
			levels: board.get_level_leaves(board)
		}

		if (data.software_unit_id === -1) 
			data.software_unit_id = null;

		if (data.board_id === -1) 
			data.board_id = null;

		try
		{
			let result = await fetch(`/kanban/save_board`, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: { 'content-type': 'application/json' }
			});

			let json = await result.json();
			if (result.ok)
			{
				await this.refresh_boards();
				this.board_id(json.board_id);
				this.page.loading(false);
				Grape.alert(`Kanban board saved. Board ID: ${JSON.stringify(json.board_id)}`);
			}
			else 
			{
				throw new Error(result);
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'Error', message: err.message });
			console.error(err);
		}
	}

	async delete_board () 
	{
		//let board = this.active_board();
		let data = {
			board_id: this.board_id()
		};

		try
		{
			let result = await fetch(`/kanban/delete_board`, {
				method: 'POST',
				body: JSON.stringify(data),
				headers: { 'content-type': 'application/json' }
			});
			
			if (result.ok)
			{
				this.refresh_boards();
				Grape.alert({ title: 'Info', type: 'info', message: `Kanban board ID ${JSON.stringify(data.board_id)} has been deleted.` });
			}
			else 
			{
				throw new Error(`${result.status}: ${result.statusText}`);
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'Error', message: err.message });
			console.error(err);
		}
	}

	move_up_level (model, e) 
	{
		let parent_levels = ko.contextFor(e.target).$parent.levels;
		let index = ko.contextFor(e.target).$index();

		if (!parent_levels._latestValue)
		{
			parent_levels._latestValue = parent_levels();
		}
		parent_levels.moveDown(index);
	}

	move_down_level (model, e) 
	{
		let parent_levels = ko.contextFor(e.target).$parent.levels;
		let index = ko.contextFor(e.target).$index();

		if (!parent_levels._latestValue)
			parent_levels._latestValue = parent_levels();
		
		parent_levels.moveUp(index);
	}

	async add_level (model, e) 
	{
		let parent_levels = model.levels;
		let response = await Grape.dialog.open('EditKanbanLevel', {});

		if (response)
		{
			parent_levels.push({
				name: ko.observable(response.name),
				full_path: ko.observable(response.full_path),
				description: ko.observable(response.description),
				associated_cabsav_status: ko.observable(response.associated_cabsav_status),
				levels: ko.observableArray([])
			});
		}
	}

	async edit_level (model, e) 
	{
		let parent_levels = ko.contextFor(e.target).$parent.levels;

		let response = await Grape.dialog.open('EditKanbanLevel',
		{
			name: model.name(),
			full_path: model.full_path(),
			description: model.description(),
			associated_cabsav_status: model.associated_cabsav_status()
		});

		if (response)
		{
			model.name(response.name);
			model.associated_cabsav_status(response.associated_cabsav_status);
			model.full_path(response.full_path);
			model.description(response.description);

			let levels = parent_levels();
			parent_levels([]);
			parent_levels(levels);
		}
	}

	async delete_level (model, e) 
	{
		let answer = Grape.prompt({
			title: 'Confirm',
			accept_text: 'Yes',
			cancel_text: 'No',
			message: `Are you sure you want to delete stage: [${model.name()}]`
		});

		if (answer)
		{
			let parent_levels = ko.contextFor(e.target).$parent.levels;
			parent_levels.remove(model);
		}
	}
}

class EditKanbanPage
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new EditKanbanViewModel(this);

		this.name = 'EditKanbanPage';
		this.loading = ko.observable(false);
	}

	async init () 
	{
		this.loading(true);
		
		try
		{	
			await this.viewModel.refresh_software_units();
			await this.viewModel.refresh_filters();
			await this.viewModel.refresh_boards();
		} catch (err) {
			grape.alert({ title: 'Error', type: 'Error', message: err.message });
		}
		this.loading(false);
	}

	async save_board (model, e) 
	{
		this.loading(true);

		this.viewModel.save_board((err, res) => 
		{
			if (err) 
			{ 
				Grape.alert_api_error(err); 
			}
		});
	}

	async delete_board (model, e) 
	{
		let answer = await Grape.prompt({ 
			title: 'Delete board confirmation',
			message: 'Are you sure you want to delete this board?',
			accept_text: 'Yes',
			cancel_text: 'No' 
		});
		
		if (answer)
		{
			this.loading(true);
			this.viewModel.delete_board((err, res) => 
			{
				this.loading(false);
			});
		}
	}
}

export default {
	route: '[/]kanban/edit',
	page_class: EditKanbanPage,
	template: template
}
