
import template from './list_test_case.html';
import ClassicEditor from '../../../lib/ckeditor/build/ckeditor.js';


//Instantiate edit_test_case Variables
class ListTestCaseViewModel  {

	constructor(page) {

		this.page = page;

	}

}
class list_test_case{

	constructor (bindings) {

		this.bindings = bindings;
		this.viewModel = new ListTestCaseViewModel(this);
		this.tbl_table = null;
		this.name = 'edit_test_case';

	}

 	init () {

		document.title = 'Edit Test Case';

		let actions_formatter = function (value, row, index) {

			let ret = [
				'<div style="width: 200px;">',
				'	<a class="view" title="View">',
				'		<span class="fad fa-eye ux_context_menu_icon"></span>',
				'	</a>',
				'	<a class="edit" title="Edit">',
				'		<span class="fad fa-edit ux_context_menu_icon"></span>',
				'	</a>',
				'</div>'
			].join('');

			return ret;
		};

		let actions_events = {

			'click .view': function (e, value, row, index) {
				console.log('View');
				window.Grape.navigate('/QA/view_test_case/' + row.test_case_id);
				e.preventDefault();
				e.stopPropagation();
			},
			'click .edit': function (e, value, row, index) {

				console.log('Edit');
				window.Grape.navigate('/QA/edit_test_case/' + row.test_case_id);

				e.preventDefault();
				e.stopPropagation();
			}


		};


		$("#tbl_test_case").bootstrapTable( {

			columns: [
                {
					field: 'software_unit_version_name',
					title: 'Software Unit',
					width: '50px',
					sortable: true
				},
				{
					field: 'test_case_name',
					title: 'Name',
					width: '50px',
					sortable: true

				},
                {
					field: 'test_case_type',
					title: 'Type',
					width: '50px',
					sortable: true

				},
                {
					field: 'test_case_tags',
					title: 'tags',
					width: '50px',
					sortable: true

				},
				{
					field: 'action',
					width: '100px',
					align: 'right',
					title: 'Actions',
					formatter: actions_formatter,
					events: actions_events

				}



			],
			rowStyle: function(value, row, index) {

				return { css: {"cursor": "pointer"} };

			},
			onClickRow: function(row, $element, field) {

			},
			pagination: true,
			pageList: [10, 25, 50, 100],
			pageSize: 10,
			sortName: 'test_case_name',
			sortOrder: 'desc',
			sidePagination: 'client',
			data: []
		});
	}

	async updateData () {

		try
		{
			let query_obj = {
				schema: 'qa',
				table: 'v_test_case_software_unit',
				limit: 1000,
				filter: []
			};

			let response = await Grape.fetches.getJSON('/api/record', query_obj);
			console.log(response)



			if (response.status === 'OK')
				$("#tbl_test_case").bootstrapTable('load', response.records);

			else
				throw new Error('Error!');


		} catch(err) {
			console.log(err);
			Grape.alert({title: "Error", type: "error", message: err.message});
		}
	}

	CreateTestCase(){

		window.Grape.navigate('QA/edit_test_case/');

	}

	//Event (Pre/Before): Remove
	teardown () {

		if (this.editor)
			this.editor.destroy();

	}
}


export default {
	route: '[/]QA/list_test_case',
	page_class: list_test_case,
	template: template
};
