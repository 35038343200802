import template from './page_loader.html';

class PageLoaderVM
{
	constructor (params) {
		let page_width = $(window).width();
		let page_height = $(window).height();

		$('.loading').width(page_width);
		$('.loading').height(page_height);
		console.log('=========================');
		console.log($('.loading'));
		console.log('=========================');

		this.loading = ko_helper.safe_observable(params.loading);
	}
}

export default {
	name: 'page-loader',
	viewModel: PageLoaderVM,
	module_type: 'ko',
	template: template
}
