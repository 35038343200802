import template from './software_units.html';

//Instantiate Page Variables 
class SoftwareUnitAttributesViewModel
{
	constructor (page) {
		this.page = page;
	
		this.notification_types = ko.observableArray([]);
		this.software_unit_types = ko.observableArray(['services', 'products', 'modules', 'special']);
		this.software_units = {};
		this.software_unit_types().forEach((sut) => {
			this.software_units[sut] = ko.observableArray([]);
		});
	}

	async save_attribute (attribute, e) 
	{
		let software_unit_name = $(e.currentTarget).attr('data-software_unit_name');
		let obj = {
			software_unit_name: software_unit_name,
			attribute_name: attribute.name,
			value: attribute.value
		};

		try
		{
			await fetch('/software_unit_attribute',
			{
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});
		} catch (err) {
			Grape.alert({ type: 'error', title: 'error', message: err.message });
			console.error(err);
		}
	}

	async click_su_view (su) 
	{
		console.log(su);
		let d = await Grape.dialog.open('SoftwareUnitDialog', su);

		if (d)
			this.page.updateData();
	}

	async click_su_req_access (row) 
	{
		try 
		{
			let response = await fetch('/software_unit/user/request_access', 
			{
				method: 'POST',
				body: JSON.stringify({ software_unit_id: row.software_unit_id }),
				headers: { 'content-type': 'application/json' }
			});

			if (response.ok)
			{
				Grape.alert({ type: 'success', message: 'Access request has been sent', title: 'Success' });
				this.page.updateData();
			}
		} catch (err) {
			Grape.alert({ type: 'error', message: err.message, title: 'Error' });
			console.error(err);
		}
	}
	
	async click_su_remove_access (row) 
	{
		try 
		{
			let response = await window.Grape.fetches.getJSON(`/unlink_user_from_software_unit/${row.software_unit_name}/${window.Grape.currentSession.user.username}`, {});

			if (response.status == 'OK')
			{
				Grape.alert({ type: 'success', message: 'Access has been removed', title: 'Success' });
				this.page.updateData();
			}
			else
			{
				throw new Error(response);
			}
		} catch(err) {
			Grape.alert({ type: 'error', message: err.message, title: 'Error' });
		}	
	}
}

//Handle interaction with page 
class SoftwareUnitAttributesPage 
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new SoftwareUnitAttributesViewModel(this);
		this.name = 'SoftwareUnitAttributes';
	}

	init ()
	{
		document.title = 'Edit Software Units';

		if (!window.Grape.currentSession)
		{
			window.Grape.navigate('#/grape-ui/login');
			return;
		}

		$('#div_software_units a[data-toggle="tab"]').on('shown.bs.tab', (e) => {
			let new_tab = e.target; // newly activated tab
			let new_tab_name = new_tab.dataset.tabname;
			window.localStorage.setItem('software_units.tab', new_tab_name);
		});
	}

	async updateData () 
	{
		let p1 = this.fetch_list();
		let p2 = this.fetch_notification_types();
		
		await Promise.all([ p1,p2 ])

		let last_tab = window.localStorage.getItem('software_units.tab');
		if (last_tab && last_tab != '')
			$('a[data-tabname="' + last_tab + '"]').tab('show');
	}
		
	async fetch_list ()
	{
		let result = await window.Grape.fetches.getJSON('/api/record', { schema: 'cabsav', table: 'v_user_software_units', limit: 200 });
				
		if (result.records == null)
			result.records = [];
			
		let services = [];
		let products = [];
		let modules = [];
		let specials = [];

		result.records.forEach((r) => {
			if (r.username == null)
			{
				r.has_access = false;
				if (r.date_requested)
					r.access_requested = true;
				else
					r.access_requested = false;
			}
			else 
			{
				r.has_access = true;
				r.access_requested = false;
			}

			r.beta_versions = r.beta_versions || [];
			r.active_versions = r.active_versions || [];
			r.deprecated_versions = r.deprecated_versions || [];

			if (r.software_unit_type == 'Service')
				services.push(r);
			else if (r.software_unit_type == 'Module')
				modules.push(r);
			else if (r.software_unit_type == 'Product')
				products.push(r);
			else
				specials.push(r);
		});

		this.viewModel.software_units['services'](services);
		this.viewModel.software_units['products'](products);
		this.viewModel.software_units['modules'](modules);
		this.viewModel.software_units['special'](specials);
	}
		
	async fetch_notification_types () 
	{
		let data = await Grape.cache.fetch('NotificationTypeLookup');

		if (data)
			this.viewModel.notification_types(data);
	}

	teardown () {};

	async addSoftwareUnit ()
	{
		let d = await Grape.dialog.open('AddSoftwareUnit', {});
		
		if (d)
			this.updateData();
	}
}

export default {
	route: '[/]software_units',
	page_class: SoftwareUnitAttributesPage,
	template: template
}
