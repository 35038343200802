
class MilestoneModel
{
	constructor(milestone)
	{
		this.milestone_id = ko.observable(milestone.milestone_id);
		this.status = ko.observable(milestone.status);
		this.task_id = ko.observable(milestone.task_id);
		this.task_nr = ko.observable(milestone.task_nr);
		this.deadline = ko.observable(milestone.deadline);
		this.total_tasks = ko.observable(milestone.total_tasks);
		this.closed_tasks = ko.observable(milestone.closed_tasks);
		this.short_description = ko.observable(milestone.short_description);
		this.progress = ko.observable(milestone.progress || '0');

		this.tasks = ko.observableArray([]);
	}

	async load_tasks()
	{
		let data = await Grape.fetches.getJSON('/api/record', {
			table: 'v_milestone_tasks',
			schema: 'cabsav',
			filter: [{ value: this.milestone_id(), operand: '=', field: 'milestone_id' }],
			fields: ['task_nr', 'short_description', 'status', 'task_id']
		});

		if (data.status !== 'OK')
			throw new Error(data);
		
		this.tasks(data.records || []);
	}

}

class RoadMapModel
{
	constructor(roadmap_id)
	{
		this.roadmap_id = ko.observable(roadmap_id);
		this.roadmap_name = ko.observable();
		this.description = ko.observable();
		this.status = ko.observable();
		this.status_note = ko.observable();
		this.start_date = ko.observable(moment(new Date()).format('YYYY-MM-DD'));
		this.completion_date = ko.observable(moment(new Date()).format('YYYY-MM-DD'));
		this.software_unit_id = ko.observable();
		this.priority = ko.observable();
		this.internal = ko.observable();
		this.active = ko.observable();
		
		this.open_tasks = ko.observable(0);
		this.closed_tasks = ko.observable(0);

		this.milestones = ko.observableArray([]);
	}

	async load()
	{

		let roadmap_filter = [{ value: parseInt(this.roadmap_id()), op: '=', field: 'roadmap_id' }];
		let response = await Grape.fetches.getJSON('/api/record', { table: 'v_roadmaps', schema: 'cabsav', filter: roadmap_filter, limit: 1 });

		if (response.status == 'OK')
		{
			let roadmap_data = response.records[0];

			if (roadmap_data.start_date)
				this.start_date(roadmap_data.start_date);
			else
				this.start_date('');

			if (roadmap_data.estimated_completion_date)
				this.completion_date(roadmap_data.estimated_completion_date);
			else
				this.completion_date(null);

			this.roadmap_name(roadmap_data.roadmap_name);
			this.description(roadmap_data.description);
			this.status(roadmap_data.status);
			this.status_note(roadmap_data.status_note);
			this.priority(roadmap_data.priority);
			this.active(roadmap_data.active);
			this.internal(roadmap_data.internal);
			
			this.open_tasks(parseInt(roadmap_data.open_tasks));
			this.closed_tasks(parseInt(roadmap_data.closed_tasks));
		}
	}

	async load_milestones () 
	{
		let milestones = [];

		const params = {
			table: 'v_milestones',
			schema: 'cabsav',
			filter: [{
				value: this.roadmap_id(),
				operand: '=',
				field: 'roadmap_id'
			}]
		};

		let data = await Grape.fetches.getJSON('/api/record', params);

		if (data.status !== 'OK')
			throw new Error(`${data.status}: ${data.code}`);
		
		for (let r of data.records)
		{
			if (r.total_tasks > 0)
				r.progress = (r.closed_tasks / r.total_tasks * 100).toFixed(2);
			else
				r.progress = 0;

			const model = new MilestoneModel(r);
			milestones.push(model);
		}
		this.milestones(milestones);
	}



}

export default RoadMapModel;

