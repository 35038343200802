export default {
	name: 'SoftwareUnitUsernames',
	options: {
		refresh: function(cb) {
			var req = $.getJSON('/list_software_unit_usernames', {}, function(result) {
				if (!result || result.status != 'OK')
				{
					Grape.alert_api_error(result);
					cb(result);
					return;
				}

				if (!result.software_units)
				{
					cb(null, []);
					return;
				}

				result.software_units.forEach(function(software_unit) {
					if (software_unit.devs)
						software_unit.devs.sort();
					else
						software_unit.devs = [];

					if (software_unit.clients)
						software_unit.clients.sort();
					else
						software_unit.clients = [];
				});

				cb(null, result.software_units);
			});
		}
	}
}
