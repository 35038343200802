import template from './edit_roadmap.html';

import RoadMapModel from '../../lib/RoadMapModel.js';

class EditRoadmapViewModel
{
	constructor (page) {
		this.page = page;

		this.roadmap = new RoadMapModel();

		this.available_statuses = ko.observableArray(['Current', 'New', 'Potential', 'Completed', 'Ongoing', 'Non-Project']);
	}

	async load(roadmap_id)
	{
		this.roadmap.roadmap_id(roadmap_id);
		try {
			await this.roadmap.load();
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}

	}

	get_data()
	{
		let roadmap_data = {
			start_date : this.roadmap.start_date(),
			completion_date : this.roadmap.completion_date(),
			roadmap_name : this.roadmap.roadmap_name(),
			description: this.roadmap.description(),
			status: this.roadmap.status(),
			status_note: this.roadmap.status_note(),
			active: this.roadmap.active(),
			internal: this.roadmap.internal(),
			priority: this.roadmap.priority(),
			roadmap_id : this.roadmap.roadmap_id()
		};

		if (roadmap_data.completion_date == '')
			roadmap_data.completion_date = null;
		
		if (roadmap_data.start_date == '')
			roadmap_data.start_date = null;

		return roadmap_data;
	}
}

class EditRoadmapDialog
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new EditRoadmapViewModel(this);
	}

	async init () 
	{
		this.viewModel.load(this.bindings.roadmap_id);
	}
	
	updateData () {}

	async save_roadmap_details () 
	{
		let roadmap_data = this.viewModel.get_data();
		try
		{
			let response = await fetch('/api/tasks/roadmap', {
				method: 'POST',
				body: JSON.stringify(roadmap_data),
				headers: { 'content-type': 'application/json' }
			});

			if (response.ok)
				Grape.alert({type: 'success', title: 'Data Saved', message: 'Roadmap details has been updated'});
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}

		this.close(true);
	}

	btn_close_clicked () 
	{
		this.close();
	}
}

export default {
	name: 'EditRoadmapDetails',
	page_class: EditRoadmapDialog,
	template: template
}
