
import SUDialog from './software_unit.js';
import TaskSelect from './select_task.js';
import TagsEdit from './tags_edit/tags_edit.js';
import ViewTask from './view_task_dialog/view_task.js';
import EditTask from './edit_task_dialog/edit_task.js';
import KanbanAddTask from './kanban/add_task.js';
import KanbanEditLevel from './kanban/edit_kanban_level.js';
import NewClientRequest from './client_request/new_request.js';
import TaskTime from './task_time/task_developer_hours.js';
import FilterStats from './filter_stats/dialog.js';
import NewRoadmap from './roadmaps/new_roadmap.js';
import EditRoadmap from './roadmaps/edit_roadmap.js';
import AddSU from './add_software_unit/add_software_unit.js';
import TaskHistory from './task_history.js';
import EditRecurringSeries from './recurring-series/edit.js';
import NewDialog from './dialog.js';

export default [
	SUDialog,
	TaskSelect,
	TagsEdit,
	ViewTask,
	EditTask,
	KanbanAddTask,
	KanbanEditLevel,
	NewClientRequest,
	TaskTime,
	FilterStats,
	NewRoadmap,
	EditRoadmap,
	AddSU,
	TaskHistory,
	EditRecurringSeries,
	NewDialog
];
