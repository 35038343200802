import template from './roadmaps.html';

class RoadmapViewModel 
{
	constructor(page) 
	{
		this.page = page;
		
		this.roadmaps = {
			'Current': ko.observableArray(),
			'New': ko.observableArray(),
			'Potential': ko.observableArray(),
			'Completed': ko.observableArray(),
			'Ongoing': ko.observableArray(),
			'Non-Project': ko.observableArray()
		};

		this.software_unit_list = ko.observableArray([]);
		this.full_roadmap = ko.observableArray([]);
		this.roadmap = ko.observableArray();
		this.initial_load = ko.observable(true);
		this.loading_bind = ko.observable(true);
	}
}

//Handle Interaction with Page 
class RoadmapPage
{
	constructor(page) 
	{
		this.page = page;
		this.viewModel = new RoadmapViewModel(this);
		this.name = 'RoadmapPage';
		document.title = 'Projects';
	}
	
	async init () 
	{
		// Need to be logged in to view this page
		if (!window.Grape.currentSession)
		{
			window.Grape.navigate(`#/grape-ui/login?rr=${encodeURIComponent('/roadmaps')}`);
			return;
		}
		
		// Data loader initialisation and display while data is retrieved.
		$('.loading').width(window.innerWidth);
		$('.loading').height(window.innerHeight);
		this.viewModel.loading_bind(true);
	}

	async updateData () 
	{
		//loader
		this.viewModel.initial_load(true);

		$('.loading').width(window.innerWidth);
		$('.loading').height(window.innerHeight);

		let options = {
			table: 'v_roadmaps',
			schema: 'cabsav',
			offset: 0,
			limit: 1000,
			sortfield: 'roadmap_id',
			sortorder: 'ASC'
		};

		try 
		{
			let result = await Grape.fetches.getJSON('/api/record', options);
			
			result.records = result.records || [];

			const roadmaps = {};
			for (let record of result.records)
			{
				if (!roadmaps.hasOwnProperty(record.status))
					roadmaps[record.status] = [];
				roadmaps[record.status].push(record);
			}

			for (let [k,v] of Object.entries(roadmaps))
				this.viewModel.roadmaps[k](v);

			this.viewModel.loading_bind(false);
		} catch(err) {
			Grape.alert({title: "Error", type: "error", message: err.message});
			console.error(err);
		} finally {
			this.viewModel.initial_load(false);	
		}
	}

	async new_roadmap () 
	{
		let d = await Grape.dialog.open('NewRoadmap', {}); 
		this.updateData();
	}

	view_roadmap (_roadmap_id) 
	{
		let url = `/roadmap/view_roadmap/${_roadmap_id}`;
		Finch.navigate(url);
	}
}

export default {
	route: '[/]roadmaps',
	page_class: RoadmapPage,
	template: template
};
