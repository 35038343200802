import template from './list_notifications.html';

class NotificationPageViewModel
{
	constructor (page) {
		this.page = page;

		this.notification_types = ko.observableArray();
		this.notification_types_values = ko.observableArray();
		this.selected_notification_types = ko.observableArray();
		this.show_read_notifications = ko.observable(false);
		this.limit = ko.observable(50);

		// Load previous filter values
		if (localStorage.getItem('cabsav.notifications.filter.show_read_notifications') === 'true')
			this.show_read_notifications(true);
		//if (localStorage.getItem('cabsav.notifications.filter.notification_types') != null)
		//	this.selected_notification_types(JSON.parse(localStorage.getItem('cabsav.notifications.filter.notification_types')));

		this.show_read_notifications.subscribe(() => {
			this.page.store_last_filter_values();
			this.page.refresh_data();
		});

		this.selected_notification_types.subscribe((value) => {
			console.log('selected_notification_types');
			console.log(value);
			this.page.store_last_filter_values();
			this.page.refresh_data();
		});
		
		this.unread_notifications = ko.observableArray();
	}

	async refresh_notifications () 
	{

		
		let query_obj = {
			schema: 'cabsav',
			table: 'v_notifications',
			limit: 50,
			filter: []
		};
		
		if (!this.show_read_notifications())
			query_obj.filter.push({field: 'date_seen', operand: 'IS_NULL', value: 'NULL'});

		if (this.selected_notification_types())
		{
		
			if (this.selected_notification_types().length > 0)
			{
				let list = [];

				console.log(this.selected_notification_types());
				if( Array.isArray(this.selected_notification_types())){
					this.selected_notification_types().forEach((s) => {
						list.push(s.notification_type);
					});
				}
				else{

					list.push(this.selected_notification_types());
					console.log('Convert to List');
					this.selected_notification_types(list);
				}


				query_obj.filter.push({field: 'notification_type', operand: 'IN', value: this.selected_notification_types()});
			}
		}
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', query_obj);
			
			if (result.status == 'OK'){
				this.unread_notifications(result.records || []);

			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}
	
	async getNotificationType(){
		
		let query_obj = {
			schema: 'public',
			table: 'notification_type_lookup',
			limit: 50,
			filter: []
		};

		try
		{
			let result = await Grape.fetches.getJSON('/api/record', query_obj);
			
			if (result.status == 'OK'){
				this.notification_types(result.records );


			}

		}

		catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}

		

	}

	async update_lookups () 
	{
		let data = await Grape.cache.fetch('NotificationTypeLookup');
		if (data)
		{
			this.notification_types(data);
		}
			
		setTimeout(() => { $('.selectpicker').selectpicker('rebuild'); }, 0);
	}
}

class ListNotificationPage
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new NotificationPageViewModel(this);
		this.name = 'NotificationPage';
		document.title =  'Notifications';

		this.loading = ko.observable(false);
		this.firstLoad = ko.observable(true);
		this.notification_types_test = ko.observableArray(['elllo', 'Bono']);
		
		this.notification_type_list = ko.observableArray();

		this.notification_type_list(['elllo', 'Bono']);
	}

	init () 
	{
		$("#tbl_notifications").bootstrapTable({
			columns: [
				{
					field: 'date_seen',
					title: 'Read',
					width: '50px',
					formatter: this.read_formatter,
					events:{
						'click #mark_as_read': this.mark_as_read
					}
				},
				{
					field: 'task_nr',
					title: 'Task Nr',
					width: '50px'
				},
				{
					field: 'date_inserted',
					title: 'Date',
					width: '100px',
					formatter: (value, row, index) => {
						return moment(value).format("YYYY-DD-MM");
					}
				},
				{
					field: 'inserted_by_username',
					title: 'User',
					width: '150px'
				},
				{
					field: 'software_units',
					title: 'Software Units',
					width: '150px'
				},
				{
					field: 'notification_type',
					title: 'Type',
					width: '150px'
				},
				{
					field: 'contents',
					title: 'Message'
				},
			],
			rowStyle: () => {
				return { css: {"cursor": "pointer"} };
			},
			onClickRow: (row, $element, field) => {
				if (field == 'date_seen')
					return;
				Grape.dialog.open( 'ViewTask', { task_id: row.task_id });
			},
			pagination: true,
			pageList: [10, 25, 50, 100],
			pageSize: 10,
			sortName: 'date_inserted',
			sortOrder: 'desc',
			sidePagination: 'client',
			data: []
		});


		this.refresh_data();
	}

	store_last_filter_values () 
	{
		
		if (this.viewModel.show_read_notifications() === true)
			localStorage.setItem('cabsav.notifications.filter.show_read_notifications', 'true');
		else
			localStorage.setItem('cabsav.notifications.filter.show_read_notifications', 'false');
		
		if (this.viewModel.selected_notification_types() !== undefined && this.viewModel.selected_notification_types().length > 0)
			localStorage.setItem('cabsav.notifications.filter.notification_types', JSON.stringify(this.viewModel.selected_notification_types()));
		else
			localStorage.setItem('cabsav.notifications.filter.notification_types', JSON.stringify([]));
	}

	async refresh_data () 
	{
		this.loading(true);


		try
		{

			if (this.firstLoad() ){
				await this.viewModel.update_lookups();
				setTimeout(() => { $('.selectpicker').selectpicker('refresh'); }, 0);
				this.firstLoad(false); 
			}
			await this.viewModel.refresh_notifications();
	
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		} finally {
			$("#tbl_notifications").bootstrapTable('load', this.viewModel.unread_notifications());
			this.loading(false);
		}

	}

	read_formatter (val, row, data) 
	{
		if (row.date_seen === null)
			return `
				<input id="mark_as_read" class="form-check-input" type="checkbox" value="${!!val}"></input>`;
		else
			return `<span>${moment(val).format('YYYY-DD-MM')}</span>`;
	}

	async mark_as_read (e, value, row, index) 
	{
		try
		{
			let response = await Grape.fetches.getJSON(`/mark_user_notification_as_read/${row.user_notification_id}`, {});
			if (response.status == 'OK')
			{
				//this.viewModel.unread_notifications.remove(row);
				$('#tbl_notifications').bootstrapTable('hideRow', { index: index });
				let options = $('#tbl_notifications').bootstrapTable('getOptions');

				$('tbl_notifications').bootstrapTable('selectPage', options.pageNumber);
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	async btnMarkAllAsRead_click () 
	{
		try
		{
			let response = await Grape.fetches.getJSON(`/mark_all_user_notification_as_read`, {});

			if (response.status == 'OK')
				this.refresh_data();
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}
}

export default {
	route: '[/]notifications',
	page_class: ListNotificationPage,
	template: template
}
