import template from './conversation.html';

class TaskConversationVM
{
	constructor (params) {
		this.params = params;
		this.message_text = ko.observable('');
		this.message_list = ko_helper.safe_observableArray(this.params.message_list || []);
		this.task_id = ko_helper.safe_observable(this.params.task_id);

		this.get_mention_users();
	}

	async post_message () 
	{
		console.log("message text:",this.message_text());

		let message_contents = this.message_text();

		try
		{
			if (message_contents.trim() == '')
				throw new Error('Cannot post an empty message');
			else
			{
				message_contents = message_contents.replace(/\n/g, "<br />");

				if (message_contents.length > 0)
				{
					let message = {
						contents: message_contents,
						task_id: this.task_id()
					};

					let response = await fetch('/api/task_message', {
						method: 'POST',
						body: JSON.stringify(message),
						headers: { 'content-type': 'application/json' }
					});

					if (response.ok)
					{
						if (this.params.new_message)
							this.params.new_message(message);

						this.message_text('');
					}
				}
			}
		} catch (error) {
			Grape.alert({ type: 'error', title: 'Message Contents', message: error.message });
			console.error(error);
		}
	}

	async get_mention_users () 
	{
		try
		{
			let users = await Grape.fetches.postJSON('/list_mention_users/'+ this.task_id(), {});

			if (users.list)
			{
				$("#txt_task_message").mention({
					delimiter: '@',
					sensitive: true,
					users: users.list
				});
			}
		} catch (error) {
			Grape.alert({ type: 'error', title: 'Message Contents', message: error.message });
		}
	}

	reply_to_message (msg) 
	{
		this.message_text(this.message_text() + '@' + msg.username + ' ');
		$("#txt_task_message").focus();
	}
}

export default {
	name: 'task-conversation',
	viewModel: TaskConversationVM,
	module_type: 'ko',
	template: template
}
