import template from './software_unit_access.html';

//Instantiate Page Variables
class SoftwareUnitAccessViewModel
{
	constructor(page) {
		this.page = page;
	}
}

//Handle interaction with page
class SoftwareUnitAccess
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new SoftwareUnitAccessViewModel(this);
		this.tbl_table = null;
		this.name = 'SoftwareUnitAccess';
	}

	//Event (Post/After): Load once
	async init ()
	{
		let actions_formatter = (value, row, index) => {
			let ret = [
				'<a class="download" data-bind="click: test" title="Approve" href="javascript:void(0)">',
				'<i class="fad fa-check" style="color: #77C4D3"></i>',
				'</a>&emsp;'
			].join('');

			return ret;
		}

		let click_handler = async (e, value, row, index) => {
			try
			{
				let response = await fetch('/software_unit/user/approve_access', {
					method: 'POST',
					body: JSON.stringify({ software_unit_id: row.software_unit_id, user_id: row.user_id }),
					headers: { 'content-type': 'application/json' }
				});

				if (response.ok)
				{
					this.updateData();
				}
			} catch(err) {
				Grape.alert({title: "Error", type: "error", message: err.message});
			}
		}

		let actions_events = {
			'click .download': click_handler
		}

		this.tbl_table = $("#su_tbl_table").bootstrapTable({
			columns: [
				{field: 'username', title: 'Username', sortable: true, width: ''},
				{field: 'software_unit_name', title: 'SoftwareUnit', sortable: true, width: ''},
				{field: 'date_requested', title: 'Requested', sortable: true, width: '',
					formatter: (val, row, idx) => { return moment(val).format(Grape.config.date_time_format); }
				},
				{
					field: 'action',
					width: '100px',
					align: 'right',
					title: 'Actions',
					formatter: actions_formatter,
					events: actions_events
				}
			],
			dataField: 'records',
			totalField: 'total',
			showRefresh: true,
			//TABLE PROPERTIES: Paging
			pagination: true,
			pageList: [20, 50, 100],
			pageSize: 20,
			sidePagination: 'server',
			onPageChange: (number, size) => {
				this.updateData();
			},

			//TABLE PROPERTIES: Sort
			onSort: (sortName, sortOrder) => {
				this.updateData();
			},
			search: true,
			searchOnEnterKey: true,
			onSearch: (text) => {
				this.updateData();
			},
			onRefresh: () => {
				let searchText = document.querySelector('#container > div > div.bootstrap-table.bootstrap3 > div.fixed-table-toolbar > div.pull-right.search.input-group > input').value;
				$('#su_tbl_table').bootstrapTable('refreshOptions', {searchText});
				this.updateData();
			}
		});
	}

	//Event (Post/After): Refresh page data
	async updateData ()
	{
		console.log('SoftwareUnitAccess updateData');

		let options = {
			table: 'v_pending_software_unit_access_request',
			schema: 'cabsav',
			offset: 0,
			limit: 10,
		};

		console.log('v_pending_software_unit_access_request', options);
		let table_options = $('#su_tbl_table').bootstrapTable('getOptions');

		if (table_options.searchText){
			options.filter = [{field:'username', operator:'ILIKE', value:`%${table_options.searchText}%`}];
		}

		if (table_options.sortName && table_options.sortName != '')
		{
			options.sortfield = table_options.sortName;
			options.sortorder = table_options.sortOrder.toUpperCase();
		}

		//LOGIC: Page size and offset instantiate
		if (table_options.pageNumber && table_options.pageSize)
		{
			options.limit = table_options.pageSize;
			options.offset = (table_options.pageNumber - 1) * options.limit;
		}

		//SERVER: api call to grape to retrieve data from db regarding request params set above
		try
		{
			let result = await Grape.fetches.getJSON('/api/record',options);

			if (result.status == 'OK')
				$("#su_tbl_table").bootstrapTable('load', result.records);
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	//Event (Pre/Before): Remove
	teardown = () => {}
}

export default {
	route: '[/]cabsavadmin/software_unit_access',
	page_class: SoftwareUnitAccess,
	template: template
}
