

class CabSavPlugin
{
	constructor(Grape, options)
	{
		this.Grape = Grape;
		this.options = options;
	}

	beforeNavbarRender()
	{
		const self = this;
		return new Promise(function(resolve, reject) {
			if (window.Grape.currentSession == null)
				return resolve();

			$.getJSON('/user/filter/list_names', {},async (result)=> {

				let dev_task_filters = [
							{name: 'Tasks assigned to me', href: '#/tasks_filter/assigned_to_me'},
							{name: 'Tasks made by me', href: '#/tasks_filter/requested_by_me'},
							{name: 'separator'}
				];
				

				var client_task_filters = [
					{name: 'Requests made by me', href: '#/tasks_filter/requested_by_me'},
					{name: 'Requests on my projects', href: '#/tasks_filter/all_open_requests'},
					{name: 'separator'}
				];

			
				var software_unit_task_filters = [
					{name: 'All task for my software units', href: '#/tasks_filter/my_software_units'},
					{name: 'separator'}
				];

				let _su = await Grape.cache.fetch('SoftwareUnitForUsername');
				if (result.filters && result.filters.length > 0)
				{
					result.filters.forEach(function(filter) {
						if (filter.shared_to_software_unit)
						{

							if(filter?.shared_to_su){
								if( filter.shared_to_su.some( item => _su.includes(item) )){
									dev_task_filters.push({name: filter.name, href: '#/tasks_filter/' + filter.name});
									client_task_filters.push({name: filter.name, href: '#/tasks_filter/' + filter.name});
								}
							}
							return;

						}

						if (filter.visible_in_navbar)
						{
							dev_task_filters.push({name: filter.name, href: '#/tasks_filter/' + filter.name});
							client_task_filters.push({name: filter.name, href: '#/tasks_filter/' + filter.name});
							return;
						}
					});
				}

				let devlist = self.Grape.config.navbar.find(function(item) { return (item.dynamic_navbar == 'dev-taskfilter-list'); });
				let clientlist = self.Grape.config.navbar.find(function(item) { return (item.dynamic_navbar == 'client-taskfilter-list'); });
				devlist.items = dev_task_filters;
				clientlist.items = client_task_filters;
/*
				var qa_items = [];

				qa_items.push({name: 'Create Test Case', href: '#/qa/test_case/edit'});
			

				let qa_list = self.Grape.config.navbar.find(function(item) { return (item.dynamic_navbar == 'qa-list'); });
				qa_list.items = qa_items;
*/
				// Kanban List
				var kanban_items = [];

				kanban_items.push({name: 'Edit Kanban Board', href: '#/kanban/edit'});
				kanban_items.push({name: 'Access Kanban Board', href: '#/kanban/manage'});

				let kanbanlist = self.Grape.config.navbar.find(function(item) { return (item.dynamic_navbar == 'dev-kanban-list'); });
				kanbanlist.items = kanban_items;

				// Project Info List
				//var project_info_items = [];

				//project_info_items.push({name: 'Roadmaps', href: '#/roadmaps'});

				//window.Grape.dynamic_navbar_items['project_info-list'] = {items: project_info_items};
			
				//console.log('going to create navbar now', window.Grape.navbar);
				//window.Grape.create_navbar(window.Grape.navbar);

				resolve();
			});
		});
	}
}

export default CabSavPlugin;

