import template from './add_task.html';

class AddTaskBoardViewModel
{
	constructor (page) {
		this.page = page;

		this.task_list = ko.observableArray([]);
		this.kanban_task_id_list = ko.observableArray([]);
	}
}

class AddTaskBoardDialog
{
	constructor (bindings)
	{
		this.bindings = bindings;
		this.viewModel = new AddTaskBoardViewModel(this);
	}

	init () 
	{
		this.get_task_list(this.bindings.kanban_board, () => {
			$(".modal-loader").hide();
		});
	}

	async get_task_list (board) 
	{
		let filter = {
			product_id : board.product_id,
			impact_list : ["Low", "Medium", "High"],
			effort_list : [1, 2, 3],
			urgency_list : ["Low", "Medium", "High", "Urgent"]
		};

		try
		{	
			let result = await fetch('/kanban/task/add/custom', {
				method: 'POST',
				body: JSON.stringify(filter),
				headers: { 'content-type': 'application/json' }
			});

			let data = await result.json();
			if (result.ok)
			{
				this.viewModel.task_list(data);
				return true;
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
		}
	}

	manage_task (task) 
	{
		let task_id_list = this.viewModel.kanban_task_id_list();

		if (task_id_list.indexOf(task.task_id) > -1)
			this.viewModel.kanban_task_id_list.remove(task.task_id);
		else if (task_id_list.indexOf(task.task_id) == -1)
			this.viewModel.kanban_task_id_list.push(task.task_id);
	}

	async add_tasks () 
	{
		let new_task_id_list = this.viewModel.kanban_task_id_list();
		let stored_status_list = this.bindings.kanban_board.board_parameters.status_list;
		let stored_task_id_list = this.bindings.kanban_board.board_parameters.task_list;

		new_task_id_list.forEach((v) => {
			stored_task_id_list.push(v);
		});

		let parameters = {
			status_list : stored_status_list,
			task_list : stored_task_id_list
		};

		let board_data = {
			board_id : this.bindings.kanban_board.board_id,
			task_list : stored_task_id_list,
			board_parameters : parameters
		};

		try
		{
			let result = await fetch('/kanban/parameters/update', {
				method: 'POST',
				body: JSON.stringify(board_data),
				headers: { 'content-type': 'application/json' }
			});

			if (result.ok)
			{
				Grape.alert({
					title: 'Task(s) added',
					message: "task(s) has been added to the board.",
					type: 'success',
				});
			}
		} catch (err) {
			Grape.alert({ title: 'Error', type: 'error', message: err.message });
			console.error(err);
		}
	}

	close_dialog () 
	{
		this.close(false);
	}
}

export default {
	name: 'AddTaskBoard',
	page_class: AddTaskBoardDialog,
	template: template
}
