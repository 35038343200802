import template from './add_software_unit.html';

//PAGE: Instantiate Page Variables 
class AddSoftwareUnitViewModel
{
	constructor (page) {
		this.page = page;
		this.software_unit_id = ko.observable();
		this.software_unit_name = ko.observable();
		this.short_description = ko.observable('');
		this.task_prefix = ko.observable();
		this.software_unit_types = ko.observableArray([]);
		this.selected_software_unit_type = ko.observable();
	}

	// load initial data
	load_data () 
	{
		Grape.cache.fetch('SoftwareUnitTypes', (data) => {
			this.software_unit_types(data);
		});
	}
}

class AddSoftwareUnitDialog
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new AddSoftwareUnitViewModel(this);
	}

	init () 
	{
		 this.viewModel.load_data();
		
		if (this.bindings.row) 
			ko.mapping.fromJS(bindings.row, {}, this.viewModel);
		else
		{
			var obj = {
				software_unit_id: null,
				software_unit_name: '',
				task_prefix: '',
			};
			
			ko.mapping.fromJS(obj, {}, this.viewModel);
		}
	}

	async btn_save_clicked () 
	{
		let software_unit_details = {
			software_unit_id: this.viewModel.software_unit_id()||null,  
			task_pre: this.viewModel.task_prefix(),
			software_unit_name: this.viewModel.software_unit_name(),
			software_unit_type_id: this.viewModel.selected_software_unit_type().software_unit_type_id,
			active: true
		};
		
		try
		{
			let result = await fetch('/cabsav/software_unit/save', {
				method: 'POST',
				body: JSON.stringify(software_unit_details),
				headers: { 'content-type': 'application/json' }
			});
			
			if (result.ok)
			{
				Grape.alert({ type: 'success', title: 'Success', message: 'New software unit created' });
				this.close(1);
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}
}

export default {
	name: 'AddSoftwareUnit',
	page_class: AddSoftwareUnitDialog,
	template: template
}
