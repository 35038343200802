import template from './new_roadmap.html';

class NewRoadmapViewModel
{
	constructor (page) {
		this.page = page;
		this.roadmap_name = ko.observable();
		this.description = ko.observable();
		this.start_date = ko.observable();
		this.software_unit_list = ko.observableArray();
		this.selected_software_unit = ko.observable();
		this.internal = ko.observable(false);
	}
}

class NewRoadmapDialog
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new NewRoadmapViewModel(this);
	}

	init () 
	{
		Grape.cache.fetch('SoftwareUnitUsernames', (data) => {
			if (data)
				this.viewModel.software_unit_list(data);
		});
	}
	
	updateData () {}

	async btn_create_clicked () 
	{
		const input = {
			software_unit_id: this.viewModel.selected_software_unit().software_unit_id, 
			roadmap_name: this.viewModel.roadmap_name(), 
			description: this.viewModel.description(), 
			start_date: this.viewModel.start_date(),
			internal: this.viewModel.internal(),
			status: 'New'
		};

		try
		{
			if (!input.roadmap_name || !input.roadmap_name.length)
				throw new Error('Roadmap name is empty');
			
			await fetch('/api/tasks/roadmap', {
				method: 'POST',
				body: JSON.stringify(input),
				headers: { 'content-type': 'application/json' }
			});
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
		this.close();
	}

	btn_close_clicked () 
	{
		this.close();
	}
}

export default {
	name: 'NewRoadmap',
	page_class: NewRoadmapDialog,
	template: template
}
