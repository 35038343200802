(function() {
window.GrapeLocals = window.GrapeLocals ?? {};
window.GrapeLocals.TaskActions = {
	'view': function(obj, done) {
		if (obj.task)
			var task = obj.task;
		else
			var task = obj;

		if (!done || typeof done != 'function')
			var done = function() { }

		Grape.show_dialog(
			'ViewTask',
			{
				task: task
			},
			{
				onClose: done
			}
		);

	},
	'view_history': function(obj) {
		if (obj.task)
			var task = obj.task;
		else
			var task = obj;
		
		if (!done || typeof done != 'function')
			var done = function() { }

		Grape.show_dialog(
			'TaskHistory',
			{
				task: task,
				task_id: task.task_id
			}
		);

	},
	'edit': function(obj, done) {
		if (obj.task)
			var task = obj.task;
		else
			var task = obj;
		
		if (!done || typeof done != 'function')
			var done = function() { }

		Grape.show_dialog(
			'EditTask',
			{
				task: task
			},
			{
				onClose: done
			}
		);

	},
	'hours': function(obj, done) {
		if (obj.task){
			var task = obj.task;
			var stop_watch_time = obj.stop_watch_time;
		}
		else
			var task = obj;

		Grape.show_dialog(
			'TaskDevHours',
			{
				task: task,
				stop_watch_time : stop_watch_time,
				task_id: task.task_id
			},
			{
				onClose: done
			}

		);

	},
	'linked-tasks': function(obj, done) {
		if (obj.task)
			var task = obj.task;
		else
			var task = obj;

		Grape.show_dialog(
			'TaskLinks',
			{
				task: task,
				task_id: task.task_id,
				request_id: task.task_id
			},
			{
				onClose: done
			}

		);

	},
	'recurring-series': async function(obj) {
		let task_id = obj.task_id ?? null;
		let recurring_task_series_id = obj.recurring_task_series_id ?? null;
		let result = await Grape.dialog.open('EditRecurringSeries', {
			task_id: task_id,
			recurring_task_series_id: recurring_task_series_id
		});
		return result;
	}


};
})();
