export default {
	name: 'RequesteeLookup',
	options: {
		refresh: function(cb) {
			var req = $.postJSON('/client/requestee_list', {}, function(result) {
				cb(null, result.requestees);
			});
			//TODO error handle
		}
	}
};

