/*
 * OPTIONS:
 * 	fields - all available fields, array of objects with items "field" 
 * 	selected - selected fields, an array of objects with items "field" and "order"
 */

import template from './sorting-selector.html';

class SortingSelectorVM
{
	constructor (params) {
		this.available_fields = ko_helper.safe_observableArray(params.fields);
		this.selected_fields = ko_helper.safe_observableArray(params.selected);
		this.selected_field = ko.observable();

		this.label_field = ko_helper.safe_observable(params.label_field);
		if (params.done && typeof params.done == 'function')
			this.done = params.done;
		else
			this.done = () => {};
	} 

	add_selected_after () 
	{
		if (this.selected_fields.indexOf(this.selected_field()) == -1)
			this.selected_fields.push(this.selected_field());
	}

	add_selected_before () 
	{
		if (this.selected_fields.indexOf(this.selected_field()) == -1)
			this.selected_fields.unshift(this.selected_field());
	}

	delete_item (e) 
	{
		this.selected_fields.splice(this.selected_fields.indexOf(e), 1);
	}
}

export default {
	name: 'sorting-selector',
	viewModel: SortingSelectorVM,
	module_type: 'ko',
	template: template
}
