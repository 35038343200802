import template from './task-card.html';

class TaskCardVM
{
	constructor (options) {
	

		this.options = options;
		let task = ko.utils.unwrapObservable(this.options.task);


		task.software_units_string = task.software_units.join(', ');
		this.task = ko.observable(task);
		this.selected = ko.observable(false);

		if(	this.options.selected_items){
			this.options.selected_items.subscribe(() => {
				console.log(this.options.selected_items().length);
				if (this.options.selected_items.indexOf(task) == -1)
					this.selected(false);
				else
					this.selected(true);
			});
		}

		if (this.options.dialog_visible){

			if (this.options.dialog_visible())
			{
				this.clicked_on_card = () => {
					if (this.options.selected_items.indexOf(task) == -1)
						this.options.selected_items.push(task)
					else 
						this.options.selected_items.splice(this.options.selected_items.indexOf(task), 1);
					}
			}
			else
			{
				this.clicked_on_card = () => {
					window.GrapeLocals.TaskActions['view'](this.task(), () => {});
				}
			}



			this.options.dialog_visible.subscribe((val) => {
			if (val)
			{
				this.clicked_on_card = () => {
					if (this.options.selected_items.indexOf(task) == -1)
						this.options.selected_items.push(task)
					else 
						this.options.selected_items.splice(this.options.selected_items.indexOf(task), 1);
				}
			}
			else
			{
				this.clicked_on_card = () => {
					window.GrapeLocals.TaskActions['view'](this.task(), () => {});
				}
			}
		});




		}


		this.actions = window.GrapeLocals.TaskActions;
	}

	priority_color (prio) 
	{
		if (prio == 'Low')
			return '#17a2b8';
		if (prio == 'Medium')
			return '#28a745';
		if (prio == 'High')
			return '#ffc107';
		if (prio == 'Urgent')
			return '#dc3545';
	}

	click_edit () 
	{
		window.GrapeLocals.TaskActions['edit'](this.task(), () => {});
	}

	click_view () 
	{
		window.GrapeLocals.TaskActions['view'](this.task(), () => {});
	}

	click_history () 
	{
		window.GrapeLocals.TaskActions['view_history'](this.task(), () => {});
	}
}

export default {
	name: 'task-card',
	viewModel: TaskCardVM,
	module_type: 'ko',
	template: template
}
