
import DepartmentList from './DepartmentList.js';
import DeveloperLookup from './DeveloperLookup.js';
import NotificationTypeLookup from './NotificationTypeLookup.js';
import RequesteeLookup from './RequesteeLookup.js';
import SoftwareUnits from './SoftwareUnits.js';
import SoftwareUnitsUsernames from './SoftwareUnitsUsernames.js';
import SoftwareUnitForUsername from './SoftwareUnitsCurrentUsername.js';
import SoftwareUnitTypes from './SoftwareUnitTypes.js';
import su_version_status from './su_version_status_lookup.js';
import categories from './task_categories.js';
import Roadmaps from './Roadmaps.js';
import TaskCategoryLookup from './TaskCategoryLookup.js';
import TaskStatusLookup from './TaskStatusLookup.js';
import TaskTagLookup from './TaskTagLookup.js';

export default [
	DepartmentList,
	DeveloperLookup,
	NotificationTypeLookup,
	RequesteeLookup,
	Roadmaps,
	SoftwareUnits,
	SoftwareUnitsUsernames,
	SoftwareUnitForUsername,
	SoftwareUnitTypes,
	su_version_status,
	categories,
	TaskCategoryLookup,
	TaskStatusLookup,
	TaskTagLookup
];

