import template from './kanban_lane.html';

class KanbanLaneVM
{
	constructor (params) {
		this.params = params;
		this.title = ko_helper.safe_observable(this.params.name, '');
		this.idx = ko_helper.safe_observable(this.params.idx, '');
		this.associated_cabsav_status = ko_helper.safe_observable(this.params.associated_cabsav_status, '');
		this.levels = ko_helper.safe_observableArray(this.params.levels, []);
		this.tasks = ko_helper.safe_observableArray(this.params.tasks, []);
		this.full_path = ko_helper.safe_observable(this.params.full_path, null);
		this.cb_load_more = this.params.cb_load_more || (() => {});

		this.show_status_indicator = ko.pureComputed(() => {
			let status = this.associated_cabsav_status();

			return !!(status) && status.length > 0;
		});

		this.status_indicator_message = ko.pureComputed(() => {
			let status = this.associated_cabsav_status();

			return `Status: ${status}\n`;
		});

		this.collapsed = ko_helper.safe_observable(this.params.collapsed, false);

		this.valid_target = () => {
			return this.levels().length === 0;
		}

		this.sorted_levels = ko.pureComputed(() => {
			return _.chain(this.levels())
				.sortBy('name')
				.sortBy('idx')
				.value();
		});

		this._sorted_tasks_invalidator = ko.observable();
		this.sorted_tasks = ko.pureComputed(() => {
			this._sorted_tasks_invalidator();
			return _.chain(this.tasks())
				.sortBy('idx')
				.value();
		});

		this.drag_enter = (data, e) => {
			e.stopPropagation();
			$('.kanban-lane-container.dragging').removeClass('dragging');

			let payload = e.originalEvent.dataTransfer.getData("text/json");
			try {
				payload = JSON.parse(payload);
			} catch (e) {
				payload = {
					stage_path: ''
				}
			}

			if (data.valid_target(payload.stage_path))
			{
				e.originalEvent.dataTransfer.dropEffect = 'move';
				e.preventDefault();
				$(e.currentTarget).addClass('dragging');
			}
			else { e.originalEvent.dataTransfer.dropEffect = 'none'; }

			return false;
		}

		this.drag_end = (data, e) => {
			e.stopPropagation();
			e.preventDefault();

			$('.kanban-lane-container.dragging').removeClass('dragging');

			return false;
		}

		// dragover event handler
		this.allow_drop = (data, e) => {
			e.stopPropagation();

			let payload = e.originalEvent.dataTransfer.getData("text/json");

			if(payload)
				payload = JSON.parse(payload);

			let closest_element = document.elementFromPoint(e.originalEvent.clientX, e.originalEvent.clientY);

			while (
				!$(closest_element).hasClass('kanban-task') && 
				closest_element.parentElement
			)
			{
				closest_element = closest_element.parentElement;
			}
			
			if ($(closest_element).hasClass('kanban-task')) // hovering on top of another task. give it a border at the top
			{
				$('.kanban-task-hover-below').css('border-bottom', '');
				$('.kanban-task-hover-below').removeClass('kanban-task-hover-below');

				if (!$(closest_element).hasClass('kanban-task-hover-above'))
				{
					$('.kanban-task-hover-above').css('border-top', '');
					$('.kanban-task-hover-above').removeClass('kanban-task-hover-above');

					$(closest_element).css('border-top', '1px dotted blue');
					$(closest_element).animate({'border-top-width': '4px'}, 100);
					$(closest_element).addClass('kanban-task-hover-above');
				}
			}
			else
			{
				// find last task in lane
				closest_element = $('.kanban-task:last', e.currentTarget);
				
				$('.kanban-task-hover-above').css('border-top', '');
				$('.kanban-task-hover-above').removeClass('kanban-task-hover-above');

				if (!$(closest_element).hasClass('kanban-task-hover-below'))
				{
					$('.kanban-task-hover-below').css('border-bottom', '');
					$('.kanban-task-hover-below').removeClass('kanban-task-hover-below');

					$(closest_element).css('border-bottom', '1px dotted blue');
					$(closest_element).animate({'border-bottom-width': '4px'}, 50);
					$(closest_element).addClass('kanban-task-hover-below');
				}
			}

			if (data.valid_target(payload.stage_path))
			{
				e.preventDefault();
				return false;
			}
			else { return true; }
		}

		this.drop = async (data, e) => 
		{
			e.originalEvent.stopPropagation();

			if (data.valid_target())
			{
				e.preventDefault();
				$(e.currentTarget).removeClass('dragging');

				let payload = e.originalEvent.dataTransfer.getData("text/json");
				payload = JSON.parse(payload);

				let vm_source_lane = this.find_lane(payload.stage_path);
				let vm_target_lane = data;

				if (vm_source_lane && vm_target_lane)
				{
					let new_task_idx = 0;
					let place_after_idx = 0;
					if ($('.kanban-task-hover-below').length == 1)
					{
						new_task_idx = $('.kanban-task-hover-below').data('task-idx') + 1;
						place_after_idx = new_task_idx;
					}
					else if ($('.kanban-task-hover-above').length == 1)
					{
						new_task_idx = $('.kanban-task-hover-above').data('task-idx');
						place_after_idx = new_task_idx - 1;
					}

					if (vm_source_lane.full_path() !== vm_target_lane.full_path())
					{
						let element = _.find(vm_source_lane.tasks(), { task_id: payload.task_id });
						let task_vm = this.find_task(payload.task_id);
						if (element && task_vm)
						{
							task_vm.update_parent(vm_target_lane.full_path(), new_task_idx, (err, result) => {
								if (!err)
								{
									element.stage_path = vm_target_lane.full_path();
									element.idx = result.idx;

									this.refresh_task_data(element, (err, task_data) => {
										if (!err)
										{
											if (!task_data.board) { task_data.board = task_vm.board; }

											vm_target_lane.tasks.push(task_data);
											vm_source_lane.tasks.remove((itm) => {
												return itm.task_id === element.task_id;
											});
										}
									});
								}
							});
						}
					}
					else // same lane
					{
						try
						{
							let input = { board_task_id: payload.board_task_id, place_after_idx: place_after_idx };

							let result = await fetch('/kanban/update_task_idx', {
								method: 'POST',
								body: JSON.stringify(input),
								headers: { 'content-type': 'application/json' }
							});

							if (result.ok)
							{
								let tasks = this.tasks();

								tasks.forEach((task) => { 
									if (parseInt(task.task_id) == parseInt(payload.task_id))
										task.idx = new_task_idx;
									else if (task.idx >= new_task_idx)
										task.idx++;

									$('.kanban-task[data-task-id=' + task.task_id + ']').data('task-idx', task.idx);
									let task_vm = this.find_task(task.task_id);
									task_vm.idx(task.idx);
								});

								this.tasks(tasks);
								this._sorted_tasks_invalidator.notifySubscribers();
							}
						} catch (error) {
							Grape.alert({ title: 'Error', type: 'error', message: error.message });
						}
					}
				}
			}

			$('.kanban-task-hover-below').css('border-bottom', '');
			$('.kanban-task-hover-below').removeClass('kanban-task-hover-below');
			$('.kanban-task-hover-above').css('border-top', '');
			$('.kanban-task-hover-above').removeClass('kanban-task-hover-above');

			return true;
		}

		this.refresh_task_data = async (task_data, done) => {
			try
			{
				let res = await Grape.fetches.getJSON(`/kanban/select_task/${task_data.board_task_id}`, {});

				if (res.status === 'OK' && res.task)
				{
					let task = res.task;

					task_data.board = task.board;
					task_data.attachment_count = task.attachment_count || 0;
					task_data.board_task_id = task.board_task_id;
					task_data.category = task.category;
					task_data.child_count = task.child_count || 0;
					task_data.closed_child_count = task.closed_child_count || 0;
					task_data.department = task.department;
					task_data.difficulty = task.difficulty;
					task_data.deadline = task.deadline;
					task_data.impact = task.impact;
					task_data.priority = task.priority;
					task_data.project_name = task.project_name;
					task_data.short_description = task.short_description;
					task_data.stage_path = task.stage_path;
					task_data.status = task.status; // New
					task_data.tags = task.tags || [];
					task_data.task_id = task.task_id; // 5001
					task_data.task_nr = task.task_nr; // PQ111
					task_data.assigned_to = task.assigned_to;
					task_data.idx = task.idx;
					done(null, task_data);
				}
				else
				{
					throw new Error(`${res.status}: ${res.message}`);
				}
			} catch (error) {
				Grape.alert({ title: 'Error', type: 'error', message: error.message });
			}
		}

		this.find_lane = (full_path) => {
			let lane_element = $(`.kanban-lane[data-path="${full_path}"]`);
			if (lane_element && lane_element.length > 0)
			{
				let vm = ko.dataFor(lane_element[0]);
				return vm;
			}
			else { return null; }
		}

		this.find_task = (full_path) => {
			let lane_element = $(`.kanban-task[data-task-id="${full_path}"]`);
			if (lane_element && lane_element.length > 0)
			{
				let vm = ko.dataFor(lane_element[0]);
				return vm;
			}
			else { return null; }
		}
	}

	toggle_collapsed () 
	{
		this.collapsed(!this.collapsed());
	}
}

export default {
	name: 'kanban-lane',
	viewModel: KanbanLaneVM,
	module_type: 'ko',
	template: template
}
