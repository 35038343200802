
import SUList from './software_units/software_units.js';
import edit_categories from './edit_categories.js';
import list_notifications from './notifications/list_notifications.js';
import task_filter from './task_filter/task_filter.js';
import KanbanEdit from './kanban/edit_kanban.js';
import KanbanView from './kanban/manage_kanban.js';
import SoftwareUnitAccess from './software_unit_access/software_unit_access.js';
import TaskSearch from './task_search/page.js';
import Roadmaps from './roadmaps/roadmaps.js';
import ViewRoadmap from './roadmaps/view_roadmap.js';
import CabSavUsers from './cabsav_users/users.js';
import QAEditTestCase from './QA/test_case/edit_test_case.js';
import QAViewTestCase from './QA/test_case/view_test_case.js';
import QAListTestCase from './QA/test_case/list_test_case.js';
import filebrowser from './filestore/browser.js';

export default [
	SUList,
	edit_categories,
	list_notifications,
	task_filter,
	KanbanEdit,
	KanbanView,
	SoftwareUnitAccess,
	TaskSearch,
	Roadmaps,
	ViewRoadmap,
	CabSavUsers,
	QAEditTestCase,
	QAViewTestCase,
	QAListTestCase,
	filebrowser
];
