
import TaskCard from './task-card.js';
import TaskStatus from './task-status/ko.components.task-status.js';
import KanbanLane from './kanban_lane/kanban_lane.js';
import KanbanTask from './kanban_task/kanban_task.js';
import PageLoader from './page_loader/page_loader.js';
import TaskConversation from './task-conversation/conversation.js';
import ColumnSelector from './column-selector/column-selector.js';
import AttachmentList from './attachment-list/attachment-list.js';
import SortingSelector from './sorting-selector/sorting-selector.js';
import FormattingRuleSelector from './formatting-rule-selector/formatting-rules.js';
import navbar from './navbar/navbar.js';


export default [
	TaskCard,
	navbar,
	KanbanLane,
	KanbanTask,
	PageLoader,
	TaskStatus,
	TaskConversation,
	ColumnSelector,
	AttachmentList,
	SortingSelector,
	FormattingRuleSelector
];
