import template from './edit_kanban_level.html';

class EditKanbanLevelViewModel
{
	constructor (page, bindings) {
		this.bindings = bindings;
		this.page = page;

		this.edit_mode = ko.observable(this.bindings.name && this.bindings.name.length > 0);
		this.name = ko_helper.safe_observable(this.bindings.name, '');
		this.description = ko_helper.safe_observable(this.bindings.description, '');
		this.full_path = ko_helper.safe_observable(this.bindings.full_path, '');
		this.associated_cabsav_status = ko_helper.safe_observable(this.bindings.associated_cabsav_status, '');
		this.all_status = ko.observableArray([]);

		Grape.cache.fetch('TaskStatusLookup', (data) =>
		{
			if (data)
			{
				let status_options = data.map((status)=>{return {text:status.status, value:status.status}});
				status_options.unshift({ text:'None', value: '' });
				this.all_status(status_options);
				this.associated_cabsav_status(bindings.associated_cabsav_status);
			}
		});

		this.can_save = ko.pureComputed(() =>
		{
			let name = this.name();

			return name && name.length > 0 && name.match(/^[A-Za-z_0-9]+$/);
		});
	}
}

class EditKanbanLevelDialog
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new EditKanbanLevelViewModel(this, bindings);
	}

	init () {};

	save_level (model, e)
	{
		this.close({
			name: this.viewModel.name(),
			full_path: this.viewModel.full_path(),
			description: this.viewModel.description(),
			associated_cabsav_status: this.viewModel.associated_cabsav_status()
		});
	}

	cancel ()
	{
		this.close(false);
	}

	close_dialog ()
	{
		this.close(false);
	}
}

export default {
	name: 'EditKanbanLevel',
	page_class: EditKanbanLevelDialog,
	template: template
}
