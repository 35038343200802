import template from './navbar.html';

/**
 * Navbar View Model
 */
class NavbarViewModel
{
	/**
	 * Navbar constructor
	 */
	constructor ()
	{
		this.product_name = ko.observable(Grape.config.public_settings?.product_name);
		this.service_name = ko.observable(Grape.config.public_settings?.service_name);
		this.site_name = ko.observable(Grape.config.public_settings?.site_name);
	}
}

export default {
	name: 'ko-demo-navbar',
	viewModel: NavbarViewModel,
	module_type: 'ko',
	template: template
}
