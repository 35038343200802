import template from './software_unit.html';

class SoftwareUnitVersionModel
{
	constructor(data, parent) {
		this.parent = parent;
		this.status_lookup = ko.observableArray();
		this.new_status = ko.observable();

		this.software_unit_version_id = ko.observable(data.software_unit_version_id || null);
		this.version_string = ko.observable(data.version_string || '');
		this.date_inserted = ko.observable(data.date_inserted || '');
		this.date_released = ko.observable(data.date_released || '');
		this.status = ko.observable(data.status || '');
		this.changelog = ko.observable(data.changelog || '');
		this.git_tag = ko.observable(data.git_tag || '');
		this.created_by = ko.observable(data.created_by || '');

		this.editing = ko.observable(false);
	}

	async btnsave_click (suv) 
	{
		let obj = {
			software_unit_version_id: this.software_unit_version_id(),
			status: this.new_status().name,
			changelog: this.changelog(),
			git_tag: this.git_tag()
		};

		try 
		{
			let result = await fetch('/cabsav/software_unit_version/save', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});

			if (result.ok) 
			{
				Grape.alert({ type: 'success', title: 'Success', message: 'Success' });
				suv.editing(false);
				parent.fetch_version_info();
			}
		} catch(err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
			console.error(error);
		} finally {
			this.close(this.viewModel.has_changed());
		}
	}

	btnedit_click (suv) 
	{
		if (this.parent.is_owner() == false)
		{
			alert('Only the owner can edit versions');
			return;
		}

		this.new_status(this.status_lookup().find((e) => { return (e.name == this.status()); }));
		suv.editing(true);
	}
}

class SoftwareUnitViewModel 
{
	constructor(page) {
		this.page = page;
		
		this.software_unit_types = ko.observableArray(['Service', 'Product', 'Module', 'Special']);

		this.name = ko.observable();
		this.owner = ko.observable();
		this.software_unit_id = ko.observable();
		this.software_unit_type = ko.observable();
		this.attributes = ko.observableArray();
		this.notification_types = ko.observableArray();
		this.linked_users = ko.observableArray();
		this.is_owner = ko.observable(false);
		this.scrum_master = ko.observable('');
		this.description = ko.observable('');
		this.active = ko.observable(false);
		this.task_prefix = ko.observable('');
		this.total_task_count = ko.observable(0);
		this.open_task_count = ko.observable(0);

		this.docs_url = ko.observable('');
		this.git_urls = ko.observableArray([]);
		
		this.kanban_boards = ko.observableArray([]);
		this.roadmaps = ko.observableArray([]);

		this.svc_client = ko.observable('');
		this.svc_level = ko.observable('');

		this.new_git_url = ko.observable('');
		this.new_git_role = ko.observable('');
		this.new_git_default = ko.observable('');
		this.new_git_qa = ko.observable('');

		this.email_notifications = ko.observableArray();
		this.online_notifications = ko.observableArray();
		
		this.software_unit_versions = ko.observableArray();
		this.software_unit_version_status_lookup = ko.observableArray();
			
		this.is_loading = ko.observable(true);
		this.has_changed = ko.observable(false);

		this.editing_mode = ko.observable(false);

		this.files_path = ko.observable('');

		this.new_user_email = ko.observable();

		this.active_versions = ko.observableArray();

		this.new_suv_major = ko.observable('0');
		this.new_suv_minor = ko.observable('0');
		this.new_suv_patch = ko.observable('0');
		this.new_suv_label = ko.observable('');
		this.new_suv_status = ko.observable();

		this.raw_data = ko.observable();
	}

	add_git_url () 
	{ 
		let new_obj = {
			url: this.new_git_url(),
			role: this.new_git_role(),
			default: this.new_git_default(),
			qa: this.new_git_qa()

		};
		this.git_urls.push(new_obj);
	}

	remove_git_url (item) 
	{
		let removed = this.git_urls.indexOf(item);

		this.git_urls.splice(removed,1);
	}

	edit_click () 
	{
		this.editing_mode(true);
	}

	async new_user_click () 
	{
		console.log(this.new_user_email());
		console.log(this.software_unit_id());
		try 
		{
			let result = await Grape.fetches.postJSON(`/link_user_to_software_unit/${this.name()}/${this.new_user_email()}`, {});

			if (result.status == 'OK')
			{
				Grape.alert({type: 'success', title: 'User linked', message: 'User was linked successfuly'});
				this.updateData();
			}
			else
			{
				throw new Error(`${result.status}: ${result.message}`);
			}
		} catch (err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
			console.error(err);
		}
	}

	async add_software_unit_version () 
	{
		let obj = {
			software_unit_id: this.software_unit_id(),
			major_version: this.new_suv_major(),
			minor_version: this.new_suv_minor(),
			patch_version: this.new_suv_patch(),
			label: this.new_suv_label(),
			status: this.new_suv_status().name
		};

		try 
		{
			let result = await fetch('/cabsav/software_unit_version/save', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});

			if (result.ok)
			{
				Grape.alert({type: 'success', title: 'Version saved', message: 'Version saved successfuly'});
				this.has_changed(true);
				this.updateData();
			}
		} catch(err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
			console.error(err);
		}
	}

	async save_attr_click () 
	{
		let ar = [];
		this.attributes().forEach(async (attr) => {
			// TODO only save the ones that havent changed
			await Grape.fetches.postJSON('/software_unit_attribute', { software_unit_name: this.name(), attribute_name: attr.name, value: attr.value });
			ar.push(attr);
		});
		this.attributes(ar);
		this.editing_mode(false);
	}

	async save_settings_click () 
	{
		let attributes = [
			{name: 'SHORT_DESCRIPTION', value: this.description()},
			{name: 'DOCS_URL', value: this.docs_url()},
			{name: 'SCRUM_MASTER', value: this.scrum_master()},
			{name: 'GIT_URLS', value: this.git_urls()}
		];

		if (this.software_unit_type() == 'Service')
		{
			attributes.push({name: 'CLIENT', value: this.svc_client()});
			attributes.push({name: 'SERVICE_LEVEL', value: this.svc_level()});
		}

		let obj = {
			software_unit_id: this.software_unit_id(),
			software_unit_name: this.name(),
			task_pre: this.task_prefix(),
			active: this.active(),
			software_unit_type: this.software_unit_type(),
			attributes: attributes
		};

		try 
		{
			let res = await fetch('/cabsav/software_unit/save', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});

			if (res.ok)
			{
				this.has_changed(true);
				Grape.alert({ type: 'success', title: 'Settings saved', message: 'Saved successfuly' });
			}
		}
		catch(err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
			console.error(err);
		} finally {
			this.page.btnclose();
		}
	}

	async delete_user (username) 
	{
		console.log(username);
		try 
		{
			let result = await Grape.fetches.getJSON('/unlink_user_from_software_unit/' + this.name() + '/' + username, {});

			if (result.status == 'OK')
			{
				Grape.alert({ type: 'success', message: 'Access has been removed', title: 'Success' });
				this.updateData();
			}
			else
			{
				throw new Error(`${result.status}: ${result.message}`);
			}
		} catch (err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
			console.error(err);
		}
	}

	async online_notification_click (nnt) 
	{
		var obj = {
			software_unit_id: this.software_unit_id(),
			notification_type: nnt.notification_type,
			delivery_type: 'notify',
			active: nnt.checked()
		};

		try 
		{
			let result = await fetch('/user/update_notification_setting', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});

			if (result.ok)
				this.updateData();
		} catch (err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
			console.error(err);
		}
	}

	async email_notification_click (ent) 
	{
		var obj = {
			software_unit_id: this.software_unit_id(),
			notification_type: ent.notification_type,
			delivery_type: 'email',
			active: ent.checked()
		};

		try 
		{
			let result = await fetch('/user/update_notification_setting', {
				method: 'POST',
				body: JSON.stringify(obj),
				headers: { 'content-type': 'application/json' }
			});

			if (result.ok)
				this.updateData();
		} catch (err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
			console.error(err);
		}
	}

	//claim_ownership working???
	//only devs can create a software_unit 
	//if dev creates it ownership is automatically allocated to dev
	//also not able to open dialog without having access
	//thus no way of claiming ownership
	async claim_ownership () 
	{
		try
		{
			let result =  await fetch(`/software_unit/${this.software_unit_id()}/claim_ownership`, {
				method: 'POST',
				body: {},
				headers: { 'content-type': 'application/json' }
			});
			
			if (result.ok) 
			{
				this.has_changed(true);
				this.updateData();
			}
		} catch (err) {
			Grape.alert({ type: 'error', title: 'Error', message: err.message });
		}
	}

	async fetch_su () 
	{
		try
		{
			let result = await Grape.fetches.getJSON('/cabsav/software_unit', {software_unit_id: this.software_unit_id()});
			this.raw_data(result);

			if (result.status == 'ERROR')
				throw new Error(`${result.status}: ${result.message}`);

			this.description(result.attributes['SHORT_DESCRIPTION'] || '');
			this.docs_url(result.attributes['DOCS_URL'] || '');
			this.git_urls(result.attributes['GIT_URLS'] || []);
			this.svc_client(result.attributes['CLIENT'] || []);
			this.svc_level(result.attributes['SERVICE_LEVEL'] || []);
			this.scrum_master(result.attributes['SCRUM_MASTER'] || '');

			let combined_attrs = [];
			result.type_attributes.forEach(function(tv) {
				let k = tv.name;
				let v = result.attributes[k] || '';

				combined_attrs.push({
					name: tv.name,
					optional: tv.optional || false,
					value: v,
					description: tv.description
				});
			});
			this.attributes(combined_attrs);

			this.name(result.software_unit_name);
			this.files_path('/Files/Software Units/' + result.software_unit_name);
			this.active(result.active);
			this.owner(result.owner);
			this.software_unit_type(result.software_unit_type);
			this.linked_users(result.linked_users);
			this.task_prefix(result.task_prefix);
			this.total_task_count(result.total_tasks);
			this.open_task_count(result.open_tasks);
			this.roadmaps(result.roadmaps);
			this.kanban_boards(result.kanban_boards);

			if (this.owner() == window.Grape.currentSession.user.username)
				this.is_owner(true);

			let email_notifications = [];
			let online_notifications = [];
			this.notification_types().forEach((nt) =>
			{
				let ent = Object.assign({checked: ko.observable(false)}, nt);
				if (result.default_email_types.indexOf(nt.notification_type) != -1)
					ent.checked(true);
				email_notifications.push(ent);

				let nnt = Object.assign({checked: ko.observable(false)}, nt);
				if (result.default_notification_types.indexOf(nt.notification_type) != -1)
					nnt.checked(true);
				online_notifications.push(nnt);
			});

			this.email_notifications(email_notifications);
			this.online_notifications(online_notifications);
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	async fetch_version_info () 
	{
		try
		{
			let result = await Grape.fetches.getJSON('/api/record', { table: 'v_software_unit_versions', schema: 'cabsav', limit: 10, filter: [{field: 'software_unit_name', operand: '=', value: this.name() }]});

			if (result.status == 'OK')
			{
				let suvs = [];
				result.records.forEach((r) => {
					let suv = new SoftwareUnitVersionModel(r, this);
					suv.status_lookup(this.software_unit_version_status_lookup());
					suvs.push(suv);
				});
				
				this.software_unit_versions(suvs);
			} 
			else
			{
				throw new Error(`${result.status}: ${result.message}`);
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	async updateData () 
	{
		let data = await Grape.cache.get('NotificationTypeLookup');

		if (data) {
			this.notification_types(data);

			let res = await Grape.cache.get('SoftwareUnitVersionStatus');
			if (res) {
				this.software_unit_version_status_lookup(res);
				await this.fetch_su();
				await this.fetch_version_info();
			}
		}
	}
}

class SUDialogClass // bindings here will contain the data sent via Grape.show_dialog(name, data, options)
{
	constructor(bindings) 
	{
		this.bindings = bindings;
		this.viewModel = new SoftwareUnitViewModel(this); // Name of the ViewModel defined above
		this.name = 'SoftwareUnitDialog'; // Unique dialog name
	}
	
	async init () 
	{ 
		this.viewModel.software_unit_id(this.bindings.software_unit_id);
		console.log(this.bindings);
		console.log(this.viewModel.software_unit_id())
		await this.viewModel.updateData();
	}

	onClose () {};

	btnclose () {
		this.close(this.viewModel.has_changed());
	}
}

export default {
	name: 'SoftwareUnitDialog',
	dialog_class: SUDialogClass,
	template: template
}
