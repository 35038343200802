import template from './edit_task.html';
import ClassicEditor from '../../lib/ckeditor/build/ckeditor.js';
import ClassicEditorConfig from '../../lib/ClassicEditorConfig.js';

/* import Paragraph from '@ckeditor/ckeditor5-paragraph/src/paragraph';
import Essentials from '@ckeditor/ckeditor5-essentials/src/essentials';
import Bold from '@ckeditor/ckeditor5-basic-styles/src/bold';
import Italic from '@ckeditor/ckeditor5-basic-styles/src/italic';
import CKEditorFontPlugin from '@ckeditor/ckeditor5-font/src/font';
*/

class EditTaskViewModel
{
	constructor (dialog) {
		this.dialog = dialog;

		this.html_editor_loaded = ko.observable(false);
		this.enable_html_editor = () => 
		{
			this.dialog.enable_ckeditor();
		}
		this.add_parent_toggled = ko.observable(false);
		this.selected_parent_task = ko.observable();
		this.selected_parent_task.subscribe((new_parent) => 
		{
			this.new_parent_task_nr(new_parent.task_nr);
			$('#new_parent_task_selectbox', this.dialog.target).hide();
		});

		this.all_parent_tasks = ko.observableArray([]);
		this.task_id = ko.observable();
		this.task_prefix = ko.observable();
		this.task_nr = ko.observable();
		this.software_unit_item_id = ko.observableArray([]);
		this.software_unit_owner = ko.observable();
		this.software_unit_status = ko.observable('New');
		this.software_unit_alter_allowed = ko.observable(false);

		this.parent_task_description = ko.observable();
		this.parent_task_nr = ko.observable();
		this.parent_task_id = ko.observable(null);

		this.selected_requestee = ko.observable();
		this.allow_requestee_edit = ko.observable(false);

		this.short_description = ko.observable();
		this.full_description = ko.observable('');

		this.selected_developers = ko.observableArray();
		this.selected_department = ko.observable();

		this.selected_status = ko.observable('New');
		this.selected_software_units = ko.observableArray([]);
		this.selected_software_unit_ids = ko.observableArray([]);
		this.selected_category = ko.observable({ task_category: '', allowed_statuses: [], subcategories: [] });
		this.selected_subcategory = ko.observable('');
		this.selected_deadline = ko.observable();
		this.selected_planned_start_date = ko.observable();
		this.selected_priority = ko.observable();
		this.selected_difficulty = ko.observable();
		this.selected_impact = ko.observable();
		this.logged_in_user = ko.observable(localStorage.getItem('username'));

		this.status_list = ko.observableArray([]);
		this.software_unit_list = ko.observableArray([]);
		this.requestee_list = ko.observableArray([]);
		this.developer_lookup = ko.observableArray([]);
		this.developer_list = ko.observableArray([]);
		this.department_list = ko.observableArray([]);
		this.category_list = ko.observableArray([]);
		this.subcategory_list = ko.observableArray([]);
		this.priority_list = ko.observableArray([
			'Low', 'Medium', 'High', 'Urgent'
		]);
		this.difficulty_list = ko.observableArray([
			'Easy', 'Medium', 'Hard'
		]);
		this.impact_list = ko.observableArray([
			'Low', 'Medium', 'High'
		]);

		this.selected_software_units.subscribe((new_software_units) => 
		{
			let new_developers = [];
			let software_unit_ids = [];
			if (new_software_units)
			{
				new_software_units.forEach((nsu) => {
					if (nsu)
					{
						new_developers = _.uniq(new_developers.concat(nsu.devs));
						software_unit_ids.push(nsu.software_unit_id);
					}
				});
			}
			this.selected_software_unit_ids(software_unit_ids);
			let new_devs_objects = [];
			new_developers.forEach((d) => {
				let dev = this.developer_lookup().find((dev) => { return dev.username == d });
				if (dev)
					new_devs_objects.push(dev);
				else
					throw new Error(`Unable to locate dev username=${d}`);
			});
			this.developer_list(new_devs_objects);
			console.log(this.developer_list());
			setTimeout(() => { $('.selectpicker').selectpicker('refresh'); }, 0);
		});

		this.new_parent_task_nr = ko.observable('');
	}

	async remove_parent_task () 
	{
		if (this.task_id())
		{
			let input = { task_id: this.task_id(), parent_task_id: null };

			try
			{
				let result = await fetch('/api/task', {
					method: 'POST',
					body: JSON.stringify(input),
					headers: { 'content-type': 'application/json' }
				});

				if (result.ok)
				{
					this.parent_task_id(null);
					this.parent_task_description(null);
					this.parent_task_nr(null);
					this.dialog.something_changed = true;
					Grape.alert({ title: 'Success', message: 'Parent task removed', type: 'success' });
				}
			} catch (error) {
				Grape.alert({ title: 'Error', type: 'error', message: error.message });
			}
		}
	}

	btn_add_to_parent_click () 
	{
		this.add_parent_toggled(true);
	}

	async refresh_parent_list () 
	{
		try
		{
			let result = await Grape.fetches.getJSON(`/task_autocomplete/${this.new_parent_task_nr()}`, {});
			
			if (result.status == 'OK')
			{
				if (result.list)	
				{
					result.list.forEach((item) => {
						item['label'] = item.task_nr + ' - ' + item.short_description.slice(0, 20);
					});
					this.all_parent_tasks(result.list);
					$('#new_parent_task_selectbox', this.dialog.target).css('top', $('#new_parent_task_search_txt').outerHeight() + 'px');
					$('#new_parent_task_selectbox', this.dialog.target).show();
				}
				else
					this.all_parent_tasks([]);
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error)
		}
	}

	async save_parent_task () 
	{
		$('.modal-loader').show();

		try
		{
			let res = await Grape.fetches.getJSON(`/task_autocomplete/${this.new_parent_task_nr()}`, {});
			
			if (res.status == 'ERROR')
				throw new Error(res.message || res.code);

			if (res.list && res.list.length == 1)
			{
				let new_parent = res.list[0];

				if (new_parent.task_id == this.task_id())
				{
					$('.modal-loader').hide();
					Grape.alert({ type: 'warning', message: 'Parent task cannot be the same as the child task!', title: 'Invalid Parent Task' });
				}
				else if (this.task_id() == null)
				{
					$('.modal-loader').hide();
					this.parent_task_id(new_parent.task_id);
					this.parent_task_nr(new_parent.task_nr);
					this.parent_task_description(new_parent.short_description);
				}
				else
				{
					let input = { task_id: this.task_id(), parent_task_id: res.list[0].task_id };
					let result = await fetch('/api/task', {
						method: 'POST',
						body: JSON.stringify(input),
						headers: { 'content-type': 'application/json' }
					});
					$('.modal-loader').hide();

					if (result.ok)
					{
						this.parent_task_id(new_parent.task_id);
						this.parent_task_description(new_parent.short_description);
						this.parent_task_nr(new_parent.task_nr);
						this.dialog.something_changed = true;
						Grape.alert({ title: 'Success', message: 'Parent task saved', type: 'success' });
						this.btn_cancel_parent_click();
					}
					else 
						throw new Error(result.message || result.code)
				}
			}
			else
			{
				$('.modal-loader').hide();
				Grape.alert({ type: 'warning', message: 'Invalid task number' });
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}

	btn_cancel_parent_click () 
	{
		this.add_parent_toggled(false);
	}
}

class EditTaskDialog 
{
	constructor (bindings, element) {
	
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new EditTaskViewModel(this);
		this.task_id = null;
		this.editor = null;
		this.original_task = null;

		this.something_changed = false;
		
		this.viewModel.selected_category.subscribe((new_category) => 
		{
			if (new_category)
			{
				this.viewModel.status_list(new_category.allowed_statuses);
				this.viewModel.subcategory_list(new_category.subcategories);

				if (this.original_task)
				{
					let original_status = _.find(new_category.allowed_statuses, (d) => { return d.status == this.original_task.status; });
					if (original_status)
						this.viewModel.selected_status(original_status);

					let original_subcategory = _.find(new_category.subcategories, (d) => { return d.task_subcategory == this.original_task.subcategory; });
					if (original_subcategory)
						this.viewModel.selected_subcategory(original_subcategory);
				}
			}
		});
		this.state = '';
	}

	init () 
	{
	
		if (this.bindings.task && this.bindings.task.task_id)
			this.task_id = this.bindings.task.task_id;

		if (!this.task_id && this.bindings.task_id)
			this.task_id = this.bindings.task.task_id;

		let dialog_width = $('.modal-content').width();
		let dialog_height = $('.modal-content').height();


		$('.modal-loader').show();

		$('.software_unit_warning').hide();

		setTimeout(() => { $('.selectpicker').selectpicker('refresh'); }, 0);

		Promise.allSettled([
			new Promise ((resolve, reject) => {
				Grape.cache.fetch('SoftwareUnitUsernames', (data) => {


					if (data)
						this.viewModel.software_unit_list(data);

					setTimeout(() => { $('.selectpicker').selectpicker('rebuild'); }, 0);
					resolve(null);
				});
			}),
			new Promise ((resolve, reject) => {
				Grape.cache.fetch('DepartmentList', (data) => {
					this.viewModel.department_list(data);
					resolve(null);
				});
			}),
			new Promise ((resolve, reject) => {
				Grape.cache.fetch('TaskCategoryLookup', (data) => {
					if (data)
						this.viewModel.category_list(data);
					resolve(null);
				});
			}),
			new Promise ((resolve, reject) => {
				Grape.cache.fetch('RequesteeLookup', (data) => {
					if (data)
						this.viewModel.requestee_list(data);
					resolve(null);
				});
			}),
			new Promise ((resolve, reject) => {
				Grape.cache.fetch('DeveloperLookup', (data) => {
					if (data)
						this.viewModel.developer_lookup(data);
					resolve(null);
				});
			}),

		])
		.then(()=>{
			if (this.task_id)
			{
				this.state = 'editing_task';
				this.retrieve_task_data(this.task_id, (err, task) => {
					this.original_task = task;
					$('.modal-loader').hide();
				});
			}
			else
			{
				$('.selectpicker').selectpicker('refresh');
				this.state = 'new_task';
				if (this.bindings.parent_task_id)
				{
					this.viewModel.parent_task_id(this.bindings.parent_task_id);
					if (this.bindings.parent_task_description)
						this.viewModel.parent_task_description(this.bindings.parent_task_description);
					if (this.bindings.parent_task_nr)
						this.viewModel.parent_task_nr(this.bindings.parent_task_nr);
				}

				this.viewModel.software_unit_alter_allowed(true);
				this.viewModel.enable_html_editor();
				this.original_task = {
					requested_by: window.Grape.currentSession.user.username
				};
				this.viewModel.selected_requestee(_.find(this.viewModel.requestee_list(), (o) => { return o.username == Grape.currentSession.user.username; }));

				let default_software_unit_name = localStorage.getItem('last_software_unit_name_used');
				if (this.bindings.software_unit_name)
					default_software_unit_name = this.bindings.software_unit_name;

				if (default_software_unit_name)
				{
					let unit_list = this.viewModel.software_unit_list().filter(value => default_software_unit_name.includes(value.software_unit_name));
					this.viewModel.selected_software_units(unit_list);
				}

				let default_category = localStorage.getItem('last_category_used');
				if (this.bindings.category)
					default_category = this.bindings.category;

				if (default_category)
					this.viewModel.selected_category(
						_.find(this.viewModel.category_list(),
							(o) => { return o.task_category == default_category; }
						)
					);

				let default_department_name = localStorage.getItem('last_department_used');
				if (this.bindings.department_name)
					default_department_name = this.bindings.department_name;

				if (default_department_name)
					this.viewModel.selected_department(
						_.find(this.viewModel.department_list(),
							(o) => { return o.name == default_department_name; }
						)
					);

				$('.modal-loader').hide();
			}
		});
	}

	enable_ckeditor () 
	{
		if (!this.editor)
		{
			ClassicEditor.create(
				this.target.querySelector('.ckeditor'),
					Object.assign({}, ClassicEditorConfig, {
						height: '550px'
					})
				).then((editor) => {
					console.table(Array.from(editor.ui.componentFactory.names()));
					this.viewModel.html_editor_loaded(true);
					this.editor = editor;
					this.editor.fileUploadURL = '/upload_task_attachment?task_id=' + this.task_id;
					this.editor.fileUploadMethod = 'POST';
					this.editor.fileUploadField = 'attachment';
					this.editor.setData(this.viewModel.full_description());
				}).catch((err) => {
					console.error(err);
					console.trace();
				});
		}
	}

	difficulty_code_to_name(code) 
	{
		if (code <= 1)
			return 'Easy';
		else if (code == 2)
			return 'Medium';
		else if (code >= 3)
			return 'Hard';
	}

	difficulty_name_to_code(name) 
	{
		if (name == 'Easy')
			return 1;
		else if (name == 'Medium')
			return 2;
		else if (name == 'Hard')
			return 3;
		else
			return 0;
	}

	// FUNCTION: Retrieve task specific data from task table
	async retrieve_task_data (_task_id, childCallBack) 
	{
		try 
		{
			let result = await Grape.fetches.getJSON(`/task/${_task_id}`, {});
			let _task_data = result.task;

			let _allow_software_unit_alter = !((_task_data.parent_task_id || _task_data.child_tasks));

			this.viewModel.task_id(_task_data.task_id);
			this.viewModel.task_prefix(_task_data.task_prefix);
			this.viewModel.task_nr(_task_data.task_nr);
			this.viewModel.software_unit_alter_allowed(_allow_software_unit_alter);
			this.viewModel.software_unit_item_id(_task_data.software_unit_item_id);
			this.viewModel.software_unit_owner(_task_data.product_owner);
			this.viewModel.short_description(_task_data.short_description);

			this.viewModel.selected_software_units(_.filter(this.viewModel.software_unit_list(), (o) => { return (_task_data.software_units.indexOf(o.software_unit_name) != -1); }));
			this.viewModel.selected_category(_.find(this.viewModel.category_list(), (o) => { return o.task_category == _task_data.category; }));
			this.viewModel.selected_status(_.find(this.viewModel.status_list(), (o) => { return o.status == _task_data.status; }));
			this.viewModel.selected_requestee(_.find(this.viewModel.requestee_list(), (o) => { return o.username == _task_data.requested_by; }));
			this.viewModel.selected_developers(_.filter(this.viewModel.developer_list(), (o) => { return (_task_data.assigned_to_uuids.indexOf(o.user_uuid) != -1) }));

			this.viewModel.selected_department(_.find(this.viewModel.department_list(), (o) => { return o.name == _task_data.department; }));

			this.viewModel.selected_deadline(_task_data.deadline);
			this.viewModel.selected_planned_start_date(_task_data.planned_start_date);
			this.viewModel.selected_priority(_task_data.priority);
			this.viewModel.selected_difficulty(this.difficulty_code_to_name(_task_data.difficulty));
			this.viewModel.selected_impact(_task_data.impact);
			this.viewModel.parent_task_id(_task_data.parent_task_id);
			this.viewModel.parent_task_description(_task_data.parent_task_description);
			this.viewModel.parent_task_nr(_task_data.parent_task_number);

			this.viewModel.full_description(_task_data.full_description || '');

			childCallBack(null, _task_data);
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		}
	}
	
	async show_task_info () 
	{
		await Grape.dialog.open('TaskInfoDialog', { task_id: this.viewModel.task_id() });
	}

	// FUNCTION: Initiate save request
	async save_task () 
	{

		let _valid = this.validate();

		if (!_valid)
			return;

		$('.modal-loader').show();
		let task = this.compile_data();
		let data;

		try 
		{
			let result = await fetch('/api/task', {
				method: 'POST',
				body: JSON.stringify(task),
				headers: { 'content-type': 'application/json' }
			});

			let selected_software_units = this.viewModel.selected_software_units().map(val => { return val.software_unit_name });
			localStorage.setItem('last_software_unit_name_used', selected_software_units);
			localStorage.setItem('last_department_used', task.department_name);
			localStorage.setItem('last_category_used', task.category);

			data = await result.json();

			if (result.ok)	
			{
			
				if (this.state == 'edit_task')
					Grape.alert({ title: 'Task Edited', type: 'success', message: 'Task has been successfully edited' });
				else if (this.state == 'new_task')
				{
					let response = await Grape.prompt({
						title: 'Task Created ' + data.task_nr,
						type: 'success',
						message: 'Task has been successfully created. Do you want to open it?'
					});

					if (response)
						this.view_task(data.task_id, {});
						
				}
			}
		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
			console.error(error);
		} finally {
			$('.modal-loader').hide();
			this.close(data);
		}
	}

	// FUNCTION: Called when the dialog is closed or navigated away from.
	validate () 
	{
		let _flag = true;
		let _short_description = this.viewModel.short_description();
		let software_unit = this.viewModel.selected_software_units();
		
		if(software_unit.length < 1)
		{
			_flag = false;

			Grape.alert({ title: 'Missing Data', message: 'No software unit defined', type: 'error' });
		}
			
		if (!_short_description || _short_description == '')
		{
			_flag = false;

			Grape.alert({ title: 'Missing Data', message: 'No short description defined', type: 'error' });
		}

		return _flag;
	}

	// FUNCTION: Complile task record data from observable variables.
	compile_data () 
	{
		let _deadline = (this.viewModel.selected_deadline()) ? this.viewModel.selected_deadline() : null;
		let _parent_task_id = (this.viewModel.parent_task_id()) ? parseInt(this.viewModel.parent_task_id()) : null;
		let task_data = {
			task_id: this.viewModel.task_id(),
			parent_task_id: _parent_task_id,
			short_description: this.viewModel.short_description(),
			software_unit_ids: this.viewModel.selected_software_unit_ids(),
			category: this.viewModel.selected_category() ? this.viewModel.selected_category().task_category : 'General' ,
			subcategory: this.viewModel.selected_subcategory(),
			status: this.viewModel.selected_status().status,
			requested_by: this.viewModel.selected_requestee() ? this.viewModel.selected_requestee().username : '',
			assigned_to: this.viewModel.selected_developers(),
			department_id: this.viewModel.selected_department().department_id,
			department_name: this.viewModel.selected_department().department_name,
			deadline: _deadline,
			planned_start_date: this.viewModel.selected_planned_start_date(),
			priority: this.viewModel.selected_priority(),
			difficulty: this.difficulty_name_to_code(this.viewModel.selected_difficulty()),
			impact: this.viewModel.selected_impact()
		};

		if (this.editor)
			task_data.full_description = this.editor.getData();

		return task_data;
	}

	// FUNCTION: Called when the dialog is closed or navigated away from.
	teardown () 
	{
		if (this.editor)
			this.editor.destroy();
	}

	close_dialog () 
	{	
	//	parent.location.reload();

		$('.selectpicker').selectpicker('close');
		this.close(this.something_changed);
	}

	async view_task (task_id) 
	{
		await Grape.dialog.open('ViewTask', { task_id: task_id }).then((_back)=>{
			//parent.location.reload();
		})
		.catch((err)=>{
			

		});;
	}
}

export default {
	name: 'EditTask',
	dialog_class: EditTaskDialog,
	template: template
}
