import template from './task_history.html';

class TaskHistoryViewModel
{
	constructor (page) {
		this.page = page;

		this.current_values = ko.observableArray([]);
	}
}

class TaskHistoryPage
{
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new TaskHistoryViewModel(this);
		this.task_id = null;
	}

	init () 
	{
		this.task_id = this.bindings.task_id;

		let columns = [
			{ field: 'date_inserted', title: 'Date', sortable: false, formatter: (v, r) => { return moment(v).format('L LTS'); } },
			{ field: 'username', title: 'Username', sortable: false },
			{ field: 'status', title: 'Status', sortable: false },
			{ field: 'assigned_to', title: 'User Assigned', sortable: false },
			{ field: 'priority', title: 'Priority', sortable: false },
			{ field: 'department', title: 'Department', sortable: false },
			{ field: 'category', title: 'Category', sortable: false },
			{ field: 'requested_by', title: 'Requested By', sortable: false },
			{ field: 'impact', title: 'Impact', sortable: false },
			{ field: 'tags', title: 'Tags', sortable: false },
			{ field: 'total_billable_hours', title: 'Total Billable Hours', sortable: false },
			{ field: 'attachment_count', title: 'Attachment Count', sortable: false }
		];

		this.tbl_wait_tasks = $("#tbl_history_entries").bootstrapTable({
			columns: columns,
			search: false,
			data: []
		});

		this.updateData();
	}

	async updateData () 
	{
		try
		{
			let result = await Grape.fetches.getJSON('/task_history', { task_id: this.task_id });

			if (result.status == 'Error')
			{
				throw new Error(result);
			}
			
			$('#tbl_history_entries').bootstrapTable('load', result.history);

		} catch (error) {
			Grape.alert({ title: 'Error', type: 'error', message: error.message });
		}
	}

	close_dialog () 
	{
		this.close();
	}
}

export default {
	name: 'TaskHistory',
	page_class: TaskHistoryPage,
	template: template
}
