import template from './column-selector.html';

class ColumnSelectorVM
{
	constructor (params) {
		this.available_options = ko.observableArray(ko.utils.unwrapObservable(params.available));
		this.selected_options = params.selected;

		this.label_field = ko.observable(ko.utils.unwrapObservable(params.label_field));
		
		this.selected_option = ko.observable();


		if (params.done && typeof params.done == 'function')
			this.done = params.done;
		else
			this.done = () => {};
	}

	add_selected_after () 
	{
		this.selected_options.push(this.selected_option());
	}

	add_selected_before () 
	{
		this.selected_options.unshift(this.selected_option());
	}

	delete_item (e) 
	{
		this.selected_options.splice(this.selected_options.indexOf(e), 1);
	}
}

export default {
	name: 'column-selector',
	viewModel: ColumnSelectorVM,
	module_type: 'ko',
	template: template
}
