
import template from './browser.html';

/**
 * Empty Page view model
 */
class FileBrowserViewModel
{
	constructor(page)
	{
		this.page = page;
		this.user_home_dir = ko.observable(Grape.currentSession?.user?.settings?.['filestore.home'] || '/DAV');
		this.current_path = ko.observable(this.user_home_dir());
	}

	linkClick(href)
	{
		this.current_path(href);
	}
}

/**
 * Empty page class
 */
class FileBrowserPageClass
{
	constructor(bindings, element)
	{
		this.bindings = bindings;
		this.element = element;
		this.viewModel = new FileBrowserViewModel(this);
		this.name = 'FileBrowserPageClass';
	}

	async init ()
	{
		let lasturl = localStorage.getItem('cms.file-browser-last-url');
		if (lasturl)
			this.viewModel.linkClick(lasturl);

		this.viewModel.current_path.subscribe((nv) => {
			if (nv && localStorage.getItem('cms.file-browser-last-url') != nv)
				localStorage.setItem('cms.file-browser-last-url', nv);
		});
	}

	async updateData ()
	{
	}

	async teardown ()
	{
	}

};

export default {
	route: '[/]file-browser',
	page_class: FileBrowserPageClass,
	template: template
};
