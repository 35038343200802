import template from './page.html';

class FTSPageViewModel
{
	constructor (page) {
		this.page = page;

		this.search_string = ko.observable('');
		this.task_list = ko.observableArray([]);
		this.is_searching = ko.observable(false);
	}

	priority_color (prio) 
	{
		if (prio == 'Low')
			return '#17a2b8';
		if (prio == 'Medium')
			return '#28a745';
		if (prio == 'High')
			return '#ffc107';
		if (prio == 'Urgent')
			return '#dc3545';
	}

	async search () 
	{
		this.is_searching(true);
		if (this.search_string() && this.search_string().length)
		{
			this.task_list([]);
			localStorage.fts_search_string = this.search_string();

			let d = await Grape.fetches.getJSON('/user/task_fts_search',{ query: this.search_string() });
			this.is_searching(false);
			
			if (d.status == 'ERROR')
			{
				Grape.alert_api_error(d);
				return;
			}
			if (d.records && d.records.length > 0)
			{
				d.records.forEach((task) => {
					task.difficulty_html = '';
					if (task.difficulty == 1)
						task.difficulty_html = '<b style="color: green;">[E]</b>';
					else if (task.difficulty == 2)
						task.difficulty_html = '<b style="color: orange;">[M]</b>';
					else if (task.difficulty == 3)
						task.difficulty_html = '<b style="color: red;">[H]</b>';
				});

				this.task_list(d.records);
			}
			else
			{
				this.task_list([]);
			}
		}
	}
}

class FTSPage
{	
	constructor (bindings) {
		this.bindings = bindings;
		this.viewModel = new FTSPageViewModel(this);
		this.name = 'FTSPage';
	}

	init () 
	{
		document.title = 'Task Search';
		if (this.bindings && this.bindings.search_string_id)
		{
			var val = $('#' + this.bindings.search_string_id).val();
			if (val)
				this.viewModel.search_string(val);
			else
				this.viewModel.search_string('');
		}

		if (this.viewModel.search_string() == '' && localStorage.fts_search_string)
		{
			this.viewModel.search_string(localStorage.fts_search_string);
		}

		if (this.viewModel.search_string() != '')
			this.viewModel.search();
	}

	updateData () {}

	teardown () {}
}

export default {
	route: '[/]task_fts',
	page_class: FTSPage,
	template: template
}
