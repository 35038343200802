export default [
	{
		field: 'task_nr',
		title: 'Task Nr',
		order: 'DESC'
	},
	{
		field: 'short_description',
		title: 'Description',
		order: 'DESC'
	},
	{
		field: 'software_units',
		title: 'Software Units',
		order: 'DESC'
	},

	{
		field: 'status',
		title: 'Status',
		order: 'DESC'
	},
	{
		field: 'priority',
		title: 'Priority',
		order: 'DESC'
	},
	{
		field: 'date_assigned',
		title: 'Date Assigned',
		order: 'DESC'
	},
	{
		field: 'date_closed',
		title: 'Date Closed',
		order: 'DESC'
	},
	{
		field: 'date_created',
		title: 'Date Created',
		order: 'DESC'
	},
	{
		field: 'deadline',
		title: 'Deadline',
		order: 'DESC'
	},
	{
		field: 'category',
		title: 'Category',
		order: 'DESC'
	},
	{
		field: 'subcategory',
		title: 'Subcategory',
		order: 'DESC'
	},
	{
		field: 'department',
		title: 'Department',
		order: 'DESC'
	},
	{
		field: 'impact',
		title: 'Impact',
		order: 'DESC'
	},
	{
		field: 'assigned_to',
		title: 'Assigned To',
		order: 'DESC'
	},
	{
		field: 'created_by',
		title: 'Created By',
		order: 'DESC'
	},
	{
		field: 'requested_by',
		title: 'Requested By',
		order: 'DESC'
	},
	{
		field: 'tags',
		title: 'Tags',
		order: 'ASC'
	},
	{
		field: 'difficulty',
		title: 'Difficulty',
		order: 'ASC'
	},
	{
		field: 'child_count',
		title: 'Child count',
		order: 'ASC'
	},
	{
		field: 'hours_logged',
		title: 'Hours Logged',
		order: 'ASC'
	},
	{
		field: 'last_updated',
		title: 'Last Updated',
		order: 'DESC'
	},
	{
		field: 'planned_start_date',
		title: 'Planned Start Date',
		order: 'DESC'
	},
	{
		field: 'unread_notification_count',
		title: 'Notification Count',
		order: 'DESC'
	},

	{
		field: 'child_progress',
		title: 'Progress'
	},
	{
		field: 'parent_task',
		title: 'Parent',
		order: 'ASC'
	},
	{
		field: 'child_tasks',
		title: 'Child Tasks'
	}
];

